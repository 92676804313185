
/* ************************************ */
/*     APP STYLES                       */
/* *********************************** */
/* 
    color: #C3C4DE;
    background-color: #FDB813;
    alert{
        color: #FDB813;;
        color: rgb(13, 166, 13);
    }
*/
@font-face {
    font-family: 'chillax';
    src: url('/public/asset/fonts/Chillax_Complete/Fonts/OTF/Chillax-Bold.otf') format('opentype');
    font-weight: bold;
    font-style: normal;
}

/* // <uniquifier>: Use a unique and descriptive class name
// <weight>: Use a value from 100 to 900 */

*{
  font-family: "Montserrat", sans-serif;
  font-optical-sizing: auto;
  font-weight: 300;
  font-style: normal;
}
.app-parent-container{
    width: 100%;
    margin: auto;
    position: relative;
    overflow: hidden;
    box-sizing: border-box;
}
body{
    font-family: 'chillax', sans-serif;
    letter-spacing: 0.005em;
    -webkit-user-select: none; /* Safari */
    -ms-user-select: none; /* IE 10 and IE 11 */
    user-select: none; /* Standard syntax */
}
.alert-error{
    height: 15px;
    font-size: 13px;
}
.simple-alert{
    font-weight: 600;
    font-size: 18px;
    text-align: center;
}
.simple-alert.danger{
    padding: 5px;
    border-radius: 5px;
    color: rgb(129, 5, 5);
    background-color: rgb(247, 154, 154);
    border: 1px solid rgb(252, 72, 72);
}
.alert-error span{
    top: -12px;
    position: relative;
    color: rgb(180, 3, 3);
}
ul, ol {
    margin-bottom: 0;
    list-style: none;
    padding: 0px;
    color: #000000;
    -webkit-user-select: none; /* Safari */
    -ms-user-select: none; /* IE 10 and IE 11 */
    user-select: none; /* Standard syntax */
  }
a {
    color: #000000;
    text-decoration: none;
    background-color: transparent;
}
p, li, h3, div, span{
    color: #000000;
    font-family: "Montserrat", sans-serif;
    -webkit-user-select: none; /* Safari */
    -ms-user-select: none; /* IE 10 and IE 11 */
    user-select: none; /* Standard syntax */
}
/* *************** ALERT NOTIFICATION ******************** */
.popup-alert{
    top: 0px;
    left: 0px;
    z-index: 50;
    width: 100%;
    height: 1px;
    overflow: hidden;
    position: fixed;
}
.popup-alert .dark-theme{
    top: 0px;
    left: 0px;
    height: 100vh;
    width: 100%;
    position: fixed;
    display: none;
    background-color: rgba(51, 51, 51, 0.349);
}
.popup-alert.active .dark-theme{
    display: block;
}
.popup-alert .body{
    width: 100%;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
}
.inner-popup-alert{
    width: 50%;
    height: 400px;
    padding: 10px;
    display: flex;
    position: fixed;
    text-align: center;
    border-radius: 5px;
    background-color: #fff;
    align-items: center;
    justify-content: center;
    transform: scale(0);
    transition: all 0.3s ease;
}

.popup-alert.popup .inner-popup-alert{
    transform: scale(1);
}
.inner-popup-alert .icon-container .icon{
    color: #FDB813;;
    font-size: 90px;
}
.inner-popup-alert .icon-container li  h3.title-header{
    font-size: 22px;
}
.inner-popup-alert .icon-container li p{
    color: #555;
    font-size: 18px;
}
.inner-popup-alert .icon-container li{
    margin: 10px 0px;
}
.inner-popup-alert .icon-container li button{
    border: none;
    color: #333;
    font-size: 20px;
    padding: 10px 30px;
    border-radius: 5px;
    font-weight: bold;
    background-color: #FDB813;
}
@media only screen and (min-width: 1500px){
    .inner-popup-alert{
        width: 700px;
    }
}
@media only screen and (max-width: 992px){
    .inner-popup-alert{
        width: 80%;
    }
}
@media only screen and (max-width: 767px){
    .inner-popup-alert{
        width: 90%;
    }
}
/* ***************PRELOADER******************************* */
.preloader{
    width: 100%;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #FDB813;
}
.preloader .loader-image{
    position: relative;
    animation: loader 2s linear infinite;
}
@keyframes loader {
    0% {
        transform: scale(1.05);
    }
}
/* ***************NAVIGATION SECTION********************** */
.main-navigation{
    z-index: 20;
    width: 100%;
    height: 80px;
    position: relative;
    background-color: #fff;
}
.navigation-container{
    z-index: 20;
    width: 100%;
    height: 80px;
    position: fixed;
    background-color: #fff;
    border-bottom: 1px solid #e9e9e9;
    box-shadow: 2px 2px 5px 0px rgb(232, 232, 232);
}
.navigation-container .main-navi{
    background-color: #fff;
}
.navigation-container .inner-main-navi{
    width: 70%;
    height: 80px;
    display: flex;
    margin: 0 auto;
    align-items: center;
    justify-content: space-between;
}
.navigation-container .nav-right{
    width: 70%;
}
.navigation-container .nav-left{
    width: 30%;
}
.navigation-container .nav-left img{
    width: 140px;
}
.navigation-container .nav-right ul{
    display: flex;
    justify-content: space-between;
}
.navigation-container .nav-right ul li{
    cursor: pointer;
    font-size: 16px;
    font-weight: 500;
    display: inline-block;
    text-transform: capitalize;
    font-family: "Montserrat", sans-serif;
}
.navigation-container .nav-right ul li a,
.navigation-container .nav-right ul.nav-links li.link-container span{
    font-weight: 500;
    font-family: "Montserrat", sans-serif;
}
.navigation-container .nav-right ul li a:hover{
    color: #333;
}
.navigation-container .nav-right .nav-icon{
    display: none;
    text-align: right;
}
.navigation-container .nav-right .nav-icon .icon{
    cursor: pointer;
    color: #333;
}
.navigation-container .nav-right ul.nav-links li.link-container{
    position: relative;
}
.navigation-container .nav-right ul.nav-links li.link-container .drop-down-container{
    top: 30px;
    right: 0px;
    width: 250px;
    display: none;
    padding: 10px 0px;
    position: absolute;
    border-radius: 5px;
    background-color: #fff;
    box-shadow: 2px 2px 5px 2px rgba(191, 191, 191, 0.772);
}
.navigation-container .nav-right ul.nav-links li.link-container:hover .drop-down-container{
    display: block;
}
.navigation-container .nav-right ul.nav-links li.link-container span .icon{
    top: 2px;
    color: #333;
    font-size: 16px;
    margin-left: 5px;
    position: relative;
}
.navigation-container .nav-right ul.nav-links li.link-container .drop-down-container ul{
    width: 100%;
    display: block;
}
.navigation-container .nav-right ul.nav-links li.link-container .drop-down-container ul li{
    display: block;
    font-size: 16px;
    font-weight: 300;
    padding: 10px 10px;
    line-height: 25px;
    text-transform: capitalize;
}
.navigation-container .nav-right ul.nav-links li.link-container .drop-down-container ul li:hover{
    background-color: #efefef;
}
.navigation-container .nav-right ul li.button{
    padding: 3px 20px;
    border-radius: 5px;
    background-color:  black;
}
.navigation-container .nav-right ul li.button a{
    color: #fff;
    font-weight: 300;
}
/* ******** FLOATING NAVIGATION *************** */
.float-navigation{
    opacity: 0;
    height: 0px;
    visibility: hidden;
}
.float-navigation.active{
    opacity: 1;
    height: 100px;
    visibility: visible;
    transition: all 0.5s ease;
}
.float-navigation.active .main-navi{
    top: 0px;
    left: 0px;
    z-index: 15;
    width: 100%;
    position: fixed;
    border-bottom: 1px solid #dedede;
    /* box-shadow: 2px 2px 2px 0px rgb(219, 219, 219); */
}
@media only screen and (max-width: 1400px){
    .navigation-container .inner-main-navi{
        width: 90%;
    }
}
@media only screen and (max-width: 992px){
    .navigation-container .nav-right .nav-icon{
        display: block;
    }
    .navigation-container .nav-right ul.nav-links{
        display: none;
    }
    .navigation-container .nav-right .nav-icon{
        text-align: right;
    }
}
@media only screen and (max-width: 567px){
    .navigation-container .nav-left img{
        width: 150px;
    }
}
/* ****************** SIDE NAVIGATION SECTION ************** */
.side-navigation{
    top: 0px;
    left: 0px;
    z-index: 20;
    position: relative;
}
.side-navigation .dark-skin{
    top: 0px;
    left: 0px;
    z-index: 10;
    width: 100%;
    height: 100vh;
    display: none;
    position: fixed;
    align-items: center;
    background-color: rgba(13, 13, 13, 0.425);
}
.side-navigation.active .dark-skin{
    display: block;
}
.side-navigation .side-nav-container{
    top: 0px;
    left: -450px;
    z-index: 15;
    width: 400px;
    height: 100vh;
    position: fixed;
    background-color: #fff;
    transition: all 0.3s ease;
    border-right: 1px solid rgb(228, 228, 228);
}
.side-navigation.active .side-nav-container{
    left: 0px;
}
.side-nav-header {
    display: flex;
    height: 80px;
    padding: 0px 20px;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid rgb(228, 228, 228);
}

.side-nav-header .side-nav-img img{
    width: 150px;
    cursor: pointer;
}
.side-nav-header .side-nav-icon .icon{
    color: #333;
    cursor: pointer;
}
.side-nav-links{
    padding: 20px;
}
.side-nav-links ul li{
    margin: 10px 0px;
}
.side-nav-links ul li a{
    font-size: 20px;
    font-family: "Montserrat", sans-serif;
}
.side-nav-links .nav-drop-down .title:hover,
.side-nav-links ul li a:hover{
    color: #FDB813;
}
.nav-drop-down .title{
    font-size: 20px;
    cursor: pointer;
}
.nav-drop-down .title .icon{
    font-size: 18px;
    color: #929292;
}
.nav-drop-down .drop-down ul li{
    margin: 0px;
    padding: 0px;
    font-size: 18px;
    cursor: pointer;
    font-weight: 300;
}
.nav-drop-down .drop-down ul li a{
    font-size: 16px;
}
.nav-drop-down .drop-down ul{
    padding-left: 15px;
}
.nav-drop-down .drop-down{
    height: 0px;
    overflow: hidden;
    transition: all 0.3s ease;
}
.nav-drop-down .drop-down.active{
    height: inherit;
    overflow: visible;
}
.nav-drop-down .drop-down ul li:hover{
    color: #FDB813;
}
@media only screen and (max-width: 767px){
    .nav-drop-down .title,
    .side-nav-links ul li a{
        font-size: 18px;
    }
    .nav-drop-down .drop-down ul li a{
        font-size: 16px;
    }
    .side-nav-links ul li{
        margin: 0px 0px;
    }
}
@media only screen and (max-width: 567px){
    .side-navigation .side-nav-container{
        width: 80%;
    }
    .nav-drop-down .title,
    .nav-drop-down .title .icon,
    .side-nav-links ul li a{
        font-size: 16px;
    }
    .nav-drop-down .drop-down ul li a{
        font-size: 14px;
    }
}
@media only screen and (max-width: 398px){
    .side-navigation .side-nav-container{
        width: 85%;
    }
}
/* ************** FOOTER SECTION ************ */
.footer-container{
    background: #1C1B1F;
}
.footer-container.mobile{
    margin-bottom: 100px;
}
.footer-top{
    width: 90%;
    height: 150px;
    display: flex;
    margin: 0 auto;
    align-items: center;
    justify-content: space-between;
    border-bottom: 3px solid  #333;
}
.footer-links ul li{
    display: inline-block;
}
.footer-links ul li,
.footer-links ul li a{
    cursor: pointer;
    color: #fff;
    font-size: 18px;
    font-weight: 300;
    margin: 0px 10px;
    line-height: 29.52px;
    font-family: "Montserrat", sans-serif;
}
.footer-app-img img{
    width: 120px;
    margin: 10px 10px;
    border-radius: 5px;
}
.footer-bottom{
    padding-bottom: 20px;
}
.footer-bottom .inner-content{
    width: 90%;
    margin: 0 auto;
    display: flex;
    position: relative;
    margin-top: 50px;
    justify-content: space-between;
}
.footer-bottom .footer-left img{
    width: 150px;
    margin-right: 50px;
}
.footer-bottom .footer-left span.at{
    color: #fff;
    font-size: 16px;
    font-family: "Montserrat", sans-serif;
}
.footer-bottom .footer-left span.at span{
    color: #FDB813;
}
.footer-right ul li{
    margin: 0px 15px;
    display: inline-block;
    font-family: "Montserrat", sans-serif;
}
.footer-right ul li a{
    color: #fff;
    font-size: 20px;
}
.footer-bottom-at{
    display: none;
}
@media only screen and (max-width: 767px){
    .footer-top, .footer-bottom .inner-content{
        width: 100%;
        padding: 0px 10px;
    }
    .footer-bottom .footer-left img{
        width: 100px;
    }
    .footer-bottom .footer-left span.at{
        font-size: 11px;
    }
    .footer-bottom .footer-right ul li .icon{
        font-size: 14px;
    }
}
@media only screen and (max-width: 567px){
    .footer-top{
        height: 100%;
        display: flex;
        align-items: center;
        padding: 20px 0px;
        justify-content: space-between;
    }
    .footer-links,
    .footer-app-img{
        width: 50%;
    }
    .footer-app-img{
        text-align: right;
    }
    .footer-container.mobile{
        margin-bottom: 80px;
    }
    .footer-links ul li{
        width: 100%;
    }
    .footer-links ul li a{
        font-size: 16px;
        font-weight: 300;
    }
    .footer-bottom .footer-left span.at{
        display: none;
    }
    .footer-bottom-at{
        display: block;
        padding: 10px 0px;
        text-align: center;
    }
    .footer-bottom-at span{
        color:#fff;
        font-size: 14px;
    }
    .footer-bottom-at span.at span{
        color: #FDB813;
    }
    .footer-bottom{
        padding-bottom: 0px;
    }
}
/* *************** OLD HOME SECTION ************* */
/* .home-banner{
    width: 90%;
    margin: 0 auto;
    position: relative;
    margin-top: 100px;
    margin-bottom: 100px;
}
.home-banner img{
    width: 100%; 
    height: 100%;
}
.home-banner .banner-content{
    top: 0px;
    width: 100%;
    height: 100%;
    display: flex;
    position: absolute;
    align-items: center;
    justify-content: space-between;
}
.banner-content .right-side{
    width: 50%;
    height: 100%;
    padding: 0px 20px;
    text-align: right;
}
.banner-content .right-side img{
    top: 20px;
    width: 100%;
    height: 100%;
    position: relative;
    transition: all 0.5s ease;
}
.banner-content .right-side.popin img{
    transform: scale(0);
    transition: all 0.5s ease;
}
.banner-content .right-side.popup img{
    transform: scale(1.13);
    transition: all 0.5s ease;
}
.banner-content .left-side{
    top: 20px;
    left: 100px;
    width: 40%;
    height: 50%;
    position: relative;
}
.banner-content .left-side .item{
    display: flex;
    align-items: center;
    justify-content: center;
}
.banner-content .left-side .item h3{
    font-size: 35px;
    font-weight: bold;
}
.banner-content .left-side .item p{
    font-size: 18px;
    line-height: 25px;
}
.banner-description{
    width: 50%;
    display: none;
    margin: 0 auto;
    position: relative;
    text-align: center;
}
.banner-description .item h3{
    font-size: 35px;
    font-weight: bold;
}
.banner-description .item p{
    font-size: 18px;
    line-height: 25px;
}
@media only screen and (max-width: 1400px){
    .banner-content .left-side .item h3{
        font-size: 30px;
    }
}
@media only screen and (max-width: 992px){
    .banner-content .left-side{
        left: 50px;
        width: 50%;
        height: 60%;
    }
    .banner-content .left-side .item h3{
        font-size: 25px;
    }
    .banner-content .left-side .item p{
        font-size: 14px;
    }
} */

/* ************** HOME SECTION **************** */
.home-banner-container{
    width: 90%;
    margin: 0 auto;
    margin-top: 100px;
}
.inner-banner{
    display: flex;
    align-items: center;
    justify-content: center;
}
.home-banner-container .inner-banner .right,
.home-banner-container .inner-banner .left
{
    width: 50%;
}
.home-banner-container .inner-banner .left .title-header,
.home-banner-container .inner-banner .left .body,
.home-banner-container .inner-banner .left .button{
    margin-bottom: 50px;
}
.home-banner-container .inner-banner .right{
    text-align: right
}
.home-banner-container .inner-banner .right img{
    width: 80%;
    height: 100%;
    border-radius: 20px;
    padding: 20px 20px 0px 20px;
    background-color: #FDB813;
}
.home-banner-container .inner-banner .left .title-header h3{
    font-size: 50px;
    font-weight: bold;
}
.home-banner-container .inner-banner .left .body p{
    font-size: 20px;
    line-height: 30px;
    font-weight: 300;
}
.home-banner-container .inner-banner .left .button .app-link{
    bottom: -10px;
    width: 70%;
    display: flex;
    padding: 20px;
    position: relative;
    border-radius: 50px;
    justify-content: space-between;
    transition: all 0.3s ease;
    border: 1px solid rgb(235, 235, 235);
    box-shadow: 2px 5px 10px 0px rgb(198, 198, 202);
    
}
.home-banner-container .inner-banner .left .image{
    display: none;
}

.home-banner-container .inner-banner .left .button .app-link:hover{
    color: #333;
    bottom: 0px;
    border: 1px solid rgb(252, 236, 204);
    box-shadow: 2px 5px 10px 0px rgb(250, 210, 117);
}
.home-banner-container .inner-banner .left .button span{
   font-size: 20px;
   font-weight: bold;
}
.home-banner-container .inner-banner .left .button .icon{
    border-radius: 50%;
    padding: 10px;
    transform: rotate(-50deg);
    background-color: #FDB813;
} 
@media only screen and (min-width: 1500px){
    .home-banner-container{
        width: 1300px;
    }
}
@media only screen and (max-width: 992px){
    .home-banner-container .inner-banner .left .title-header h3{
        font-size: 35px;
    }
    .home-banner-container .inner-banner .left .body{
        font-size: 17px;
        line-height: 25px;
    }
    .home-banner-container .inner-banner .left .title-header,
    .home-banner-container .inner-banner .left .body,
    .home-banner-container .inner-banner .left .button{
        margin-bottom: 30px;
    }
    .home-banner-container .inner-banner .left .button .app-link{
        width: 90%;
    }
}
@media only screen and (max-width: 767px){
    .home-banner-container .inner-banner .left .image{
        display: block;
        padding-top: 20px;
        border-radius: 10px;
        background-color: #FDB813;
    }
    .home-banner-container .inner-banner .right{
        display: none;
    }
    .home-banner-container .inner-banner .left .button .app-link{
        width: 100%;
    }
    .home-banner-container .inner-banner .right,
    .home-banner-container .inner-banner .left
    {
        width: 100%;
    }
    .home-banner-container .inner-banner .left{
        text-align: center;
    }
    
    .home-banner-container .inner-banner .left .title-header h3{
        font-size: 40px;
    }
    .home-banner-container .inner-banner .left .description .title-header,
    .home-banner-container .inner-banner .left .description .body,
    .home-banner-container .inner-banner .left .description .image{
        margin-bottom: 40px;
    }
    .home-banner-container .inner-banner .left .image img{
        width: 70%;
        height: 100%;
    }
}
/* **************** HOME SERVICES SECTION ************ */
.services-contanier{
    width: 90%;
    margin: 0 auto;
    position: relative;
    margin-top: 100px;
    margin-bottom: 100px;
    border-radius: 20px;
    background-color: #D8E3E2;
}
.services-contanier .service-bg{
    top: 0px;
    left: 0px;
    z-index: 5;
    width: 100%;
    height: 100%;
    position: absolute;
}
.services-contanier .service-bg img{
    width: 100%;
    height: 100%;
    opacity: 0.3;
}
.services-contanier .inner-services{
    z-index: 10;
    width: 100%;
    display: flex;
    padding: 50px;
    position: relative;
    align-items: center;
    justify-content:  space-between;
}
.services-contanier .right,
.services-contanier .left{
    width: 45%;
}
.services-contanier .left .title-header,
.services-contanier .left .body,
.services-contanier .left .button{
    margin-bottom: 30px;
}
.services-contanier .left .title-header h3{
    font-size: 45px;
}
.services-contanier .left .body{
    font-size: 20px;
    line-height: 30px;
    font-weight: 300;
}
.services-contanier .left .button{
    width: 80%;
    bottom: -10px;
    position: relative;
    padding: 10px 20px 10px 20px;
    border-radius: 50px;
    transition: all 0.3s ease;
    background-color:  #FDB813;
    border: 2px solid#FDB813;
}
.services-contanier .left .button .app-link{
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.services-contanier .left .button span{
    color: black;
    font-size: 20px;
    font-weight: bold;
}
.services-contanier .left .button span{
    color: black;
}
.services-contanier .left .button .app-link .icon{
    padding: 10px;
    border-radius: 50%;
    transform: rotate(-50deg);
    background-color: #333;
}
.services-contanier .left .button:hover{
    bottom: 0px;
    color: #333;
    background-color:  rgb(255, 199, 70);
}
.services-contanier .right .item{
    margin-bottom: 30px;
}
.services-contanier .right .content-item{
    width: 100%;
    height: 240px;
    padding: 20px 10px;
    text-align: center;
    border-radius: 10px;
    background-color: #fff;
}
.services-contanier .right .content-item .image{
    width: 50px;
    height: 50px;
    margin: 0 auto;
    margin-top: 30px;
    margin-bottom: 30px;
    border-radius: 50%;
    background-color: #FDB813;
    background-image: linear-gradient(to bottom right, #FDB813, #D2AB4B);
}
.services-contanier .right .content-item .image img{
    width: 25px;
    height: 25px;
}
.services-contanier .right .content-item h3{
    color: #333;
    font-size: 20px;
    font-weight: bold;
}
@media only screen and (min-width: 1500px){
    .services-contanier{
        width: 1300px;
    }
}

@media only screen and (max-width: 1125px){
    .services-contanier .right .content-item h3{
        font-size: 16px;
    }
    .services-contanier .right .content-item{
        height: 220px;
    }
    .services-contanier .right,
    .services-contanier .left{
        width: 50%;
    }
    .services-contanier .left .button{
        width: 90%;
    }
}
@media only screen and (max-width: 992px){
    .services-contanier .right .content-item{
        height: 170px;
    }
    .services-contanier .right .content-item .image{
        width: 50px;
        height: 50px;
        margin-top: 10px;
        margin-bottom: 10px;
    }
    .services-contanier .inner-services{
        padding: 30px;
    }
    .services-contanier .left .title-header h3{
        font-size: 30px;
    }
}
@media only screen and (max-width: 767px){
    .services-contanier .inner-services{
        display: block;
        padding: 40px 20px;
    }
    .services-contanier .right,
    .services-contanier .left{
        width: 100%;
        text-align: center;
    }
    .services-contanier .left{
        margin-bottom: 50px;
    }
    .services-contanier .left .title-header h3{
        font-size: 35px;
        text-align: center;
    }
    .services-contanier .left .button{
        width: 100%;
    }
}
@media only screen and (max-width: 567px){
    .services-contanier .right .content-item h3{
        font-size: 20px;
        font-weight: 500;
    }
    .services-contanier .right .content-item{
        width: 100%;
        height: 100%;
    }
    .services-contanier .left .button .app-link span{
        font-size: 18px;
    }
    .services-contanier .right .item{
        display: block;
        min-width: 100%;
        height: 100%;
    }
    .services-contanier .right .content-item{
        border-radius: 5px;
    }
}
@media only screen and (max-width: 398px){
    .services-contanier .left .title-header h3{
        font-size: 30px;
    }
}
/* **************** QRCODES SECTION ********** */
.qrcodes-contanier .qrcodes-inner{
    width: 90%;
    display: flex;
    margin: 0 auto;
    margin-top: 50px;
    align-items: center;
    margin-bottom: 100px;
    justify-content: space-between;
}
.qrcodes-contanier .qrcodes-left,
.qrcodes-contanier .qrcodes-right{
    width: 45%;
    height: 100%;
}
.qrcodes-contanier .qrcodes-inner.mobile{
    display: none;
}
.qrcodes-contanier .qrcodes-left{
    position: relative;
}
.qrcodes-contanier .qrcodes-left .image{
    height: 100%;
    width: 100%;
    border-radius: 30px;
}
.qrcodes-contanier .qrcodes-left .image img{
    width: 100%;
    height: 100%;
}
.qrcodes-contanier .qrcodes-left .image-float{
    left: 00px;
    bottom: 20px;
    padding: 20px;
    position: absolute;
}
.qrcodes-contanier .qrcodes-left .qrcodes-left-inner{
    padding: 20px;
    width: 70%;
    text-align: center;
    border-radius: 20px;
    background-color: #fff;
}
.qrcodes-contanier .qrcodes-left .image-float img{
    width: 100px;
}
.qrcodes-contanier .qrcodes-left .image-float p{
    font-size: 14px;
    line-height: 20px;
}
.qrcodes-contanier .qrcodes-right .title-header{
    margin: 20px 0px;
}
.qrcodes-contanier .qrcodes-right .title-header h3{
    font-weight: bold;
    font-size: 40px;
}
.qrcodes-contanier .qrcodes-right .body{
    font-size: 20px;
    font-weight: 300;
    line-height: 30px;
}
.qrcodes-contanier .qrcodes-right .button{
    margin-top: 30px;
}
.qrcodes-contanier .qrcodes-right .button .app-link{
    bottom: -10px;
    width: 80%;
    display: flex;
    padding: 20px;
    position: relative;
    border-radius: 50px;
    justify-content: space-between;
    transition: all 0.3s ease;
    border: 1px solid rgb(235, 235, 235);
    box-shadow: 2px 5px 10px 0px rgb(198, 198, 202);
}
.qrcodes-contanier .qrcodes-right .button .app-link:hover{
    color: #333;
    bottom: 0px;
    border: 1px solid rgb(252, 236, 204);
    box-shadow: 2px 5px 10px 0px rgb(250, 210, 117);
}
.qrcodes-contanier .qrcodes-right .button span{
   font-size: 20px;
}
.qrcodes-contanier .qrcodes-right .button .icon{
    border-radius: 50%;
    padding: 10px;
    transform: rotate(-50deg);
    background-color: #FDB813;
}

@media only screen and (min-width: 1500px){
    .qrcodes-contanier .qrcodes-inner{
        width: 1300px;
    }
}
@media only screen and (max-width: 992px){
    .qrcodes-contanier .qrcodes-left,
    .qrcodes-contanier .qrcodes-right{
        width: 47%;
    }
    .qrcodes-contanier .qrcodes-left .image-float{
        bottom: 0;
    }
    .qrcodes-contanier .qrcodes-left .image-float h3{
        font-size: 20px;
    }
    .qrcodes-contanier .qrcodes-left .image-float img{
        width: 80px;
    }
    .qrcodes-contanier .qrcodes-left .qrcodes-left-inner{
        width: 100%;
    }
    .qrcodes-contanier .qrcodes-right .image-float h3{
        font-size: 30px;
    }
    .qrcodes-contanier .qrcodes-right .button .app-link{
        width: 100%;
    }
}
@media only screen and (max-width: 767px){
    .qrcodes-contanier .qrcodes-inner{
        display: block;
    }
    .qrcodes-contanier .qrcodes-inner.desktop{
        display: none;
    }
    .qrcodes-contanier .qrcodes-inner.mobile{
        display: block;
    }
    .qrcodes-contanier .qrcodes-left,
    .qrcodes-contanier .qrcodes-right{
        width: 100%;
        margin-bottom: 40px;
    }
    .qrcodes-contanier .qrcodes-right .button .app-link{
        width: 70%;
        margin: 0 auto;
    }
    .qrcodes-contanier .qrcodes-left .image-float{
        padding: 10px;
    }
    .qrcodes-contanier .qrcodes-left .image-float img{
        width: 50%;
    }
    .qrcodes-contanier .qrcodes-left .qrcodes-left-inner{
        width: 70%;
    }
    .qrcodes-contanier .qrcodes-right{
        text-align: center;
    }
}
@media only screen and (max-width: 567px){
    .qrcodes-contanier .qrcodes-left .qrcodes-left-inner{
        width: 100%;
        padding: 10px;
    }
    .qrcodes-contanier .qrcodes-left .image-float img{
        width: 70px;
    }
    .qrcodes-contanier .qrcodes-right .title-header h3{
        font-size: 35px;
    }
    .qrcodes-contanier .qrcodes-right .button .app-link{
        width: 100%;
    }
}
/* ************** OUR SERVICES SECTION ******** */
.ourServices-container{
    width: 90%;
    margin: 0 auto;
    margin-bottom: 100px;
}
.ourServices-container .content-item{
    width: 100%;
    height: 100%;
    cursor: pointer;
    position: relative;
    transition: all 0.2s ease;
}
.ourServices-container .content-item:hover{
    transform: scale(1.09);
}
.ourServices-container .content-item .image{
    width: 100%;
    height: 100%;
}
.ourServices-container .content-item .image img{
    width: 100%;
    height: 100%;
    border-radius: 20px;
}
.ourServices-container .title-header h3{
    font-size: 40px;
    text-align: center;
    margin-bottom: 40px;
}
.ourServices-container .content-item .title-header{
    left: 0px;
    width: 100%;
    height: 100px;
    bottom: 0px;
    padding: 20px 10px;
    position: absolute;
    align-items: center;
    border-radius: 0px 0px 20px 20px;
    background-color: rgba(7, 7, 7, 0.685);
}
.ourServices-container .content-item .title-header .item{
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.ourServices-container .content-item .title-header:hover{
    height: 100%;
    display: flex;
    align-items: center;
    border-radius: 20px;
    text-align: center;
    justify-content: center;
    background-color: rgba(51, 51, 51, 0.472);
}
.ourServices-container .content-item .title-header:hover .item{
    display: block;
}
.ourServices-container .content-item .title-header .title{
    padding: 0px;
    margin: 0px;
    width: 100%;
    color: #fff;
    display: block;
    font-size: 14px;
    text-align: left;
    font-weight: bold;
}
.ourServices-container .content-item .title-header:hover .title{
    font-size: 30px;
    position: relative;
    transition: all 0.3s ease;
}
.ourServices-container .content-item .title-header .app-link .icon{
    padding: 10px;
    border-radius: 50%;
    background-color: #FDB813;
}
.ourServices-container .content-item .title-header:hover .app-link .icon{
    padding: 20px;
}
.our-services-float-item{
    top: 0px;
    left: 0px;
    z-index: 20;
    width: 100%;
    height: 100vh;
    position: fixed;
    display: flex;
    opacity: 0;
    visibility: hidden;
    align-items: center;
    justify-content: center;
    transition: all 0.5s ease;
}
.our-services-float-item .dark-skin{
    top: 0px;
    left: 0px;
    z-index: 5;
    width: 100%;
    height: 100vh;
    display: none;
    position: fixed;
    background-color: #1e1e1e7e;
}
.our-services-float-item.active{
    opacity: 1;
    visibility: visible;
}
.our-services-float-item.active .dark-skin{
    display: block;
}
.our-services-float-item .our-services-float-item-container{
    top: -70px;
    z-index: 6;
    width: 60%;
    height: 500px;
    padding: 20px;
    opacity: 0;
    visibility: hidden;
    overflow: hidden;
    position: relative;
    border-radius: 5px;
    background-color: #fff;
    transition: all 0.9s ease;
}
.our-services-float-item.active .our-services-float-item-container{
    top: 0px;
    opacity: 1;
    visibility: visible;
}
.our-services-float-item .inner-our-services-float-item {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
}
.our-services-float-item .inner-our-services-float-item .left{
    width: 50%;
    height: 100%;
}
.our-services-float-item .inner-our-services-float-item .left img{
    width: 100%;
    height: 100%;
    object-fit: contain;
}
.our-services-float-item .inner-our-services-float-item .right{
    width: 50%;
    padding: 20px;
}
.our-services-float-item .inner-our-services-float-item .right .title{
    margin: 20px 0px;
}
.our-services-float-item .inner-our-services-float-item .right .title h3{
    color: #333;
    font-weight: 600;
    font-size: 30px;
}
.our-services-float-item .inner-our-services-float-item .right .body{
    font-weight: 300;
    font-size: 18px;
    line-height: 30px;
}
.our-services-float-item .inner-our-services-float-item .right .cancel .icon{
    font-size: 25px;
    color: #FDB813;
    transition: color 0.3s ease;
}
.our-services-float-item .inner-our-services-float-item .right .cancel .icon:hover{
    color: rgb(251, 40, 40);
}
.our-services-float-item .inner-our-services-float-item .right .cancel{
    top: 0px;
    right: 10px;
    padding: 10px;
    position: absolute;
    cursor: pointer;
}
@media only screen and (max-width: 1400px){
    .our-services-float-item .our-services-float-item-container{
        width: 80%;
    }
}
@media only screen and (max-width: 1125px){
    .our-services-float-item .our-services-float-item-container{
        width: 90%;
    }
}
@media only screen and (max-width: 992px){
    .ourServices-container .content-item:hover{
        transform: scale(1.08);
    }
    .ourServices-container .content-item .title-header:hover{
        left: 0px;
        width: 100%;
        height: 100px;
        bottom: 0px;
        display: block;
        padding: 20px 10px;
        position: absolute;
        align-items: center;
        border-radius: 0px 0px 20px 20px;
        background-color: rgba(7, 7, 7, 0.685);
    }
    .ourServices-container .content-item .title-header:hover .item{
        display: flex;
    }
    .ourServices-container .body .item{
        margin-bottom: 50px;
    }
    .ourServices-container .content-item .title-header:hover .title{
        font-size: 14px;
        text-align: left;
    }
    .ourServices-container .content-item .title-header:hover .app-link .icon{
        padding: 10px;
    }
}
@media only screen and (max-width: 767px){
    .ourServices-container .content-item .title-header{
        height: 80px;
    }
    .ourServices-container .content-item .title-header:hover{
        height: 80px;
    }
    .ourServices-container .content-item:hover{
        transform: scale(1.05);
    }
    .our-services-float-item .our-services-float-item-container{
        height: 90vh;
        padding: 0px;
    }
    .our-services-float-item .inner-our-services-float-item{
        width: 100%;
        height: 90vh;
        display: block;
    }
    .our-services-float-item .inner-our-services-float-item .left{
        width: 100%;
        height: 300px;
    }
    .our-services-float-item .inner-our-services-float-item .left img{
        object-fit: cover;
    }
    .our-services-float-item .inner-our-services-float-item .right{
        width: 100%;
        height: 300px;
        text-align: center;
        overflow-Y: auto;
    }
    .our-services-float-item .inner-our-services-float-item .right::-webkit-scrollbar{
        width: 5px;
        background: transparent;
    }
    .our-services-float-item .inner-our-services-float-item .right::-webkit-scrollbar-thumb {
        background: transparent;
    }
}
@media only screen and (max-width: 567px){
    .our-services-float-item .inner-our-services-float-item .right .body{
        font-size: 16px;
        line-height: 25px;
    }
}
/* ************* HOME TESTIMONIAL SECTION ************ */
.home-reviews-container{
    background-repeat: no-repeat;
    background-size: cover;
    width: 100%;
    height: 100%;
    background-color: #D8E3E2;
}
.home-reviews-container .home-reviews-inner{
    width: 90%;
    margin: 0 auto;
    padding: 60px 0px;
}
.home-reviews-container .home-reviews-inner .title-header h3{
    font-size: 40px;
    font-weight: bold;
    font-family: 'chillax';
}
.home-reviews-inner .slider-frame{
    margin-top: 50px;
}
.home-reviews-inner .slide-item{
    background-color: #fff;
    margin: 0px 10px;
    padding: 40px 20px;
    border-radius: 10px;
}
.home-reviews-inner .slide-item .title-header h3{
    font-size: 30px;
    font-family: 'chillax';
}
.home-reviews-inner .slide-item .body p{
    font-size: 14px;
    line-height: 20px;
    -webkit-user-select: none; /* Safari */
  -ms-user-select: none; /* IE 10 and IE 11 */
  user-select: none; /* Standard syntax */
}
.home-reviews-inner .slide-item .user-profile{
    width: 100%;
    display: flex;
    align-items: center;
}
.home-reviews-inner .slide-item .user-profile .image{
    margin-right: 10px;
}
.home-reviews-inner .slide-item .user-profile .image img{
    width: 70px;
    height: 70px;
    border-radius: 50%;
}
.home-reviews-inner .slide-item .user-profile .detail ul li{
    font-size: 13px;
    line-height: 20px;
}
.home-reviews-inner .slide-item .body {
    height: 150px;
    overflow: hidden;
}
.home-reviews-inner .slide-item .body.enlarge {
    height: inherit;
}
.home-reviews-inner .slide-item .body p span.view-more{
    cursor: pointer;
    margin-left: 10px;
    color: #FDB813;
}
.home-reviews-inner .slide-item .user-profile .detail ul li.name{
    font-weight: bold;
    text-transform: capitalize;
}
.home-reviews-inner .slide-item .user-profile .detail ul li.location{
    color: #555;
    text-transform: capitalize;
}
.home-reviews-inner.testimonial .react-multi-carousel-list button{
    display: none;
}
@media only screen and (max-width: 1200px){
    .home-reviews-inner .slide-item .user-profile .image img{
        width: 50px;
        height: 50px;
    }
}
@media only screen and (max-width: 767px){
    .home-reviews-container .home-reviews-inner .title-header h3{
        font-size: 30px;
    }
    .home-reviews-inner .slide-item .body p{
        font-size: 12px;
    }
    .home-reviews-inner .slide-item .title-header h3{
        font-size: 20px;
    }
}

/* ************* APP ICON SECTION *********** */
.app-icon-container{
    width: 100%;
    margin: 10px 0px;
    text-align: center;
}
.app-icon-container img{
    width: 200px;
    margin: 50px 20px;
    border-radius: 5px;
}
@media only screen and (max-width: 567px){
    .app-icon-container img{
        width: 40%;
        margin: 50px 10px;
    }
}
@media only screen and (max-width: 398px){
    .app-icon-container img{
        
        margin: 50px 10px;
    }
}

/* ************* FAQ SECTION *************** */
.faq-container{
    width: 90%;
    display: flex;
    margin: 0 auto;
    padding: 50px 0px;
}
.faq-container .left-side{
    width: 60%;
}
.faq-container .right-side{
    width: 40%;
}
.faq-container .right-side .title-header h3{
    font-weight: 800;
    font-size: 64px;
    line-height: 65px;
    letter-spacing: 0.005em;
    color: rgba(0, 0, 0, 0.2)
}
.faq-container .left-side .faq-item ul{
    padding-right: 20px;
}
.faq-container .left-side .faq-item li{
    padding: 10px;
    cursor: pointer;
    font-size: 20px;
    border-radius: 5px;
}
.faq-container .left-side .faq-item li .icon{
    color: #333;
}
.faq-container .left-side .faq-item li .item-title{
    display: flex;
    align-items: center;
    padding: 10px 20px 10px 5px;
    padding-bottom: 10px;
    transition: all 0.3s ease;
    justify-content: space-between;
} 
.faq-container .left-side .faq-item li .item-title.active,
.faq-container .left-side .faq-item li .item-title:hover{
    color: #fff;
    background-color: #FDB813;
}
.faq-container .left-side .faq-item li .item-title span{
    line-height: 25px;
}
.faq-container .left-side .faq-item li .item-body{
    height: 0px;
    overflow: hidden;
    color: #6b6b6b;
    font-size: 16px;
    line-height: 25px;
}
.faq-container .left-side .faq-item li .item-body.active{
    height: 100%;
    overflow: none;
}
@media only screen and (max-width: 992px){
    .faq-container .right-side .title-header h3{
        font-size: 55px;
    }
}
@media only screen and (max-width: 767px){
    .faq-container{
        width: 100%;
    }
    .faq-container .right-side{
        display: none;
    }
    .faq-container .left-side{
        width: 100%;
    }
    .faq-container .left-side .faq-item li .item-title{
        padding: 10px 5px;
    }
    .faq-container .left-side .faq-item ul{
        padding-right: 0px;
    }
    .faq-container .left-side .faq-item li .item-body{
        padding: 0px 10px;
    }
}
@media only screen and (max-width: 398px){
    .faq-container .left-side .faq-item li{
        font-size: 18px;
    }
}

/* ***************** BLOG SECTION ************* */
.blog-page-banner-container {
    margin: 100px 0px;
}
.blog-banner .blog-banner-content{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.blog-banner .left-side,
.blog-banner .right-side{
    width: 47%;
}
.blog-banner .left-side{
    padding-left: 70px;
}
.blog-banner .left-side .title-header h3{
    font-size: 40px;
    font-weight: bold;
    margin: 0px;
    margin-bottom: 25px;
    line-height: 35px;
}
.blog-banner .left-side .body{
    margin-bottom: 30px;
}
.blog-banner .left-side .body p{
    font-size: 20px;
    font-weight: 300;
    line-height: 20px;
}
.blog-banner .right-side img{
    top: -8px;
    width: 300px;
    height: 300px;
    position: relative;
}
.blog-banner .right-side .banner-img{
    height: 400px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #FDB813;
    border-radius: 200px 0px 0px 200px;
}
.blog-banner .left-side .button{
    width: 300px;
    padding: 10px 20px;
    border-radius: 35px;
    transition: all 0.3s ease;
    background-color: #FDB813;
    border: 2px solid transparent;
}
.blog-banner .left-side .button:hover{
    background-color: rgb(250, 202, 90);
    border: 2px solid #FDB813;
}
.blog-banner .left-side .button .app-link{
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.blog-banner .left-side .button .app-link:hover{
    color: #333;
}
.blog-banner .left-side .button .app-link span{
    font-size: 18px;
    font-weight: bold;
}
.blog-banner .left-side .button .app-link .icon{
    border-radius: 50%;
    padding: 12px;
    transform: rotate(-50deg);
    background-color: #333;
}
@media only screen and (max-width: 1125px){
    .blog-banner .right-side .banner-img{
        height: 350px;
    }
    .blog-banner .right-side img{
        width: 250px;
        height: 250px;
    }
}
@media only screen and (max-width: 992px){
    .blog-banner .right-side img{
        width: 200px;
        height: 200px;
    }
    .blog-banner .left-side .button{
        width: 100%;
    }
}
@media only screen and (max-width: 767px){
    .blog-banner .right-side{
        display: none;
    }
    .blog-banner .left-side{
        width: 100%;
        padding: 0px;
        text-align: center;
    }
    .blog-banner {
        width: 95%;
        margin: 0 auto;
        margin-top: 100px;
        margin-bottom: 100px;
    }
    .blog-banner .left-side .button{
        width: 70%;
        margin: 0 auto;
    }
    .blog-banner .left-side .title-header h3{
        font-size: 35px;
    }
}
@media only screen and (max-width: 567px){
    .blog-banner .left-side .button{
        width: 100%;
    }
}
/* ***************** FETAURED POST SECTION ************* */
.featured-post{
    width: 90%;
    margin: 0 auto;
    padding: 50px 0px;
}
.featured-post .main-title-header{
    width: 100%;
    margin-bottom: 30px;
}
.featured-post .main-title-header h3{
    font-size: 35px;
    text-align: left;
    font-weight: bold;
    text-transform: capitalize;
}
.featured-post .featured-item{
    padding: 10px;
    border-radius: 10px;
    margin-bottom: 40px;
    transition: all 0.3s ease;
    border: 2px solid rgb(221, 221, 221);
}
.featured-post .featured-item:hover{
    transform: scale(1.04);
    border: 2px solid rgb(201, 201, 201);
}
.featured-post .featured-item .top-item{
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.featured-post .featured-item .top-item .left-side,
.featured-post .featured-item .top-item .right-side{
    width: 50%;
}
.featured-post .featured-item .top-item .right-side{
    text-align: right;
}
.featured-post .featured-item .top-item .right-side img{
    width: 180px;
}
.featured-post .featured-item .top-item .left-side .title-header h3{
    color: #FDB813;
    font-size: 22px;
}
.featured-post .featured-item .top-item .left-side .body p{
    font-weight: 400;
    font-size: 18px;
    line-height: 30px;
}
.featured-post .bottom-item{
    display: flex;
    align-items: center;
}
.featured-post .bottom-item .content{
    margin-left: 10px;
}
.featured-post .bottom-item .image img{
    width: 50px;
    height: 50px;
    border-radius: 50%;
    border: 1px solid rgb(233, 233, 233);
}
.featured-post .bottom-item ul li{
    line-height: 25px;
}
.featured-post .bottom-item ul li.name {
    color: #333;
    font-size: 20px;
    font-weight: 400;
    text-transform: capitalize;
}
.featured-post .bottom-item ul li.date {
    font-size: 15px;
    color: rgb(145, 145, 145);
}
.featured-post .bottom-item ul li.date .icon{
    top: -3px;
    font-size: 10px;
    color: #9DD7C9;
    position: relative;
}
.featured-post .bottom-item ul li.date span.duration{
    margin-left: 10px;
}
@media only screen and (max-width: 992px){
    .featured-post{
        width: 100%;
        padding: 50px 20px;
    }
    .featured-post .featured-item .top-item .right-side img{
        width: 150px;
    }
}
@media only screen and (max-width: 767px){
    .featured-post .featured-item .top-item .right-side img{
        width: 200px;
    }
}
@media only screen and (max-width: 567px){
    .featured-post .featured-item .top-item .right-side img{
        width: 140px;
    }
    .featured-post .featured-item .top-item .left-side .body p{
        margin: 0px;
        font-size: 16px;
        line-height: 23px;
    }
    .featured-post .main-title-header h3{
        font-size: 30px;
    }
    .featured-post{
        width: 100%;
        padding: 50px 10px;
    }
}
@media only screen and (max-width: 398px){
    .featured-post .main-title-header h3{
        font-size: 28px;
    }
    .featured-post .featured-item .top-item .right-side img{
        width: 100%;;
    }
    .featured-post .featured-item .top-item .left-side .body p{
        font-size: 14px;
    }
    .featured-post .bottom-item ul li.date{
        font-size: 12px;
    }
    .featured-post .bottom-item ul li.date .icon{
        top: 0px;
    }
    .featured-post .bottom-item ul li.date span.duration{
        margin-left: 5px;
    }
}
/* ***************** TRANDING BLOG SECTION ************** */
.trending-blog{
    width: 90%;
    margin: 0 auto;
    padding-bottom: 100px;
}
.trending-blog .main-title-header{
    width: 100%;
    margin-bottom: 30px;
}
.trending-blog .main-title-header h3{
    font-size: 35px;
    text-align: left;
    font-weight: bold;
    text-transform: capitalize;
}
.trending-blog .trending-blog-body .left-side,
.trending-blog .trending-blog-body .right-side{
    width: 100%;
    height: 600px;
    position: relative;
}

.trending-blog .trending-blog-body .left-side img,
.trending-blog .trending-blog-body .right-side img{
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 10px;
}
.trending-blog-body .left-side .float-parent,
.trending-blog-body .right-side .float-parent{
    bottom: 110px;
    z-index: 10;
    width: 100%;
    height: 1px;
    cursor: pointer;
    position: absolute;
    transition: bottom 0.3s ease;
}
.trending-blog-body .left-side .float-parent:hover,
.trending-blog-body .right-side .float-parent:hover{
    bottom: 120px;
}
.trending-blog-body .left-side .float-content,
.trending-blog-body .right-side .float-content{
    width: 90%;
    margin: 0 auto;
    padding: 15px;
    border-radius: 10px;
    background-color: #fff;
    box-shadow: 2px 2px 4px 0px rgb(154, 154, 156);
}
.trending-blog-body .left-side .float-content  .top .title-header h3,
.trending-blog-body .right-side .float-content  .top .title-header h3{
    margin: 0px;
    color: #FDB813;
    font-size: 22px;
}
.trending-blog-body .left-side .float-content .top .text p,
.trending-blog-body .right-side .float-content .top .text p{
    color: #333;
    font-size: 18px;
    font-weight: 600;
    line-height: 30px;
    margin: 0px 0px 10px 0px;
}
.trending-blog-body .left-side .float-content .float-bottom,
.trending-blog-body .right-side .float-content .float-bottom{
    display: flex;
}
.trending-blog-body .left-side .float-content .float-bottom .image,
.trending-blog-body .right-side .float-content .float-bottom .image{
    margin-right: 10px;
}
.trending-blog-body .left-side .float-content .float-bottom img,
.trending-blog-body .right-side .float-content .float-bottom img{
    width: 50px;
    height: 50px;
    border-radius: 50%;
    border: 1px solid rgb(219, 219, 219);
}
.trending-blog-body .left-side .float-content .float-bottom .content ul li,
.trending-blog-body .right-side .float-content .float-bottom .content ul li{
    line-height: 25px;
}
.trending-blog-body .left-side .float-content .float-bottom .content ul li.name,
.trending-blog-body .right-side .float-content .float-bottom .content ul li.name{
    font-size: 21px;
}
.trending-blog-body .left-side .float-content .float-bottom .content ul li.date,
.trending-blog-body .right-side .float-content .float-bottom .content ul li.date{
    font-size: 15px;
    line-height: 15px;
    color: rgb(145, 145, 145);
}
.trending-blog-body .left-side .float-content .float-bottom .content ul li.date span .icon,
.trending-blog-body .right-side .float-content .float-bottom .content ul li.date span .icon{
    top: -3px;
    font-size: 8px;
    color: #9DD7C9;
    position: relative;
}
.trending-blog-body .left-side .float-content .float-bottom .content ul li.date span.duration,
.trending-blog-body .right-side .float-content .float-bottom .content ul li.date span.duration{
    margin-left: 10px;
}
.trending-blog-body .right-side-main .right-side{
    width: 100%;
    height: 200px;
    margin-bottom: 50px;
}
.trending-blog-body .right-side-main{
    height: 600px;
    overflow: auto;
}
.trending-blog .trending-blog-body .right-side img{
    width: 100%;
    height: 100%;
    object-fit: cover;
}
.trending-blog-body .right-side-main::-webkit-scrollbar{
    width: 1px;
}
@media only screen and (max-width: 992px){
    .trending-blog-body .right-side-main{
        height: inherit;
        overflow: visible;
    }
    .trending-blog .trending-blog-body .left-side,
    .trending-blog .trending-blog-body .right-side{
        height: inherit;
    }
    .trending-blog{
        width: 100%;
        padding: 0px 20px 50px 20px;
    }
    .trending-blog-body .right-side .float-content .top .text p{
        font-size: 14px;
    }
    .trending-blog-body .right-side .float-content .float-bottom .content ul li.date{
        font-size: 12px;
    }
    .trending-blog-body .left-side{
        margin-bottom: 100px;
    }
    .trending-blog-body .right-side-main .right-side{
        width: 100%;
        height: 100%;
        margin-bottom: 0px;
    }
    .trending-blog-body .right-side-main .item{
        margin-bottom: 70px;
    }
}
@media only screen and (max-width: 767px){
    .trending-blog-body .right-side-main .right-side{
        width: 100%;
        height: 100%;
        margin-bottom: 0px;
    }
    .trending-blog .trending-blog-body .left-side{
        margin-bottom: 70px;
    }
    .trending-blog .trending-blog-body .right-side{
        height: 100%;
    }
}
@media only screen and (max-width: 567px){
    .trending-blog{
        padding: 0px 10px 50px 10px;
    }
    .trending-blog-body .left-side .float-content ,
    .trending-blog-body .right-side .float-content {
        width: 95%;
    }
    .trending-blog-body .left-side .float-content .float-bottom .content ul li.date,
    .trending-blog-body .right-side .float-content .float-bottom .content ul li.date{
        font-size: 13px;
    }
}
@media only screen and (max-width: 398px){
    .trending-blog-body .left-side .float-content .float-bottom .content ul li.date,
    .trending-blog-body .right-side .float-content .float-bottom .content ul li.date{
        font-size: 11px;
    }
    .trending-blog-body .left-side .float-content .top .text p,
    .trending-blog-body .right-side .float-content .top .text p{
        font-size: 15px;
    }
    .trending-blog-body .left-side .float-content .float-bottom .content ul li.date span .icon,
    .trending-blog-body .right-side .float-content .float-bottom .content ul li.date span .icon{
        top: -2px;
    }
}
/* ************* BLOG PAGE HOW TO SECTION ************* */
.how-to-container{
    width: 90%;
    margin: 0 auto;
    margin-bottom: 100px;
}
.how-to-container .howto-content{
    display: flex;
    margin-bottom: 50px;
    justify-content: space-between;
}
.how-to-container .howto-content .left-side{
    width: 55%;
}
.how-to-container .howto-content .right-side{
    width: 45%;
}
.how-to-container .howto-content .left-side{
    height: 300px;
    border-radius: 5px;
    position: relative;
    background: #FDB813;
    box-shadow: 0px 0px 0px 0.548667px rgba(51, 51, 51, 0.25);
}
.how-to-container .howto-content .left-side{
    padding: 20px;
    text-align: right;
}
.how-to-container .howto-content .left-side img{
    top: 10px;
    right: 20px;
    width: 370px;
    height: 300px;
    position: absolute;
}
.how-to-container .howto-content .left-side .title-header{
    text-align: left;
}
.how-to-container .howto-content .left-side .title-header h3{
    font-weight: bold;
    font-size: 35px;
}
.how-to-container .howto-content .right-side{
    display: flex;
    align-items: center;
    padding-left: 20px;
}
.howto-content .right-side .right-howto-top .title-header{
    margin-bottom: 20px;
}
.howto-content .right-side .right-howto-top .title-header h3{
    color: #FDB813;
    font-size: 30px;
}
.howto-content .right-side .right-howto-top p{
    color: #333;
    font-weight: 600;
    font-size: 25px;
    line-height: 35px;
    margin-bottom: 30px;
}
.howto-content .right-side .right-howto-bottom{
    display: flex;
    align-items: center;
}
.howto-content .right-side .right-howto-bottom .image img{
    width:50px;
    height: 50px;
    border-radius: 50%;
    border: 1px solid rgb(226, 226, 226);
}
.howto-content .right-side .right-howto-bottom .content ul li.name{
    font-size: 20px;
    font-weight: 600;
}
.howto-content .right-side .right-howto-bottom .content{
    margin-left: 10px;
}
.howto-content .right-side .right-howto-bottom .content ul li{
    line-height: 25px;
}
.howto-content .right-side .right-howto-bottom .content ul li.date{
    font-size: 15px;
    line-height: 15px;
    color: rgb(145, 145, 145);
}
.howto-content .right-side .right-howto-bottom .content ul li.date span .icon{
    top: -3px;
    font-size: 8px;
    color: #9DD7C9;
    position: relative;
}
@media only screen and (max-width: 992px){
    .how-to-container{
        width: 100%;
        padding: 0px 20px;
    }
    .how-to-container .howto-content .left-side img{
        width: 290px;
        height: 250px;
    }
    .howto-content .right-side .right-howto-top p{
        font-size: 20px;
        margin-bottom: 10px;
    }
    .howto-content .right-side .right-howto-top .title-header h3{
        font-size: 25px;
    }
    .howto-content .right-side .right-howto-top .title-header{
        margin-bottom: 10px;
    }
    .how-to-container .howto-content .left-side .title-header h3{
        z-index: 10;
        font-size: 30px;
        position: relative;
    }
}
@media only screen and (max-width: 767px){
    .how-to-container{
        padding: 20px 20px;
        background-color: rgb(240, 240, 240);
    }
    .how-to-container .howto-content .left-side{
        display: none;
    }
    .how-to-container .howto-content .right-side{
        width: 100%;
        padding: 10px;
        border-radius: 5px;
        background-color: #fff;
        border: 1px solid rgb(231, 231, 231);
    }
}
@media only screen and (max-width: 567px){
    .how-to-container{
        padding: 20px 10px;
    }
    .howto-content .right-side .right-howto-top p{
        line-height: 26px;
        font-size: 18px;
    }
}
/* ************** CONTACT US SECTION ************* */
.max-word{
    color: rgb(117, 117, 117);
    font-size: 12px;
}
.contact-us-container .main-title-header{
    padding: 100px 0px;
    background-color: #FDB813;
}
.contact-us-container .main-title-header .title-header h3{
    color: #fff;
    font-size: 50px;
    text-align: center;
}
.contact-us-body{
    width: 90%;
    margin: 0 auto;
    padding: 70px 0px 150px 0px;
}
.contact-us-body .show-grid {
    display: flex;
    align-items: center;
    justify-content: center;
}
.contact-us-body .left-side .title-header h3{
    width: 100%;
    font-size: 30px;
    font-weight: bold;
    text-align: center;
}
.contact-us-body .left-side .image{
    width: 90%;
    text-align: center;
}
.contact-us-body .left-side .image img{
    width: 100%;
}
.contact-us-body .right-side .form{
    padding-left: 20px;
}
.contact-us-body .right-side .form label{
    font-size: 20px;
    font-weight: 600;
}
.contact-us-body .right-side .form input,
.contact-us-body .right-side .form textarea{
    border: none;
    padding: 10px;
    border-radius: 0px;
    border: 1px solid transparent;
    border-bottom: 1px solid #ccc;
}
.contact-us-body .right-side .form input.error,
.contact-us-body .right-side .form textarea.error{
    border: 1px solid rgb(252, 33, 33);
}
.contact-us-body .right-side .form input:focus,
.contact-us-body .right-side .form textarea:focus{
    outline: none;
    box-shadow: none;
    border-radius: 4px;
    border: 1px solid #FDB813;
}
.contact-us-body .right-side .form .button{
  margin: 30px 0px;
}
.contact-us-body .right-side .form button{
    border: none;
    color: #333;
    font-size: 18px;
    font-weight: 600;
    border-radius: 5px;
    padding: 5px 30px; 
    background-color: #FDB813;
    transition: background-color 0.4s ease;
}
.contact-us-body .right-side .form button:hover{
    background-color: #FDB813;
}
@media only screen and (max-width: 992px){
    .contact-us-body .left-side .image img{
        width: 100%;
    }
    .contact-us-body .left-side .title-header h3{
        text-align: center;
        font-size: 28px;
    }
}
@media only screen and (max-width: 767px){
    .page-bottom-help .need-help .right-side a{
        font-size: 15px;
        padding: 10px 30px;
    }
    .page-bottom-help .need-help p{
        font-size: 15px;
    }
    .contact-us-body .left-side .image{
        text-align: center;
    }
    .contact-us-body .left-side .image img{
        width: 300px;
    }
    .contact-us-body .right-side .form{
        padding-left: 0px;
    }
    .contact-us-body .right-side .form .form-group{
        margin-bottom: 20px;
    }
}
@media only screen and (max-width: 567px){
    .contact-us-container{
        margin-top: 50px;
    }
    .contact-us-body .left-side{
        margin-bottom: 30px;
    }
    .contact-us-body .left-side .image img{
        width: 90%;
    }
    .contact-us-body{
        width: 100%;
        padding: 0px 10px;
    }
    .contact-us-container .main-title-header{
        padding: 0px 0px;
        background-color: #fff;
    }
    .contact-us-container .main-title-header .title-header h3{
        color: #333;
        font-size: 40px;
    }
    .page-bottom-help .need-help{
        height: inherit;
        display: block;
        text-align: center;
        padding: 60px 0px;
        margin-top: 100px;
    }
    .contact-us-body .left-side .title-header{
        margin-bottom: 30px;
    }
    .contact-us-body .left-side .title-header h3{
        font-size: 20px;
    }
}
/* *************PAGE BOTTOM HELP SECTION ******************** */
.page-bottom-help .button{
    width: 300px;
    padding: 10px 20px;
    border-radius: 50px;
    margin-top: 50px;
    transition: all 0.3s ease;
    border: 2px solid transparent;
    background-color: #FDB813;
}
.page-bottom-help .button .app-link{
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.page-bottom-help .button .app-link span{
    font-size: 18px;
    font-weight: 500;
}
.page-bottom-help .button .app-link:hover{
    color:#333;
}
.page-bottom-help .button:hover{
    border: 2px solid #FDB813;
    background-color: #fff;
}
.page-bottom-help .button .app-link .icon{
    padding: 10px;
    border-radius: 50%;
    transform: rotate(-50deg);
    background-color: #333;
}
/* ************* HELP SECTION ******************** */
.page-bottom-help{
    width: 90%;
    margin: 0 auto;
    margin-bottom: 100px;
}
.page-bottom-help .need-help{
    width: 100%;
    padding: 100px 0px 100px 50px;
    background-size: cover;
    border-radius: 20px;
    background-color: #D8E3E2;
    background-repeat: no-repeat;
}
.helper.main-title-header{
    text-align: center;
    padding: 50px 0px;
    background-color: #FDB813;
}
.helper.main-title-header .title-header{
    width: 50%;
    margin: 0 auto;
}
.helper.main-title-header .title-header h3{
    color: #fff;
    font-size: 40px;
}
.page-bottom-help .need-help .title-header h3{
    font-size: 35px;
    font-weight: bold;
    line-height: 35px;
}
.help-container .help-body-container{
    width: 90%;
    margin: 0 auto;
    padding: 50px 0px;
}
.help-container .help-body-container .help-content{
    width: 100%;
    height: 250px;
    padding: 10px;
    cursor: pointer;
    position: relative;
    text-align: center;
    margin-bottom: 50px;
    border-radius: 10px;
    transition: all 0.2s ease;
    box-shadow: 0px 6px 10px 0px rgba(51, 51, 51, 0.25);
}
.help-container .help-body-container .help-content:hover{
    transform: scale(1.05);
    box-shadow: 0px 6px 10px 0px #FDB813;
}
.help-container .help-body-container .help-content .title{
    left: 0px;
    width: 100%;
    bottom: 0px;
    position: absolute;
}
.help-container .help-body-container .help-content .title h3{
    font-size: 16px;
    text-align: center;
}
.help-container .help-body-container .help-content .image{
   width: 100%;
   height: 100%;
   margin: 0 auto;
}
.help-container .help-body-container .help-content .image img{
    width: 100%;
    height: 100%;
 }
 .help-container .help-body-container .help-content .preloader{
    left: 0px;
    top: 0px;
    width: 100%;
    height: 100%;
    position: absolute;
    border-radius: 5px;
    background-color: #fff;
 }
 .help-container .help-body-container .help-content .preloader img{
    width: 20px;
    height: 20px;
 }
 .input-form-container{
    border-radius: 50px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: #fff;
    box-shadow: 0px 4px 5px 3px rgb(218, 218, 218);
 }
 .input-form-container .input{
        width: 80%;
        border: none;
        color: #555;
        font-size: 15px;
        padding: 10px 20px;
        font-weight: 500;
        
        background-color: transparent;
 }
.input-form-container button{
    right: 10px;
    border: none;
    display: flex;
    position: relative;
    align-items: center;
    background-color: transparent;
}
.input-form-container button .icon{
    padding: 10px;
    border-radius: 50%;
    background-color: #333;
    transform: rotate(-50deg);
}
.input-form-container .input:focus{
    border: none;
    outline: none;
}
 @media only screen and (max-width: 1125px){
    .help-container .help-body-container .help-content{
        height: 200px;
    }
 }
@media only screen and (max-width: 992px){
    .page-bottom-help{
        width: 100%;
        margin: 0px;
        margin-top: 100px;
    }
    .page-bottom-help .need-help{
        border-radius: 0px;
    }
}
@media only screen and (max-width: 767px){
    .input-form-container{
        width: 80%;
        margin: 0 auto;
        margin-top: 50px;
    }
    .helper.main-title-header .form input{
        width: 70%;
    }
    .helper.main-title-header .form button{
        width: 30%;
    }
    .helper.main-title-header .title-header{
        width: 100%;
    }
    .helper.main-title-header .form .form-item{
        width: 100%;
        padding: 0px 20px;
    }
    .page-bottom-help .need-help{
        text-align: center;
        padding: 100px 10px 100px 10px;
    }
    .page-bottom-help .need-help .button{
        margin: 0 auto;
        margin-top: 40px;
    }
}
@media only screen and (max-width: 567px){
    .input-form-container{
        width: 100%;
    }
    .page-bottom-help .need-help .button{
        width: 100%;
    }
    .page-bottom-help .need-help .title-header h3{
        font-size: 30px;
    }
    .helper.main-title-header .title-header h3{
        font-size: 32px;
        padding: 0px 10px;
    }
    .helper.main-title-header .form input{
        border-radius: 0px;
    }
    .helper.main-title-header .form button{
        border-radius: 0px;
    }
    .help-container .help-body-container{
        width: 100%;
        padding: 50px 10px 0px 10px;
    }
    .help-container .help-body-container .help-content{
        height: 180px;
    }
}
@media only screen and (max-width: 398px){
    .page-bottom-help .need-help .title-header h3{
        font-size: 25px;
    }
    .helper.main-title-header .title-header h3{
        font-size: 25px;
    }
    .help-container .help-body-container .help-content .image{
        height: 100%;
    }
    .help-container .help-body-container .help-content .title h3{
        font-size: 12px;
    }
    .help-container .help-body-container .help-content{
        height: 150px;
    }
}
@media only screen and (max-width: 320px){
    .helper.main-title-header .title-header h3{
        font-size: 20px;
    }
    .help-container .help-body-container .item{
        width: 100%;
    }
    .help-container .help-body-container .help-content{
        height: 200px;
    }
    .help-container .help-body-container .help-content .image{
        height: 100%;
    }
}
/* ************* HELP CONTENT SECTION **************** */
.help-content-container .help-content-body{
    width: 90%;
    margin: 0 auto;
    padding: 50px 0px;
}

.help-content-container .help-content-body .title-header h3{
    font-size: 40px;
    font-weight: bold;
}
.help-content-container .help-content-body .body{
    color: #333;
    font-size: 20px;
    line-height: 30px;
    margin: 20px 0px;
    font-stretch: expanded;
}
.help-content-container .support{
    width: 90%;
    color: #333;
    margin: 0 auto;
    font-size: 20px;
    line-height: 25px;
}
.help-content-container .support a{
    padding: 0px 10px;
    font-weight: 600;
    text-decoration: underline;
}
.help-content-container .support a:hover{
    color: #FDB813;
}
.help-content-container .like-container{
    width: 90%;
    margin: 0 auto;
    display: flex;
    padding: 20px 0px;
}
.help-content-container .like-container .likes,
.help-content-container .like-container .dis-likes{
    margin-right: 20px;
    font-size: 25px;
    font-weight: 600;
}
.help-content-container .like-container .icon{
    cursor: pointer;
    margin-right: 10px;
    transition: all 0.3s ease;
}
.help-content-container .like-container .icon:hover{
    color: #FDB813;
    transform: scale(1.20);
}
.help-content-container .helpful-btn{
    width: 90%;
    margin: 0 auto;
    margin-bottom: 30px;
}
.help-content-container .helpful-btn ul li{
    font-size: 20px;
    display: inline-block;
}
.help-content-container .helpful-btn ul li button{
    color: #FDB813;
    font-weight: 300;
    font-weight: 600;
    padding: 3px 40px;
    margin: 0px 10px;
    border-radius: 5px;
    border: 2px solid #333;
    background-color: #333;
    transition: all 0.3s ease;
}
.help-content-container .helpful-btn ul li button.active{
    color: #333;
    margin-left: 50px;
    border: 2px solid #FDB813;
    background-color: #FDB813;
}
.help-content-container .helpful-btn ul li button:hover,
.help-content-container .helpful-btn ul li button.active:hover{
    color: #FDB813;
    border: 2px solid #FDB813;
    background-color: transparent;
}
.help-content-container .help-featured-post{
    width: 90%;
    margin: 0 auto;
    padding: 50px 0px;
}
.help-content-container .help-featured-post .title-header{
    padding-bottom: 20px;
    border-bottom: 1px solid #ccc;
}
.help-content-container .help-featured-post .title-header h3{
    color: #404040;
    font-size: 35px;
    font-weight: bold;
}
.help-fetaured-post-body ul li{
    font-size: 18px;
    padding: 20px 0px;
    line-height: 25px;
    position: relative;
    transition: top 0.3s ease;
    background-color: #fff;
    border-bottom: 1px solid #ccc;
}
.help-fetaured-post-body ul li:hover{
    top: -10px;
    padding: 20px 10px;
    border-radius: 10px;
    border-bottom: 1px solid transparent;
    box-shadow: 2px 6px 7px 4px rgba(51, 51, 51, 0.25);
}
.help-fetaured-post-body ul li .icon{
    font-size: 22px;
    padding: 5px 0px;
    margin-right: 10px;
}
.help-fetaured-post-body ul li a{
    color: #333;
    display: flex;
    align-items: center;
}
@media only screen and (max-width: 767px){
    .help-content-container .support{
        width: 100%;
        font-size: 18px;
        padding: 0px 10px;
    }
    .help-content-container .help-featured-post{
        width: 100%;
        padding: 50px 10px;
    }
    .help-content-container .help-content-body{
        width: 100%;
        padding: 50px 10px;
    }
    .help-content-container .help-content-body .body{
        font-size: 18px;
    }
    .help-content-container .help-content-body .title-header h3{
        font-size: 35px;
    }
    .help-content-container .helpful-btn ul li{
        font-size: 18px;
        text-align: center;
    }
    .help-content-container .helpful-btn{
        width: 100%;
        padding: 0px 10px;
    }
    .help-content-container .helpful-btn ul li button{
        margin: 0px 10px;
    }
    .help-content-container .helpful-btn ul li button.active{
        margin: 0px 10px;
    }
    .help-fetaured-post-body ul li{
        border: none;
        border-radius: 5px;
        margin: 30px 0px;
        padding: 20px 10px;
        border: 1px solid rgb(245, 245, 245);
        box-shadow: 0px 5px 3px 0px rgba(51, 51, 51, 0.25);
    }
    .help-fetaured-post-body ul li:hover{
        top: 0px;
        border: none;
        box-shadow: 4px 6px 10px 4px rgba(51, 51, 51, 0.25);
    }
    .help-content-container .help-featured-post .title-header{
        padding-bottom: 10px;
        margin-bottom: 20px;
    }
}
@media only screen and (max-width: 567px){
    .help-content-container .helpful-btn ul li{
        display: block;
    }
    .help-content-container .help-content-body .title-header h3{
        font-size: 30px;
        text-align: center;
    }
    .help-content-container .help-content-body .body{
        font-size: 14px;
        line-height: 25px;
    }
    .help-content-container .support{
        font-size: 14px;
        line-height: 25px;
    }
    .help-content-container .like-container .likes,
    .help-content-container .like-container .dis-likes{
        font-size: 20px;
    }
    .help-content-container .helpful-btn ul li{
        font-size: 14px;
    }
    .help-fetaured-post-body ul li{
        font-size: 16px;
    }
}
@media only screen and (max-width: 398px){
    .help-content-container .help-content-body .title-header h3{
        font-size: 27px;
    }

}
@media only screen and (max-width: 320px){
    .help-content-container .help-content-body .title-header h3{
        font-size: 24px;
    }
}

/* ****************** AWAIT LIST SECTION ************** */
.await-list-container{
    z-index: 20;
    position: absolute;
}
.await-list-container .dark-theme{
    top: 0px;
    z-index: 10;
    left: 0px;
    height: 100vh;
    width: 100%;
    position: fixed;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgba(51, 51, 51, 0.787);
}
.await-list-container .await-body{
    width: 70%;
    display: flex;
    position: relative;
    background-color: #fff;
}
.await-list-container .await-body .left,
.await-list-container .await-body .right{
    width: 50%;
}
.await-list-container .await-body .right{
    text-align: center;
    background-color: #FDB813;
    background-image: linear-gradient(to bottom right, #FDB813, rgb(104, 75, 2));
}
.await-list-container .await-body .top-image{
    display: none;
}
.await-list-container .await-body .top-image .image img{
    object-fit: cover;
}

.await-list-container .await-body .left{
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #fff;
}
.await-list-container .await-body .left .left-container{
    padding: 20px 30px;
}
.await-list-container .await-body .left .title-header{
    text-align: center;
}
.await-list-container .await-body .left .title-header h3{
    font-size: 35px;
}
.await-list-container .await-body .left .body p{
    font-size: 20px;
    line-height: 25px;
    text-align: center;
    font-weight: 300;
}
.await-list-container .await-body .left .form input{
    width: 100%;
    font-size: 18px;
    border: none;
    padding: 5px;
    border: 1px solid transparent;
    background-color: rgb(233, 233, 233);
}
.await-list-container .await-body .left .form input:focus{
    outline: none;
    background-color: #fff;
    border: 1px solid rgb(255, 223, 150);
}
.await-list-container .await-body .left .form button.subscribe{
    border: none;
    width: 100%;
    color: #fff;
    padding: 5px;
    font-weight: 600;
    font-size: 20px;
    margin: 15px 0px;
    transition: all 0.3s ease;
    background-color: #FDB813;
}
.await-list-container .await-body .left .form button.subscribe:hover{
    background-color: rgb(241, 170, 4);
}
.await-list-container .await-body .cancel-button{
    top: 0px;
    left: 0px;
    height: 0px;
    position: absolute;
}
.await-list-container .await-body .cancel-button .icon{
    left: 10px;
    width: 30px;
    height: 30px;
    cursor: pointer;
    position: relative;
    font-size: 20px;
    border-radius: 3px;
    transition: color 0.3s ease;
}
.await-list-container .await-body .cancel-button .icon:hover{
    color:  #FDB813;

}
.await-list-container .await-body .top-image{
    text-align: center;
}
.await-list-container .confirmation-container{
    text-align: center;
}
.await-list-container .confirmation-container .icon{
    font-size: 65px;
    color:  #FDB813;
}
.await-list-container .confirmation-container h3{
    font-size: 35px;
}
.await-list-container .confirmation-container p{
    font-size: 20px;
    margin: 0px;
    line-height: 25px;
}
.await-list-container .confirmation-container button{
    width: 50%;
    border: none;
    color: #fff;
    font-size: 25px;
    font-weight: 500;
    padding: 5px 0px;
    border-radius: 3px;
    margin: 15px 0px 0px 0px;
    background-color:  #FDB813;
    transition: background-color 0.3s ease;
}
.await-list-container .confirmation-container button:hover{
    background-color:  #dc9a02;
}
@media only screen and (max-width: 1125px){
    .await-list-container .confirmation-container h3{
        font-size: 26px;
    }
    .await-list-container .confirmation-container p{
        font-size: 18px;
    }
}
@media only screen and (max-width: 992px){
    .await-list-container .await-body{
        width: 90%;
    }
}
@media only screen and (max-width: 767px){
    .await-list-container .await-body .cancel-button .icon:hover{
        color: rgb(241, 170, 4);
        background-color: rgb(210, 149, 9);
     }
    .await-list-container .await-body{
        width: 96%;
        display: block;
        padding-top: 20px;
        background-color: #FDB813;
        background-image: linear-gradient(to bottom right, #FDB813, rgb(156, 113, 3));
    }
    .await-list-container .await-body .right{
        display: none;
    }
    .await-list-container .await-body .top-image{
        display: block;
    }.await-list-container .await-body{
        border-radius: 6px;
    }
    .await-list-container .await-body .left{
        width: 100%;
        padding: 0px;
        border-radius: 6px;
        border: 1px solid #ccc;
    }
    .await-list-container .await-body .left .title-header h3{
        font-size: 30px;
    }
    .await-list-container .await-body .top-image .image img{
        width: 40%;
    }
}
@media only screen and (max-width: 567px){
    .await-list-container .await-body{
        border-radius: 6px;
    }
    .await-list-container .await-body .top-image .image img{
        width: 45%;
    }
    .await-list-container .await-body .top-image{
        padding-top: 40px;
    }
    .await-list-container .confirmation-container h3{
        font-size: 25px;
    }
    .await-list-container .confirmation-container button{
        width: 100%;
    }
    .await-list-container .await-body .left .left-container{
        padding: 20px 10px;
    }
}
@media only screen and (max-width: 398px){
    .await-list-container .await-body .top-image .image img{
        width: 60%;
    }
}






















/* *********************************************************************************************************************** *
                   UPDATED PAGES
****************************************************************************************************************************/

/* ********* CONTENT PRELOADER SECTION ********* */
.content-preloader-container{
    top: 0px;
    z-index: 20;
    left: 0px;
    width: 100%;
    height: 100vh;
    position: fixed;
    display: flex;
    align-items: center;
    background-color: #fff;
    justify-content: center;
}
.content-preloader-container .preloader-spinner{
    height: 50px;
    width: 50px;
    border-radius: 50%;
    border: 5px solid #fff;
    border-top: 5px solid #FDB813;
    border-right: 5px solid #FDB813;
    border-bottom: 5px solid #FDB813;
    -webkit-animation: spin 0.5s linear infinite;
    animation: spin 0.5s linear infinite;
}
.content-preloader-container .content-preloader-inner .text{
    right: 10px;
    text-align: center;
    color: #FDB813;
    font-size: 16px;
    font-weight: 600;
    position: relative;
}

@-webkit-keyframes spin {
    0% { -webkit-transform: rotate(0deg); }
    100% { -webkit-transform: rotate(360deg); }
  }
  
  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }
/* ************** BANNER SECTION *************** */

.page-banner-container{
    width: 70%;
    margin: 0 auto;
    margin-top: 100px;
    margin-bottom: 100px;
}
.page-banner-container .content-left,
.page-banner-container .content-right{
    width: 50%;
}
.page-banner-container .content-left .title-header h3,
.page-banner-container .content-left .title-header span{
    font-size: 3rem;
    line-height: 1.15;
    font-weight: 600;
    font-family: 'chillax';
}
.page-banner-container .content-left .paragraph{
    font-weight: 400;
    font-size: 20px;
    line-height: 30px;
    margin: 50px 0px;
    font-family: "Montserrat", sans-serif;
}
.download-left-buttons {
    margin-top: 70px;
}
.page-banner-container .content-left .download-left-buttons .title-header{
    margin: 20px 0px;
}
.page-banner-container .content-left .download-left-buttons .title-header h3{
    font-size: 20px;
    font-weight: 400;
}
.page-banner-container .content-left .download-left-buttons .buttons{
    margin: 20px 0px;
}
.page-banner-container .content-left .download-left-buttons img{
    width: 200px;
    height: 60px;
    margin-right: 10px;
    border-radius: 5px;
    transition: all 0.4s ease;
}
.page-banner-container .content-left .banner-button a{
    padding: 20px 70px;
    font-size: 18px;
    font-weight: 300;
    color: #000000;
    border-radius: 10px;
    background-color: #FDB813;
}
.page-banner-container .content-left .download-left-buttons img:hover{
    transform: scale(1.03);
}
.page-banner-container .page-banner-inner{
    width: 100%;
    height: 600px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.page-banner-container .content-right{
    height: inherit;
    text-align: right;
}
.page-banner-container .content-right .image{
    width: 100%;
    height: 100%;
}
.page-banner-container .content-right img{
    height: 100%;
}
.page-banner-container .content-left .mobile-banner-img{
    display: none;
}
@media only screen and (max-width: 1400px){
    .page-banner-container{
        width: 90%;
        margin: 0 auto;
    }
    .page-banner-container .page-banner-inner{
        width: 100%;
        height: 500px;
        margin-top: 30px;
    }
    .page-banner-container .content-right{
        height: 500px;
    }
    .page-banner-container .content-right{
        width: 40%;
    }
    .page-banner-container .content-right .image{
        width: 100%;
        text-align: right;
    }
    .page-banner-container .content-right .image img{
        width: 90%;
    }
    .page-banner-container .content-left .paragraph{
        font-size: 18px;
        margin: 30px 0px;
    }
    .page-banner-container .content-left .title-header h3,
    .page-banner-container .content-left .title-header span{
        font-size: 37px;
    }
    .download-left-buttons {
        margin-top: 0px;
    }
    .page-banner-container .content-left .download-left-buttons .title-header h3{
        font-size: 18px;
        font-weight: 400;
    }
    .page-banner-container .content-left .download-left-buttons .buttons{
        margin: 10px 0px;
    }
}
@media only screen and (max-width: 1200px){
    .page-banner-container .content-right{
        height: 400px;
    }
    .page-banner-container{
        width: 90%;
        margin: 0 auto;
    }
}
@media only screen and (max-width: 992px){
    .page-banner-container{
        margin-top: 100px;
    }
    .page-banner-container .content-left{
        width: 100%;
        text-align: center;
    }
    .page-banner-container .page-banner-inner{
        height: inherit;
        display: block;
    }
    .page-banner-container .content-right{
        width: 100%;
        height: inherit;
        margin-top: 100px;
    }
    .page-banner-container .content-right .image{
        width: 70%;
        height: inherit;
        margin: 0 auto;
    }
    .page-banner-container .content-right .image img{
        width: 100%;
        object-fit: fill;
    }
}
@media only screen and (max-width: 767px){
    .page-banner-container{
        margin-top: 100px;
        margin-bottom: 50px;
    }
    .page-banner-container .content-right .image{
        width: 70%;
    }
    .page-banner-container .page-banner-inner{
        width: 100%;
        height: 100%;
    }
    .page-banner-container .content-left .title-header h3{
        font-size: 30px;
        text-align: center;
    }
    .page-banner-container .content-left .paragraph{
        font-size: 16px;
        text-align: center;
        line-height: 20px;
    }
    .page-banner-container .content-left .mobile-banner-img{
        width: 100%;
    }
    .page-banner-container .content-left .download-left-buttons{
        text-align: center;
    }
   
}
@media only screen and (max-width: 567px){
    .page-banner-container .content-left .paragraph{
        font-size: 12px;
    }
    .page-banner-container .content-left .download-left-buttons img{
        width: 150px;
        height: 45px;
        margin: 5px;
    }
    .page-banner-container .content-right .image{
        width: 100%;
    }
    .page-banner-container .content-left .download-left-buttons img{
        width: 120px;
        height: 40px;
        margin: 10px;
    }
    .page-banner-container .content-left .banner-button a{
        font-size: 14px;
    }
}
/* *************** PERSONAL CONTENT BANNER ************** */
.content-banner-container{
    width: 70%;
    margin: 0 auto;
    margin-top: 150px;
}
.content-banner-container .title-header {
    margin: 100px 0px;
    text-align: center;
}
.content-banner-container .title-header h3{
    color: #333;
    font-size: 45px;
    font-weight: bold;
    font-family: 'chillax';
}
.content-banner-container .title-header p{
    color: #0f0f0f;
    font-size: 18px;
    font-weight: 300;
}
.content-banner-container .content-banner-item{
    width: 100%;
    display: flex;
    height: 400px;
    cursor: pointer;
    padding: 20px 20px;
    align-items: center;
    text-align: center;
    border-radius: 10px;
    margin-bottom: 20px;
    /* background-color: #333; */
    justify-content: center;
    transition: all 0.3s ease;
    border-bottom: 7px solid  #FDB813;
    background-image: linear-gradient(#404040, black);
}
.content-banner-container .content-banner-item:hover{
    transform: scale(1.05);
}
.content-banner-container .content-banner-item ul li{
    color: #fff;
    margin: 10px 0px;
    font-size: 18px;
}
.content-banner-container .content-banner-item ul li img{ 
    width: 100px;
    height: 100px;
}
.content-banner-container .content-banner-item ul li.title{
    color: #fff;
    font-size: 25px;
    font-weight: 600;
    font-family: 'chillax';
}
.content-banner-container .content-banner-item ul li.text{
    font-size: 13px;
    font-weight: 300;
    line-height: 20px;
}
@media only screen and (max-width: 1400px){
    .content-banner-container{
        width: 90%;
    }
}
@media only screen and (max-width: 1400px){
    .content-banner-container .title-header h3{
        font-size: 30px;
    }
    .content-banner-container .title-header p{
        font-size: 16px;
        line-height: 20px;
    }
}
@media only screen and (max-width: 567px){
    .content-banner-container .content-banner-item ul li img{ 
        width: 80px;
        height: 80px;
    }
    .content-banner-container .content-banner-item ul li.text{
        font-size: 14px;
    }
    .content-banner-container .content-banner-item ul li.title{
        font-size: 30px;
    }
}
/* ************** PERSONAL HOW IT WORKS ********** */
.how-it-works-container{
    margin: 200px 0px;
}
.how-it-works-container .title-header{
    text-align: center;
    padding: 0px 20px;
    margin: 50px 0px 150px 0px;
}
.how-it-works-container .title-header h3{
    color: #333;
    font-size: 45px;
    font-weight: bold;
    font-family: 'chillax';
}
.how-it-works-container .title-header p{
    color: #0f0f0f;
    font-size: 18px;
    font-weight: 300;
}
.how-it-works-container .how-it-works-body{
    width: 70%;
    margin: 0 auto;
}
.how-it-works-container .how-it-works-body .show-grid{
    display: flex;
    align-items: center;
}
.howitworks-item-left-container{
    width: 90%;
}
.howitworks-item-left-container ul li{
    display: flex;
    cursor: pointer;
    font-size: 25px;
    margin: 20px 0px;
    border-radius: 10px;
    padding: 10px 30px;
    align-items: center;
    position: relative;
    justify-content: space-between;
    border-left: 7px solid #ffe4a6;
    transition: all 0.5s ease;
    background-color: #fff;
}
.howitworks-item-left-container ul li:hover{
    background-color: #FFFBFB;
    border-left: 7px solid #FDB813;
    box-shadow: 2px 2px 5px 2px rgba(191, 191, 191, 0.772);
}
.howitworks-item-left-container ul li .content{
    width: 90%;
    padding-left: 10px;
}
.howitworks-item-left-container ul li .content h3{
    color: black;
    font-size: 20px;
    margin: 5px 0px;
    font-weight: 500;
    line-height: 25px;
}
.howitworks-item-left-container ul li .content p{
    margin: 0px;
    color: black;
    font-size: 13px;
    font-weight: 300;
    line-height: 18px;
}
.howitworks-item-left-container ul li .number{
    width: 150px;
}
.howitworks-item-left-container ul li .number span{
    width: 70px;
    height: 70px;
    display: flex;
    text-align: center;
    border-radius: 50%;
    align-items: center;
    justify-content: center;
    background-color:  #ffe4a6;
}
.howitworks-item-left-container ul li:hover .number span{
    background-color:  #FDB813;
}
.howitworks-item-right-container{
    width: 100%;
    text-align: right;
}
.howitworks-item-right-container img{
    width: 80%;
}
.howitworks-item-right-container.mobile{
    display: none;
}
/* ------- how it works for agent page ------ */
.how-it-works-container.agent-page .howitworks-item-left-container ul{
    border-left: 2px solid #ccc;
}
.how-it-works-container.agent-page .howitworks-item-left-container ul li{
    border: none;
    padding: 0px;
    margin: 25px 0px;
    border-radius: 0px;
    align-items: center;
    justify-content: space-between;
}
.how-it-works-container.agent-page .howitworks-item-left-container ul li:hover{
    background-color: transparent;
    box-shadow: none;
}
.how-it-works-container.agent-page .howitworks-item-left-container ul li .number span{
    width: 70px;
    height: 70px;
    font-size: 16px;
    font-weight: bold;
    color: #FDB813;
    background-color:  #fff;
    border: 1px solid #FDB813;
}
.how-it-works-container.agent-page .howitworks-item-left-container ul li .number{
    left: -35px;
    width: inherit;
    position: relative;
}
.how-it-works-container.agent-page .howitworks-item-left-container ul li .content{
    padding-left: 0px;
}
@media only screen and (max-width: 1400px){
    .how-it-works-container .how-it-works-body{
        width: 90%;
    }
    .howitworks-item-right-container img{
        width: 80%;
    }
    .howitworks-item-left-container ul li .content p{
        margin: 0px;
        font-size: 14px;
    }
    .how-it-works-container.agent-page .howitworks-item-left-container ul{
        width: 90%;
        left: 20px;
        position: relative;
    }
}
@media only screen and (max-width: 1200px){
    .howitworks-item-left-container{
        width: 100%;
    }
    .howitworks-item-right-container img{
        width: 100%;
    }
}
@media only screen and (max-width: 992px){
    .howitworks-item-right-container{
        display: none;
    }
    .howitworks-item-right-container.mobile{
        display: block;
        margin-bottom: 100px;
    }
}
@media only screen and (max-width: 767px){
    .how-it-works-container{
        margin: 100px 0px;
    }
    .how-it-works-container .title-header{
        margin: 0px;
    }
    .how-it-works-container .title-header h3{
        font-size: 30px;
    }
    .how-it-works-container .title-header p{
        font-size: 16px;
        line-height: 20px;
    }
    .howitworks-item-left-container ul li{
        padding: 10px;
        border-bottom: 1px solid #f0f0f0;
    }
}
@media only screen and (max-width: 567px){
    .howitworks-item-left-container ul li .number{
        width: inherit;
        margin-right: 0px;
    }
    .how-it-works-container .title-header p{
        font-size: 14px;
    }
    .howitworks-item-left-container ul li .content p{
        font-size: 12px;
    }
}
/* **************** CONTENT SECTION *********** */
.main-content-container-1{
    width: 70%;
    margin: 0 auto;
    margin-top: 200px;
    margin-bottom: 200px;
}
.main-content-container-1 .content-item{
    margin-bottom: 200px;
}
.main-content-container-1 .inner-content-item{
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.main-content-container-1 .main-content-left,
.main-content-container-1 .main-content-right{
    width: 45%;
}
.main-content-container-1 .main-content-left .content{
    width: 100%;
}
.main-content-container-1 .main-content-left .title-header{
    margin-bottom: 50px;
}
.main-content-container-1 .main-content-left .title-header h3{
    font-size: 3rem;
    font-weight: 600;
    font-family: 'chillax';
}
.main-content-container-1 .main-content-left .body{
    font-size: 18px;
    font-weight: 500;
    line-height: 35px;
}
.main-content-container-1 .main-content-right .img{
    width: 100%;
    height: 600px;
}
.main-content-container-1 .main-content-right img{
    width: 90%;
    height: 100%;
}
.main-content-container-1 .inner-content-item.left .main-content-right .img{
    align-items: '';
    text-align: right;
}
.main-content-container-1 .main-content-mobile-img{
    display: none;
    margin-bottom: 50px;
}
.main-content-container-1 .main-content-mobile-img .img img{
   width: 100%;
}
.main-content-container-1 .content-item .content .link{
    width: 100%;
    margin-top: 100px;
}
.main-content-container-1 .content-item .content .link a{
    font-size: 20px;
    font-weight: 600;
    padding: 15px 30px;
    border-radius: 10px;
    background-color:  #FDB813;
    border: 3px solid #FDB813;
    transition: all 0.3s ease;
}
.main-content-container-1 .content-item .content .link a:hover{
    background-color:  #fdb7135a;
}
@media only screen and (max-width: 1400px){
    .main-content-container-1{
        width: 90%;
        margin-top: 150px;
        margin-bottom: 150px;
    }
    .main-content-container-1 .main-content-left{
        width: 50%;
    }
    .main-content-container-1 .main-content-right{
        width: 45%;
    }
    .main-content-container-1 .main-content-left .title-header{
        margin-bottom: 0px;
    }
    .main-content-container-1 .main-content-left .title-header h3{
        font-size: 40px;
        font-weight: bold;
    }
    .main-content-container-1 .main-content-left .body{
        font-size: 18px;
        font-weight: 300;
        line-height: 35px;
    }
    .main-content-container-1 .main-content-right .img{
        width: 100%;
        height: 500px;
    }
    .main-content-container-1 .content-item .content .link{
        margin-top: 10px;
    }
}
@media only screen and (max-width: 1200px){
    .main-content-container-1{
        width: 90%;
    }
    .main-content-container-1 .main-content-right .img{
        height: 450px;
    }
    .main-content-container-1 .main-content-right .img img{
        width: 100%;
    }
}
@media only screen and (max-width: 992px){
    .main-content-container-1 .main-content-left .body,
    .main-content-container-1 .main-content-left .title-header h3{
        text-align: center;
    }
    .main-content-container-1 .main-content-mobile-img .img{
        width: 90%;
        margin: 0 auto;
    }
    .main-content-container-1 .main-content-mobile-img{
        display: block;
    }
    .main-content-container-1 .main-content-right{
        display: none;
    }
    .main-content-container-1 .main-content-left,
    .main-content-container-1 .main-content-left.left{
        width: 100%;
        padding-left: 0px;
    }
    .main-content-container-1 .main-content-right .img{
        text-align: center;
    }
    .main-content-container-1 .main-content-right .img img{
        width: 80%;
    }
    .main-content-container-1 .content-item.left .main-content-right .img,
    .main-content-container-1 .content-item.right .main-content-right .img,
    .main-content-container-1 .content-item.left .main-content-left .content,
    .main-content-container-1 .content-item.right .main-content-left .content{
        padding: 0px;
    }
    .main-content-container-1 .content-item .content .link{
        text-align: center;
    }
}
@media only screen and (max-width: 767px){
    .main-content-container-1 .content-item{
        margin-bottom: 100px;
    }
    .main-content-container-1 .main-content-left .title-header{
        margin-bottom: 10px;
    }
    .main-content-container-1 .main-content-mobile-img .img{
        width: 100%;
    }
    .main-content-container-1 .main-content-right .img img{
        width: 100%;
    }
    .main-content-container-1 .main-content-left .title-header h3{
        font-size: 30px;
        margin: 0px;
        text-align: center;
    }
    .main-content-container-1 .main-content-left .body{
        font-size: 16px;
        line-height: 25px;
        text-align: center;
    }
    .main-content-container-1 .content-item .content .link a{
        font-size: 16px;
    }
}
@media only screen and (max-width: 567px){
    .main-content-container-1 .main-content-left .body{
        font-size: 14px;
    }
    .main-content-container-1 .content-item .content .link a{
        font-size: 13px;
    }
}
/* ******************** STEPS SECTION ***************** */
.page-content-steps{
    padding: 100px 0px 0px 0px;
}
.page-content-steps .inner-page-content-steps{
    width: 70%;
    margin: 0 auto;
}
.page-content-steps .inner-page-content-steps .steps-content{
    width: 70%;
    margin: 0 auto;
    margin-top: 50px;
    margin-bottom: 50px;
}
.page-content-steps .inner-page-content-steps .steps-content ul li{
    margin: 20px 0px;
    display: flex;
}
.page-content-steps .inner-page-content-steps .steps-content ul li .title{
    color: #333;
    font-weight: bold;
    font-size: 25px;
}
.page-content-steps .inner-page-content-steps .steps-content ul li .left{
    padding-right: 20px;
}
.page-content-steps .inner-page-content-steps .steps-content ul li .left .icon{
    color: #fff;
    padding: 10px 12px;
    font-size: 20px;
    margin-right: 10px;
    border-radius: 50%;
    background-color:  #FDB813;
}
.page-content-steps .inner-page-content-steps .steps-content ul li .body{
    color: #333;
    font-weight: 300;
    font-size: 16px;
    line-height: 25px;
}
.page-content-steps .inner-page-content-steps .steps-content ul li .link a{
    color: #FDB813;
    font-size: 14px;
}
@media only screen and (max-width: 1200px){
    .page-content-steps .inner-page-content-steps{
        width: 90%;
        margin: 0 auto;
    }
    .page-content-steps .inner-page-content-steps .steps-content{
        width: 80%;
    }
}
@media only screen and (max-width: 992px){
    .page-content-steps .inner-page-content-steps .steps-content{
        width: 95%;
    }
    .page-content-steps .inner-page-content-steps .steps-content ul li .body{
        font-size: 14px;
        line-height: 18px;
    }
}
@media only screen and (max-width: 767px){
    .page-content-steps .inner-page-content-steps .steps-content ul li .left{
        padding-right: 10px;
    }
    .page-content-steps .inner-page-content-steps .steps-content ul li .title{
        font-size: 20px;
        line-height: 20px;
    }
    .page-content-steps .inner-page-content-steps .steps-content ul li .body{
        font-size: 12px;
    }
}
/* ****************** BANNER 2 SECTION ************* */
.Banner2-container{
    margin-bottom: 200px;
    background-image: linear-gradient(to top, black, #333);
}
.Banner2-container ul{
    width: 70%;
    height: 500px;
    margin: 0 auto;
    display: flex;
    text-align: center;
    align-items: center;
    flex-direction: column;
    justify-content: center;
}
.Banner2-container ul li,
.Banner2-container ul li a{
    color: #fff;
}
.Banner2-container ul li{
    margin: 20px 0px;
}
.Banner2-container ul li .title-header h3{
    color: #fff;
    font-size: 60px;
    font-weight: bold;
    font-family: 'chillax';
}
.Banner2-container ul li p{
    color: #fff;
    font-size: 25px;
    font-weight: 300;
    line-height: 30px;
}
.Banner2-container ul li .link{
    cursor: pointer;
    font-size: 20px;
    font-weight: 300;
    padding: 20px 30px;
    border-radius: 7px;
    transition: all 0.5s ease;
    /* background-color:  #FDB813; */
    font-family: "Montserrat", sans-serif;
    background-image: linear-gradient(to top, #fcda8b, #FDB813);
}
.Banner2-container ul li .link:hover{
    background-image: linear-gradient(to top, #FDB813, #fcda8b);
}
@media only screen and (max-width: 992px){
    .Banner2-container ul{
        width: 90%;
    }
}
@media only screen and (max-width: 767px){
    .Banner2-container ul{
        width: 100%;
        padding: 50px 20px;
    }
    .Banner2-container ul li{
        margin: 20px 0px;
    }
    .Banner2-container ul li p,
    .Banner2-container ul li .link{
        font-size: 18px;
        line-height: 30px;
    }
    .Banner2-container ul li .title-header h3{
        font-size: 30px;
    }
    .Banner2-container ul li p{
        font-size: 14px;
        line-height: 20px;
    }
}
@media only screen and (max-width: 567px){
    .Banner2-container ul li p{
        font-size: 12px;
    }
    .Banner2-container ul li{
        margin: 10px 0px;
    }
    .Banner2-container ul li .link a{
        font-size: 16px;
    }
}
/* *************** PERSONAL CONTENT 2 SECTION *********** */
.content-2-container{
    width: 100%;
    margin: 0 auto;
    padding: 100px 0px;
}
.content-2-container .content-2-inner{
    width: 70%;
    margin: 0 auto;
    padding: 0px 100px;
    border-radius: 30px;
    background-color: #EFEFEF;
}
.content-2-container .content-2-inner .left{
    height: 100%;
    padding: 50px 0px;
}
.content-2-container .content-2-inner .left ul li{
    margin: 50px 0px;
}
.content-2-container .content-2-inner .left ul li h3{
    font-size: 45px;
    font-weight: bold;
    font-family: 'chillax';
}
.content-2-container .content-2-inner .left ul li p{
    font-size: 20px;
    font-weight: 300;
    line-height: 30px;
    font-family: "Montserrat", sans-serif;
}
.content-2-container .content-2-inner .left ul li img{
    width: 150px;
    height: 50px;
    margin-right: 20px;
    transition: all 0.3s ease;
}
.content-2-container .content-2-inner .left ul li img:hover{
    transform: scale(1.03);
}
.content-2-container .content-2-inner .right{
    height: 100%;
    position: relative;
}
.content-2-container .content-2-inner .right img{
    right: 0px;
    bottom: 0px;
    width: 300px;
    position: absolute;
}
@media only screen and (max-width: 1400px){
    .content-2-container .content-2-inner .left{
        padding: 0px 0px;
    }
}
@media only screen and (max-width: 1200px){
    .content-2-container .content-2-inner{
        width: 100%;
        padding: 0px 70px;
    }
    .content-2-container .content-2-inner .right img{
        width: 250px;
    }
    .content-2-container .content-2-inner .left{
        padding: 0px 0px;
    }
}
@media only screen and (max-width: 992px){
    .content-2-container{
        width: 100%;
    }
    .content-2-container .content-2-inner{
        width: 100%;
        padding: 0px 20px;
        border-radius: 0px;
    }
    .content-2-container .content-2-inner .right img{
        width: 200px;
    }
    .content-2-container .content-2-inner .left{
        padding: 30px 0px;
    }
    .content-2-container .content-2-inner .left ul li h3{
        font-size: 35px;
    }
    .content-2-container .content-2-inner .left ul li p{
        font-size: 17px;
        line-height: 25px;
    }
}
@media only screen and (max-width: 767px){
    .content-2-container .content-2-inner .right{
        display: none;
    }
    .content-2-container .content-2-inner .left{
        text-align: center;
    }
    .content-2-container .content-2-inner .left ul li{
        margin: 30px 0px;
    }
    .content-2-container .content-2-inner .left ul li h3{
        font-size: 30px;
    }
    .content-2-container .content-2-inner .left ul li p{
        font-size: 16px;
        line-height: 20px;
    }
    .content-2-container .content-2-inner .left ul li img{
        width: 150px;
        height: 50px;
        margin: 10px 10px;
    }
}
@media only screen and (max-width: 567px){
    .content-2-container .content-2-inner .left ul li p{
        font-size: 12px;
    }
}
@media only screen and (max-width: 398px){
    .content-2-container .content-2-inner .left ul li img{
        width: 120px;
        height: 40px;
        margin: 5px;
    }
}
@media only screen and (max-width: 320px){
    .content-2-container .content-2-inner .left ul li img{
        width: 100%;
        height: inherit;
    }
}
/* ******************* OUR PARTNERS *************** */
.our-partners-container{
    width: 90%;
    margin: 0 auto;
    padding: 100px 0px;
}
.our-partners-container .title-header{
    text-align: center;
}
.our-partners-container .title-header h3{
    font-size: 45px;
    font-weight: bold;
    font-family: 'Chillax', sans-serif;
}
.our-partners-container .partners-slider-item {
    padding: 30px 0px;
}
.our-partners-container .partners-slider-item .image{
    text-align: center;
}
.our-partners-container .partners-slider-item .image img{
    width: 100px;
    height: 50px;
    object-fit: contain;
}
.our-partners-container .react-multi-carousel-list button{
    display: none;
}
@media only screen and (max-width: 767px){
    .our-partners-container .title-header{
        border-bottom: 1px solid #dddddd;
    }
    .our-partners-container .title-header h3{
        font-size: 30px;
    }
    .our-partners-container{
        padding: 50px 0px;
    }
}
@media only screen and (max-width: 567px){
    .our-partners-container .title-header h3{
        font-size: 25px;
    }
    .our-partners-container .partners-slider-item .image img{
        width: 80px;
        height: 50px;
    }
    .our-partners-container .partners-slider-item .image img{
        width: 70px;
        height: 30px;
    }
    .our-partners-container{
        padding: 50px 0px 0px 0px;
    }
}
/* ************* DOWNLOAD APP SECTION ************* */
.mobile-banner-image{
    z-index: 10;
    width: 100%;
    height: 100px;
    bottom: 0px;
    display: flex;
    position: fixed;
    padding: 20px 20px;
    align-items: center;
    background-color:  #fdf8ed;
    justify-content: space-between;
}
.mobile-banner-image .left{
    display: flex;
    align-items: center;
    justify-content: center;
}
.mobile-banner-image .right img{
    width: 150px;
}
.mobile-banner-image .left .logo img{
    width: 50px;
    height: 50px;
}
.mobile-banner-image .left .text{
    top: 5px;
    padding: 0px 20px;
    position: relative;
}
.mobile-banner-image .left .text h3{
    margin: 0px;
    font-size: 20px;
    line-height: 5px;
    font-weight: 500;
}
.mobile-banner-image .left .text .stars .icon{
    color: #FDB813;
    font-size: 18px;
    margin-right: 5px;
}
@media only screen and (max-width: 767px){
    .mobile-banner-image .left .text h3{
        font-size: 18px;
    }
    .mobile-banner-image .left .text .stars .icon{
        font-size: 14px;
    }
}
@media only screen and (max-width: 567px){
    .mobile-banner-image{
        height: 80px;
        padding: 10px 10px;
    }
    .mobile-banner-image .left .text h3{
        font-size: 16px;
        line-height: 0px;
    }
    .mobile-banner-image .left .text .stars .icon{
        font-size: 12px;
        margin-right: 2px;
    }
    .mobile-banner-image .right img{
        width: 140px;
    }
}
/* **************** TITLE HEADER SECTION ************* */
.page-title-header{
    padding: 0px 20px;
    margin: 50px 0px 150px 0px;
}
.page-title-header h3{
    color: #000000;
    font-size: 45px;
    font-weight: bold;
    font-family: 'chillax';
}
.page-title-header p{
    color: #000000;
    font-size: 18px;
    font-weight: 300;
}
@media only screen and (max-width: 767px){
    .page-title-header h3{
        font-size: 30px;
    }
    .page-title-header p{
        font-size: 16px;
        line-height: 20px;
    }
}
@media only screen and (max-width: 567px){
    .page-title-header p{
        font-size: 14px;
    }
}
/* *********** REASONS SECTION ************** */
.reasons-contaniner{
    width: 70%;
    margin: 0 auto;
}
.content-card-container .content-card-items{
    width: 100%;
}
.content-card-container .content-card-items.left{
    padding-right: 50px;
}
.content-card-container .content-card-items.right{
    padding-left: 50px;
}
.content-card-container .content-card-items ul{
    width: 100%;
    height: 400px;
    padding: 50px 20px;
    margin-bottom: 70px;
    border-radius: 10px;
    background-color: #fff;
    box-shadow: 5px 5px 15px 5px rgba(231, 231, 231, 0.772);
}
.content-card-container .content-card-items ul li{
    text-align: center;
    margin: 20px 0px;
}
.content-card-container .content-card-items ul li img{
    width: 76px;
    height: 76px;
}
.content-card-container .content-card-items ul li.title{
    color: #000000;
    font-size: 30px;
    font-weight: bold;
    font-family: 'chillax';
}
.content-card-container .content-card-items ul li.text{
    color: #49454F;
    font-size: 18px;
    font-weight: 400;
    line-height: 25px;
}
@media only screen and (max-width: 1400px){
    .reasons-contaniner{
        width: 90%;
    }
}
@media only screen and (max-width: 1200px){
    .content-card-container .content-card-items ul li.text{
        font-size: 16px;
    }
}
@media only screen and (max-width: 992px){
    .content-card-container .content-card-items ul{
        padding: 20px;
    }
    .content-card-container .content-card-items.left{
        padding-right: 10px;
    }
    .content-card-container .content-card-items.right{
        padding-left: 10px;
    }
    .content-card-container .content-card-items ul li.text{
        font-size: 14px;
        line-height: 24px;
    }
}
@media only screen and (max-width: 767px){
    .content-card-container .content-card-items ul{
        height: inherit;
        padding: 50px 10px;
    }
    .content-card-container .content-card-items.left{
        padding-right: 0px;
    }
    .content-card-container .content-card-items.right{
        padding-left: 0px;
    }
    .content-card-container .content-card-items ul li.text{
        font-size: 14px;
        line-height: 24px;
    }
}
/* *************** OUR TRIBE ************* */
.our-tribes-container{
    width: 70%;
    margin: 0 auto;
    margin-top: 200px;
    margin-bottom: 200px;
}
.our-tribes-container .content-tribe-item{
    margin-bottom: 70px;
}
.our-tribes-container .content-tribe-item .image{
    width: 100%;
    height: 400px;
    border-radius: 10px;
    background-image: linear-gradient(#4E3805, #FDB813);
}
.our-tribes-container .content-tribe-item .image img{
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 10px;
}
.our-tribes-container .content-tribe-item ul{
    margin-top: 20px;
}
.our-tribes-container .content-tribe-item ul li{
    width: 100%;
    text-align: center;
}
.our-tribes-container .content-tribe-item ul li.title{
    font-size: 32px;
    color: #000000;
    line-height: 30px;
    font-family: 'chillax';
}
.our-tribes-container .content-tribe-item ul li.job-title{
    font-size: 22px;
    color: #000000;
}
@media only screen and (max-width: 1400px){
    .our-tribes-container{
        width: 90%;
    }
    .our-tribes-container .content-tribe-item .image{
        height: 350px;
    }
}
@media only screen and (max-width: 992px){
    .our-tribes-container .content-tribe-item .image{
        height: 450px;
    }
}
@media only screen and (max-width: 767px){
    .our-tribes-container .content-tribe-item .image{
        height: 350px;
    }
    .our-tribes-container .content-tribe-item ul li.title{
        font-size: 30px;
    }
    .our-tribes-container .content-tribe-item ul li.job-title{
        font-size: 16px;
    }
}
@media only screen and (max-width: 567px){
    .our-tribes-container .content-tribe-item .image{
        height: 470px;
    }
}
@media only screen and (max-width: 398px){
    .our-tribes-container .content-tribe-item .image{
        height: 400px;
    }
}
@media only screen and (max-width: 320px){
    .our-tribes-container .content-tribe-item .image{
        height: 320px;
    }
}
/* ************* OUR STACK ************** */
.our-stark-container {
    width: 70%;
    margin: 0 auto;
    padding: 70px 10px;
    border-radius: 20px;
    margin-top: 100px;
    margin-bottom: 100px;
    background-color: #EFEFEF;
}
.our-stark-container .title-header{
    text-align: center;
    margin-bottom: 100px;
}
.our-stark-container .title-header h3{
    font-size: 45px;
    font-weight: bold;
    font-family: 'Chillax';
}
.our-stark-container .slider-frame .frame-item img{
    width: 70px;
    height: 80px;
    cursor: pointer;
}
.our-stark-container .react-multi-carousel-list button{
    display: none;
}
@media only screen and (max-width: 1400px){
    .our-stark-container {
        width: 90%;
    }
}
@media only screen and (max-width: 1200px){
    .our-stark-container {
        padding: 50px 10px;
    }
    .our-stark-container .slider-frame .frame-item img{
        width: 65px;
        height: 70px;
    }
}
@media only screen and (max-width: 992px){
    .our-stark-container .title-header{
        margin-bottom: 70px;
    }
    .our-stark-container .slider-frame .frame-item img{
        width: 55px;
        height: 60px;
    }
}
@media only screen and (max-width: 567px){
    .our-stark-container .title-header h3{
        font-size: 30px;
    }
    .our-stark-container .title-header{
        margin-bottom: 50px;
    }
    .our-stark-container {
        width: 100%;
        margin: 0 auto;
        border-radius: 0px;
        background-color: #EFEFEF;
    }
}
/* ************* FAQ PAGE SECTION *************** */
.faq-page-container{
    width: 90%;
    margin: 0 auto;
    padding: 100px 0px;
}
.page-title-section .title-header{
    text-align: center;
}
.page-title-section .title-header span{
    color: black;
    font-size: 3rem;
    line-height: 1.15;
    font-weight: 600;
    font-family: 'chillax';
}
.page-title-section .text{
    margin-top: 20px;
}
.page-title-section .text p{
    color: black;
    font-size: 24px;
    line-height: 20px;
    text-align: center;
}
.faq-page-container .faq-content-container{
    margin-top: 150px;
}
.faq-page-container .faq-content-item{
    position: relative;
    margin: 20px 0px;
    padding: 20px 30px;
    border-radius: 8px;
    background-color: #EFEFEF;
}
.faq-page-container .faq-content-item .title{
    padding-right: 20px;
}
.faq-page-container .faq-content-item .title h3{
    font-family: 'chillax';
    font-size: 20px;
    line-height: 24px;
}
.faq-page-container .faq-content-item .text{
    display: none;
    padding-right: 20px;
    font-size: 14px;
    line-height: 24px;
    transition: height 1s ease; /* Transition height over 1 second */
}
.faq-page-container .faq-content-item .text.active{
    display: block;
}
.faq-page-container .faq-content-item .text ul{
    margin-top: 10px;
}
.faq-page-container .faq-content-item .text ul li{
    padding: 5px 0px;
    font-size: 14px;
    line-height: 24px;
    display: flex;
}
.faq-page-container .faq-content-item .text ul li .number{
    margin-right: 10px;
}
.faq-page-container .faq-content-item .icon-item{
    top: 15px;
    right: 20px;
    display: inline-block;
    position: absolute;
}
.faq-page-container .faq-content-item .icon-item .icon{
    cursor: pointer;
    color: #FDB813;
    font-size: 24px;
}
.empty-page-content{
    width: 100%;
    height: 70vh;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}
.empty-page-content .icon-item{
    text-align: center;
}
.empty-page-content .icon-item .icon{
    color: #e3e3e3;
    font-size: 100px;
}
.empty-page-content .text{
    font-size: 20px;
    margin: 20px 0px;
}
@media only screen and (max-width: 1400px){
    .faq-page-container{
        width: 90%;
    }
    .page-title-section .text p{
        font-size: 18px;
    }
}
@media only screen and (max-width: 1200px){
    .page-title-section .title-header span{
        font-size: 37px;
    }
    .page-title-section .text{
        margin-top: 10px;
    }
    .page-title-section .text p{
        font-size: 16px;
    }
}
@media only screen and (max-width: 767px){
    .page-title-section .title-header span{
        font-size: 35px;
    }
    .page-title-section .text p{
        font-size: 12px;
    }
    .empty-page-content .icon-item .icon{
        font-size: 70px;
    }
    .empty-page-content .text{
        font-size: 12px;
    }
    .faq-page-container .faq-content-item .title h3{
        font-size: 18px;
        line-height: 20px;
    }
    .faq-page-container .faq-content-item .text{
        font-size: 12px;
        line-height: 20px;
    }
    .faq-page-container .faq-content-item .text ul li{
        font-size: 12px;
        line-height: 20px;
    }
}
@media only screen and (max-width: 567px){
    .faq-page-container .faq-content-container{
        margin-top: 100px;
    }
    .faq-page-container .faq-content-item{
        padding: 10px 10px;
        background-color: #f5f5f5;
    }
    .faq-page-container .faq-content-item .title h3{
        font-size: 16px;
    }
    .faq-page-container .faq-content-item .icon-item{
        top: 0px;
        right: 10px;
    }
    .faq-page-container .faq-content-item .icon-item .icon{
        font-size: 16px;
    }
}















