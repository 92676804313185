
/* ************************************ */
/*     APP STYLES                       */
/* *********************************** */
*{
    font-family: 'Chillax', sans-serif;
}
body{
    /* font-family: 'Clash Display'; */
    font-style: normal;
    /* font-weight: 500; */
    font-size: 32px;
    line-height: 39px;
    letter-spacing: 0.005em;
    -webkit-user-select: none; /* Safari */
    -ms-user-select: none; /* IE 10 and IE 11 */
    user-select: none; /* Standard syntax */
}
ul, ol {
    margin-bottom: 0;
    list-style: none;
    padding: 0px;
    -webkit-user-select: none; /* Safari */
    -ms-user-select: none; /* IE 10 and IE 11 */
    user-select: none; /* Standard syntax */
  }
a {
    color: #333;
    text-decoration: none;
    background-color: transparent;
}
p, li, h3{
    -webkit-user-select: none; /* Safari */
    -ms-user-select: none; /* IE 10 and IE 11 */
    user-select: none; /* Standard syntax */
}
/* styling for toastify alert notification */
.Toastify{
    font-size: 14px;
    line-height: 18px;
}
/* ******* Jodit text editior */
.jodit-workplace{
    color: #333;
    font-size: 18px;
}
/* ************ ALERT ************* */
.form-input-alert{
    height: 10px;
    margin: 3px 0px;
    display: flex;
    align-items: center;
    background-color: transparent;
}
.form-input-alert span{
    font-size: 13px;
}
/* *********** APP DARK THEME *************** */
.app-dark-theme{
    top: 0px;
    left: 0px;
    z-index: 30;
    width: 100%;
    height: 100vh;
    position: fixed;
    background-color: rgba(0, 0, 0, 0.661);
}
/* ************ PRELOADER SECTION *********** */
.preloader-container{
    top: 0px;
    left: 0px;
    z-index: 30;
    width: 100%;
    height: 100%;
    position: fixed;
    background-color: #0f0f0fff;
}
.inner-preloader{
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}
.inner-preloader img{
    width: 200px;
    height: 50px;
}
.inner-preloader .loading{
    text-align: center;
    font-size: 16px;
    color: #ffd164;
    font-weight: bold;
}
.inner-preloader .loading{
    display: flex;
    align-items: center;
    justify-content: center;
}
.inner-preloader .loading .spinner{
    width: 20px;
    height: 20px;
    margin-right: 10px;
    border-radius: 50%;
    border: 3px solid #f3f3f3; 
    border-top: 3px solid #f6bd38;
    border-right: 3px solid #f6bd38;
    border-bottom: 3px solid #f6bd38;
    animation: spinner 0.5s linear infinite;
}
.content-preloader {
    top: 0px;
    left: 0px;
    z-index: 40;
    width: 100%;
    height: 100vh;
    display: flex;
    position: fixed;
    align-items: center;
    justify-content: center;
    background-color: rgba(0, 0, 0, 0.702);
}
.content-preloader .loading{
    text-align: center;
}
.content-preloader .loading h3{
    color: #fff;
    font-size: 18px;
    margin: 10px 0px;
}
.content-preloader .loading img{
    width: 100px;
    height: 100px;
}
.content-preloader .loading img{
    width: 100px;
    height: 100px;
}
@media only screen and (max-width: 767px){
    .content-preloader .loading img{
        width: 70px;
        height: 70px;
    }
    .content-preloader .loading h3{
        font-size: 16px;
    }
}
@keyframes spinner {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
}

/* ************ LOGIN SECTION *************** */
.auth-container{
    width: 100vw;
    height: 100vh;
    display: flex;
    position: relative;
    align-items: center;
    justify-content: center;
    background-color: #EFEFEF;
}
.auth-back-img{
    position: absolute;
    opacity: 0.2;
}
.auth-container .middile-container{
    width: 35%;
    padding: 20px;
}
.auth-container .middile-container .title-header h3{
    margin: 0px;
    font-size: 40px;
    text-align: center;
    font-weight: bold;
}
.auth-container .middile-container .title-header p{
    margin: 0px;
    font-size: 18px;
    text-align: center;
}
.auth-container .middile-container .form-group{
    margin: 10px 0px;
    position: relative;
}
.auth-container .middile-container input{
    color: #555;
    border: none;
    width: 100%;
    font-size: 18px;
    padding: 5px 10px;
    background-color: #fff;
}
.auth-container .middile-container input:focus{
    outline: none;
    background-color: #fff;
}
.auth-container .middile-container input::placeholder{
    color: #ccc;
}
.auth-container .middile-container input.password{
    padding-right: 40px;
}
.auth-container .middile-container .form-group .password-field{
    position: relative;
}
.auth-container .middile-container .form-group .visible{
    top: 18px;
    right: 10px;
    color: #8c8c8c;
    font-size: 16px;
    cursor: pointer;
    position: absolute;
}
.auth-container .middile-container .form-group label{
    font-size: 16px;
    font-weight: 600;
}
.auth-container .middile-container button{
    border: none;
    width: 100%;
    color: black;
    padding: 8px;
    font-size: 16px;
    font-weight: 600;
    margin-top: 20px;
    border: 2px solid #FDB813;
    background-color: #FDB813;
    transition: background-color 0.3s ease;
}
.auth-container .middile-container button:hover{
    border: 2px solid #FDB813;
    background-color: #fbcf6a;
}
/* ********* login dark mode ************ */
.admin-container.dark .auth-container{
    background-color: #171717;
}
.admin-container.dark .auth-container .middile-container .title-header{
    color: #fff;
}
.admin-container.dark .auth-container .middile-container .form-group label{
    color: #fff;
}
/* *********** MAIN NAVIGATION SECTION ************* */
.navigation-top-container{
    width: 100%;
    height: 100px;
}
.navigation-container{
    width: 100%;
    background-color: #fff;
    border-bottom: 1px solid #e9e9e9;
}
.navigation-container.stick{
    position: fixed;
}
.top-navigation-inner{
    width: 95%;
    height: 100px;
    display: flex;
    margin: 0 auto;
    align-items: center;
    justify-content: space-between;
}
.navigation-container .navi-middle{
    background-color: #EFEFEF;
} 
.navigation-container .navi-left .title-header h3{
    font-size: 13px;
    color: #7e7e7e;
}
.navigation-container .navi-right ul li .mobile-nav-icon{
    display: none;
    cursor: pointer;
}
.navigation-container .navi-left .title-header img{
    width: 150px;
}
.navigation-container .navi-middle{
    padding: 10px;
    border-radius: 3px;
}
.navigation-container .navi-middle ul.content{
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.navigation-container .navi-middle .mobile-nav-logo{
    display: none;
} 
.navigation-container .navi-middle ul.content li{
    font-size: 16px;
    cursor: pointer;
    font-weight: 400;
    border-radius: 3px;
    transition: all 0.3s ease;
}
.navigation-container .navi-middle ul.content li:hover{
    background-color: black;
}
.navigation-container .navi-middle ul.content li a{
    color: black;
    padding: 5px 20px;
}
.navigation-container .navi-middle ul.content li.icon{
    color: black;
    font-size: 20px;
    padding: 5px 20px;
}
.navigation-container .navi-middle ul.content li:hover a{
    color: #fff;
}
.navigation-container .navi-middle ul.content li.icon:hover{
    color: #fff;
}
.navigation-container .navi-right ul{
    display: flex;
    align-items: center;
    justify-content: right;
} 
.navigation-container .navi-right ul li{
    margin-left: 20px;
}
.navigation-container .navi-right ul li .avatar .avatar-letter{
    width: 40px;
    height: 40px;
    color: #fff;
    font-size: 25px;
    font-weight: 500;
    text-align: center;
    border-radius: 50%;
    background-color: #FDB813;
}
.navigation-container .navi-right ul li .avatar .avatar-letter span{
    top: 3px;
    position: relative;
}
.navigation-container .navi-right ul li .avatar img{
    width: 40px;
    height: 40px;
    color: #fff;
    font-size: 25px;
    font-weight: 500;
    cursor: pointer;
    text-align: center;
    border-radius: 50%;
    border: 1px solid #ccc;
}
.navigation-container .navi-right ul li .notification{
    cursor: pointer;
    position: relative;
}
.navigation-container .navi-right ul li .notification .icon{
    color: #333;
    font-size: 25px;
}
.navigation-container .navi-right ul li .notification .icon.gear{
    animation: spin360 4s linear infinite;
}
@keyframes spin360 {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
}
.navigation-container .navi-right ul li .notification .bell-alert{
    top: 10px;
    right: 0px;
    color: red;
    display: none;
    font-size: 8px;
    position: absolute;
    border-radius: 50%;
    border: 2px solid #fff;
    animation: flash 1s infinite;
}
.navigation-container .navi-right ul li .notification .bell-alert.active{
    display: block;
}
@keyframes flash {
    0% { color: rgb(255, 136, 136); }
    100% { color: rgb(255, 4, 4); }
}
/* ******* navigation dark mode ***** */
.admin-container.dark,
.admin-container.dark .general-table-container,
.admin-container.dark .navigation-container{
    background-color: #171717;
}
.admin-container.dark .navigation-container{
    border-bottom: 1px solid #323232;
}
.admin-container.dark .navigation-container .navi-right ul li .mobile-nav-icon .icon{
    color: #707070;
}
.admin-container.dark .navigation-container .navi-right ul li .icon{
    color: #707070;
}
.admin-container.dark .navigation-container .navi-right ul li .icon.icon-moon{
    color: #FDB813;
}
.admin-container.dark .navigation-container .navi-middle{
    background-color: #1c1c1c;
    border: 1px solid #323232;
} 
.admin-container.dark .navigation-container .navi-middle ul.content li a{
    color: #ccc;
}
.admin-container.dark .navigation-container .navi-right ul li .notification .bell-alert{
    border: 2px solid #1c1c1c;
}
.admin-container.dark .navigation-container .navi-middle ul.content li.icon{
    color: #ccc;
}
@media only screen and (max-width: 1200px){
    .navigation-container .top-navigation-inner{
        width: 100%;
        display: flex;
        margin: 0 auto;
        padding: 10px 20px;
        align-items: center;
        justify-content: space-between;
    }
    .navigation-container .navi-left .title-header h3{
        display: none;
    }
    .navigation-container .navi-middle{
        display: none;
    }
    .navigation-container .navi-middle{
        background-color: transparent;
    } 
    .navigation-container .navi-middle .mobile-nav-logo{
        display: block;
    } 
    .navigation-container .navi-middle .mobile-nav-logo img{
        width: 150px;
    } 
    .navigation-container .navi-middle ul.content{
        display: none;
    }
    .navigation-container .navi-middle ul li a{
        padding: 5px 10px;
    }
    .navigation-container .navi-left .title-header img{
        display: none;
    }
    .navigation-container .navi-right ul li .mobile-nav-icon{
        display: block;
    }
    .navigation-container .navi-right {
        width: 100%;
    }
    .navigation-container .navi-right ul{
        justify-content: space-between;
    }.navigation-container .navi-right ul li{
        margin: 0px;
    }
}
/* ************ SIDE NAVIGATION SECTION ************ */
.side-navigation{
    top: 0px;
    width: 100%;
    height: 0px;
    position: fixed;
}
.side-navigation .side-nav-dark-skin{
    top: 0px;
    z-index: 10;
    width: 100%;
    height: 100vh;
    display: none;
    position: fixed;
    background-color: rgba(21, 21, 21, 0.772);
}
.side-navigation.active .side-nav-dark-skin{
    display: block;
}
.side-navigation .side-nav-body{
    top: 0px;
    z-index: 10;
    width: 400px;
    height: 100vh;
    position: fixed;
    transform: translate(-100vw);
    background-color: #fff;
    transition: transform 0.2s ease;
}
.side-navigation.active .side-nav-body{
    transform: translate(0px);
}
.side-navigation .side-nav-body .title-header{
    text-align: center;
    padding: 20px 0px;
    position: relative;
    border-bottom: 1px solid #dfdfdf;
}
.side-navigation .side-nav-body .title-header .icon{
    top: 10px;
    right: 10px;
    font-size: 20px;
    cursor: pointer;
    position: absolute;
    transition: color 0.3s ease;
}
.side-navigation .side-nav-body .title-header .icon:hover{
    color: #FDB813;
}
.side-navigation .side-nav-body .title-header img{
    width: 150px;
}
.side-navigation .side-nav-body .side-nav-content {
    height: 80vh;
    overflow-Y: auto;
    padding: 30px 0px;
    border-bottom: 1px solid #ccc;
}
.side-navigation .side-nav-body .side-nav-content::-webkit-scrollbar{
    width: 5px;
    background: #ccc;
}
.side-navigation .side-nav-body .side-nav-content::-webkit-scrollbar-thumb {
    background: #b2b2b2;
    border-radius: 10px;
  }
.side-navigation .side-nav-body .side-nav-content ul li{
    font-size: 16px;
    cursor: pointer;
    padding: 0px 20px;
}
.side-navigation .side-nav-body .side-nav-content ul li:hover{
    font-weight: 500;
}
.side-navigation .side-nav-body .side-nav-content ul li .icon{
    margin-right: 10px;
}
.side-navigation .side-nav-body .side-nav-content ul li .icon.settings{
    animation: spin360 4s linear infinite;
}
.side-navigation .side-nav-body .side-nav-content ul li:hover{
    width: 100%;
    background-color: rgba(240, 240, 240, 0.775);
}
/* ******* dark theme side nav *********** */
.admin-container.dark .side-navigation .side-nav-dark-skin{
    background-color: #19191967;
}
.admin-container.dark .side-navigation .side-nav-body{
    background-color: #151515;
    border-right: 1px solid #323232;
}
.admin-container.dark .side-navigation .side-nav-body .title-header{
    border-bottom: 1px solid #323232;
}
.admin-container.dark .side-navigation .side-nav-body .title-header .icon{
    color: #ccc;
}
.admin-container.dark .side-navigation .side-nav-body .title-header .icon:hover{
    color: #FDB813;
}
.admin-container.dark .side-navigation .side-nav-body .side-nav-content {
    border-bottom: 1px solid #323232;
}
.admin-container.dark .side-navigation .side-nav-body .side-nav-content ul li a,
.admin-container.dark .side-navigation .side-nav-body .side-nav-content ul li .icon{
    color: #a3a3a3;
}
.admin-container.dark .side-navigation .side-nav-body .side-nav-content ul li:hover{
    width: 100%;
    background-color: rgba(43, 43, 43, 0.775);
}
.admin-container.dark .side-navigation .side-nav-body .side-nav-content::-webkit-scrollbar{
    background-color: #252525;
}
.admin-container.dark .side-navigation .side-nav-body .side-nav-content::-webkit-scrollbar-thumb {
    background-color: rgba(255, 166, 0, 0.114);
}
@media only screen and (max-width: 1225px){
    .side-navigation .side-nav-body{
        width: 350px;
    }
}
@media only screen and (max-width: 567px){
    .side-navigation .side-nav-body{
        width: 90%;
    }
    .side-navigation.active .side-nav-body{
        transform: translate(0px);
    }
}
/* *************** THEME OPTIONS ************** */
.theme-option-container{
    width: 100%;
    height: 0px;
    z-index: 30;
    position: fixed;
}
.theme-option-container .theme-option-body{
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    display: flex;
    position: fixed;
    transform: scale(0);
    align-items: center;
    visibility: hidden;
    transition: all 0.2s ease;
    justify-content: center;
}
.theme-option-container .dark-theme{
    top: 0px;
    width: 100%;
    height: 100vh;
    display: none;
    position: fixed;
    background-color: rgba(21, 21, 21, 0.772);
}
.theme-option-container .theme-option {
    width: 600px;
    padding: 30px 20px;
    border-radius: 5px;
    border: 1px solid #fff;
    background-color: #fff;
}
.theme-option-container.active .dark-theme{
    display: block;
}
.theme-option-container.active .theme-option-body{
    transform: scale(1);
    visibility: visible;
}
.theme-option-container .theme-option .title-header h3{
    font-size: 22px;
    margin-bottom: 20px;
}
.theme-option-container .theme-option label{
    font-size: 16px;
    color: #333;
}
.theme-option-container .theme-option .form-check{
    margin: 10px 0px;
}
.theme-option-container .theme-option .form-check input{
    cursor: pointer;
    background-color: #FDB813;
}
.theme-option-container .theme-option .form-check input:focus{
    outline: none;
    border: none;
    box-shadow: none;
}
.theme-option-container .theme-option .form-check-button {
    text-align: right;
    margin-top: 40px;
}
.theme-option-container .theme-option .form-check-button button{
    width: 100px;
    color: #FDB813;
    font-size: 18px;
    font-weight: 500;
    padding: 5px 20px;
    border-radius: 50px;
    margin-left: 20px;
    border: 1px solid #FDB813;
    background-color: transparent;
    transition: all 0.3s ease;
}
.theme-option-container .theme-option .form-check-button button.ok{
    width: 80px;
    color: #fff;
    border: 1px solid #FDB813;
    background-color: #FDB813;
}
.theme-option-container .theme-option .form-check-button button:hover{
    background-color: #fdf8ed;
}
.theme-option-container .theme-option .form-check-button button.ok:hover{
    background-color: #e4a001;
}
/* ************* theme option dark ************** */
.admin-container.dark .theme-option-container .theme-option {
    background-color: #252525;
    border: 1px solid #323232;
}
.admin-container.dark .theme-option-container .theme-option .form-check-button button.ok{
    background-color: #FDB813;
}
.admin-container.dark .theme-option-container .theme-option .form-check-button button:hover{
    background-color: #343434;
}
.admin-container.dark .theme-option-container .theme-option .form-check-button button.ok:hover{
    background-color: #fdb71370;
}
.admin-container.dark .theme-option-container .theme-option .title-header h3,
.admin-container.dark .theme-option-container .theme-option label{
    color: #ccc;
}
@media only screen and (max-width: 767px){
    .theme-option-container .theme-option {
        width: 95%;
    }
}
/* *********** TOP BAR SECTION ************* */
.topbar-container{
    background-color: #fff;
}
.topbar-container .top-bar-inner{
    width: 95%;
    margin: 0 auto;
    display: flex;
    padding: 30px 0px;
    background-color: #fff;
    justify-content: space-between;
}
.topbar-container .top-left h3{
   font-size: 25px;
}
.topbar-container .top-right{
    display: flex;
    align-items: center;
}
.topbar-container .top-right .date{
    text-align: center;
    padding: 0px 10px;
    font-size: 16px;
    font-weight: 500;
    border-radius: 3px;
    margin-right: 10px;
    background-color: #EFEFEF;
}
.select-drop-down{
    position: relative;
}
.select-drop-down .drop-down-bar{
    display: flex;
    min-width: 200px;
    max-width: inherit;
    padding: 0px 10px;
    cursor: pointer;
    align-items: center;
    text-align: center;
    border-radius: 3px;
    font-weight: 500;
    justify-content: center;
    background-color: #000000;
    background-image: linear-gradient(#333, #555, black);
}
.select-drop-down .drop-down-bar span{
    color: #fff;
    font-size: 16px;
}
.select-drop-down .drop-down-bar .icon{
    color: #fff;
    font-size: 14px;
    margin-right: 10px;
}
.select-drop-down .drop-down-frame ul{
    top: -100px;
    right: 0px;
    z-index: 20;
    width: 250px;
    opacity: 0;
    visibility: hidden;
    overflow-Y: auto;
    min-height: inherit;
    max-height: 400px;
    border-radius: 3px;
    position: absolute;
    background-color: #fff;
    transition: all 0.2s ease;
    box-shadow: 2px 2px 5px 2px rgba(191, 191, 191, 0.772);
}
.select-drop-down .drop-down-frame ul::-webkit-scrollbar{
    width: 0px;
    background: transparent;
}
.select-drop-down .drop-down-frame ul::-webkit-scrollbar-thumb {
    background: transparent;
}
.select-drop-down .drop-down-frame.active ul{
    top: 0px;
    opacity: 1;
    visibility: visible;
}
.select-drop-down .drop-down-frame ul li{
    cursor: pointer;
    font-size: 15px;
    padding: 0px 10px;
}
.select-drop-down .drop-down-frame ul li:hover{
    background-color: #ebebeb;
}
.select-drop-down.table-drop-down{
    width: 150px;
}
.select-drop-down.table-drop-down .drop-down-bar{
    min-width: 100%;
    display: flex;
    align-items: center;
    background-image: none;
    border: 1px solid #ebebeb;
    background-color: transparent;
    justify-content: space-between;
}
.select-drop-down.table-drop-down span{
    color: black;
    font-size: 12px;
    font-weight: 500;
    margin: 0px 10px;
    text-transform: capitalize;
}
.select-drop-down.table-drop-down .drop-down-frame ul{
    right: 0px;
}
.select-drop-down.table-drop-down .drop-down-frame ul li span{
    text-transform: capitalize;
}
.select-drop-down.table-drop-down .drop-down-bar .icon{
    margin: 0px;
}
.select-drop-down.table-drop-down .drop-down-bar .icon.reward,
.select-drop-down.table-drop-down .drop-down-frame .icon.reward{
    color: #27AE60;
}
.select-drop-down.table-drop-down .drop-down-bar .icon.review,
.select-drop-down.table-drop-down .drop-down-frame .icon.review{
    color: #FDB813;
}
.select-drop-down.table-drop-down .drop-down-bar .icon.invalid,
.select-drop-down.table-drop-down .drop-down-frame .icon.invalid{
    color: #EB5757;
}
.select-drop-down.table-drop-down .drop-down-bar .icon.angle{
    color: #919191;
}
tbody tr:hover .select-drop-down.table-drop-down .drop-down-bar{
    border: 1px solid #ccc;
}
/* **************** dark mode *************** */
.admin-container.dark .topbar-container,
.admin-container.dark .topbar-container .top-bar-inner{
    background-color: #171717;
}
.admin-container.dark .select-drop-down .drop-down-bar{
    border: 1px solid #323232;
    background-color: transparent;
}
.admin-container.dark .select-drop-down .drop-down-frame ul{
    background-color: #252525;
    border: 1px solid #323232;
    box-shadow: 2px 2px 5px 2px rgba(17, 17, 17, 0.772);
}
.admin-container.dark .select-drop-down .drop-down-frame ul li{
    color: #ccc;
}
.admin-container.dark .select-drop-down .drop-down-frame ul li:hover{
    background-color: #1e1e1e;
}
.admin-container.dark .topbar-container .top-right .date{
    color: #FDB813;
    background-color: #2c2c2c;
}
.admin-container.dark .select-drop-down.table-drop-down .drop-down-frame ul li span,
.admin-container.dark .select-drop-down.table-drop-down .drop-down-bar span{
    color: #ccc;
}
.admin-container.dark tbody tr:hover .select-drop-down.table-drop-down .drop-down-bar{
    border: 1px solid #4f4f4f;
}
@media only screen and (max-width: 767px){
    .topbar-container .top-bar-inner{
        display: block;
    }
    .topbar-container .top-left,
    .topbar-container .top-right{
        width: 100%;
        margin-bottom: 30px;
    }
    .select-drop-down,
    .topbar-container .top-right .date{
        width: 50%;
    }
    .select-drop-down .drop-down-bar{
        width: 100%;
    }
}
@media only screen and (max-width: 567px){
    .topbar-container .top-right{
        display: block;
    }
    .select-drop-down,
    .topbar-container .top-right .date{
        width: 100%;
        margin-bottom: 20px;
    }
}
/* ************** TRANSAQCTION SECTION ********* */
.general-table-container{
    background-color: #EFEFEF;
}
.general-table-container .general-table-body{
    width: 100%;
    min-height: 100vh;
    padding: 20px 0px;
    background-color: #EFEFEF;
}
.general-table-container .general-table-body .inner-body{
    width: 95%;
    margin: 0 auto;
}
.general-table-container .general-table-body .general-table{
    padding: 0px 20px;
    display: block;
    border-radius: 5px;
    background-color: #fff;
}
.general-table-container .general-table-body .general-table .table-image{
    width: 50px;
    height: 50px;
    display: flex;
    text-align: center;
    border-radius: 5px;
    align-items: center;
    justify-content: center;
    background-color: #ebebeb;
    border: 1px solid #ccc;
}
.general-table-container .general-table-body .general-table .table-image .icon{
    color: #b2b2b2;
    font-size: 20px;
    cursor: pointer;
}
.general-table-container .general-table-body .general-table .table-image img{
    width: 100%;
    height: 100%;
    border-radius: 5px;
    cursor: pointer;
}
.general-total .total-card{
    width: 100%;
    display: flex;
    margin: 10px 0px;
    padding: 30px 0px;
    border-radius: 5px;
    align-items: center;
    background-color: #fff;
    justify-content: center;
}
.general-total .total-card .total-right{
    top: -10px;
    position: relative;
}
.general-total .total-card .total-right span{
    font-size: 16px;
    color: #969696;
}
.general-total .total-card .total-right h3{
    margin: 0px;
    font-size: 20px;
    font-weight: bold;
}
.general-total .total-card .total-icon .icon{
    color: #fff;
    width:  40px;
    height: 40px;
    padding: 5px;
    display: block;
    font-size: 20px;
    text-align: center;
    margin-right: 20px;
    border-radius: 50%;
}
.general-total .total-card .total-icon .icon.red{
    color: red;
}
.general-total .total-card .total-icon .icon.green{
    color: green;
}
.general-total .total-card .total-icon .icon.orange{
    color: #FDB813;
}
.general-total .total-card .total-icon .icon.blue{
    color: #00A9DC;
}
.general-total .total-card .total-icon .icon.purple{
    color: #7B61FF;
}
.general-table-container .general-table-body .search-bar{
    display: flex;
    margin: 20px 0px;
    align-items: center;
    justify-content: space-between;
}
.general-table-container .general-table-body .search-bar .bar-left .title-header{
    font-size: 22px;
}
.general-table-container .general-table-body .search-bar .bar-right{
    width: 50%;
}
.general-table-container .general-table-body .search-bar .bar-right input{
    width: 80%;
    border: none;
    font-size: 18px;
    padding: 5px 10px;
    border-radius: 5px;
    color: #656565;
    background-color: #fff;
    border: 1px solid transparent;
}
.general-table-container .general-table-body .search-bar .bar-right .form{
    display: flex;
    justify-content: space-between;
}
.general-table-container .general-table-body .search-bar .bar-right input:focus{
    outline: none;
    color: #656565;
    border: 1px solid #FDB813;
}
.general-table-container .general-table-body .search-bar .bar-right button,
.general-table-container .general-table-body .search-bar .bar-right .add-member-btn{
    width: 150px;
    border: none;
    font-size: 15px;
    font-weight: 500;
    padding: 5px 10px;
    border-radius: 5px;
    margin-left: 20px;
    background-color: #fff;
    transition: all 0.3s ease;
}
.general-table-container .general-table-body .search-bar .bar-right button:hover,
.general-table-container .general-table-body .search-bar .bar-right .add-member-btn:hover{
    background-color: #FDB813;
}
.general-table-container .general-table-body .search-bar .bar-right .add-member-btn{
    display: none;
}
.general-table-container .general-table-body .search-bar .bar-right .add-member-btn .icon{
    font-weight: bold;
}
.general-table-container .general-table-body .general-table .main-title-header h3{
    font-size: 18px;
    font-weight: bold;
    margin-bottom: 20px;
}
.general-table-container .general-table-body .general-table thead th{
    font-size: 16px;
}
.general-table-container .general-table-body .general-table tbody td.entrances span{
    font-size: 12px;
    color: #b2b2b2;
    margin-left: 5px;
}
.general-table-container .general-table-body .general-table thead th span{
    font-size: 12px;
    color: #b2b2b2;
}
.general-table-container .general-table-body .general-table tbody{
    font-size: 12px;
    color: #b2b2b2;
}
.general-table-container .general-table-body .general-table .table{
    margin-bottom: 0px;
}
.general-table-container .general-table-body .general-table .table td{
    text-transform: capitalize;
}
.general-table-container .general-table-body .general-table .device-type .icon{
    font-size: 22px;
    color: #969696;
}
.general-table-container .general-table-body .general-table .status .icon.success{
    color: rgb(2, 216, 2);
}
.general-table-container .general-table-body .general-table .status .icon.unverified{
    color: orange;
}
.general-table-container .general-table-body .general-table .status .icon.failed{
    color: red;
}
.general-table-container .general-table-body .general-table .user-detail.desktop,
.general-table-container .general-table-body .general-table .user-detail{
    display: flex;
    align-items: center;
}
.general-table-container .general-table-body .general-table .user-detail .left .icon.country-flag{
    font-size: 22px;
    border-radius: 5px;
}
.general-table-container .general-table-body .general-table .user-detail.mobile{
    display: none;
}
.general-table-container .general-table-body .general-table .user-detail .left .no-img{
    width: 40px;
    height: 40px;
    text-align: center;
    border-radius: 50%;
    background-color: rgb(2, 216, 2);
}
.general-table-container .general-table-body .general-table .user-detail .left .no-img span{
    color: #fff;
    font-size: 16px;
    font-weight: bold;
}
.general-table-container .general-table-body .general-table .user-detail .left .img{
    width: 40px;
    height: 40px;
    position: relative;
    text-align: center;
    border-radius: 50%;
    overflow: hidden;
    cursor: pointer;
    border: 1px solid #ccc;
    background-color: #fff;
}
.general-table-container .general-table-body .general-table .user-detail .left .img img{
    top: 0px;
    left: 0px;
    width: 100%;
    cursor: pointer;
    position: absolute;
    border-radius: 50%;
    background-color: #fff;
}
.general-table-container .general-table-body .general-table .user-detail .right{
    margin-left: 10px;
}
.general-table-container .general-table-body .general-table .user-detail .right h3{
    margin: 0px;
    cursor: pointer;
    font-size: 16px;
    line-height: 20px;
}
.general-table-container .general-table-body .general-table .user-detail .right p{
    margin: 0px;
    cursor: pointer;
    font-size: 11px;
    color: #a3a3a3;
    line-height: 20px;
}
.general-table-container .general-table-body .general-table .icon.toggle{
    font-size: 22px;
    cursor: pointer;
    color: #969696;
}
.general-table-container .general-table-body .general-table .icon.toggle.active{
    color: rgb(2, 216, 2);
}
@media only screen and (max-width: 992px){
    .general-table-container .general-table-body .general-table{
        display: none;
    }
    .general-table-container .general-table-body .general-table .user-detail.desktop{
        display: none;
    }
    .general-table-container .general-table-body .general-table .user-detail.mobile{
        display:  flex;
    }
}
/* *********** USER METRICS SECTION *************** */
.general-table-container .metrics-container{
    padding: 20px 0px;
    background-color: #EFEFEF;
}
.general-table-container .metrics-container .metrics-inner{
    width: 95%;
    margin: 0 auto;
}
.general-table-container .metrics-container .metrics-inner .metrics-left-body{
    padding: 0px;
    border-radius: 5px;
    background-color: #fff;
}
.general-table-container .metrics-container .metrics-inner .metrics-left-body .title-header h3{
    padding: 30px 20px;
    font-weight: bold;
    font-size: 18px;
}
.general-table-container .metrics-container .metrics-inner .metrics-left-body .content-left-item{
    padding: 10px;
    text-align: center;
    border: 1px solid #e8e8e8;
}
.general-table-container .metrics-container .metrics-inner .metrics-left-body .content-left-item.orange{
    background-color: #fde7b5;
}
.general-table-container .metrics-container .metrics-inner .metrics-left-body .content-left-item .total h3{
    margin: 0px;
    font-size: 25px;
    font-weight: bold;
}
.general-table-container .metrics-container .metrics-inner .metrics-left-body .content-left-item .title-header h3{
    margin: 0px;
    padding: 0px;
    color: #959595;
    font-size: 16px;
    font-weight: 400;
}
.general-table-container .metrics-container .metrics-inner .metrics-left-body .content-left-item .title-header span .icon{
    color: green;
    font-size: 14px;
    margin: 0px 5px;
}
.general-table-container .metrics-container .metrics-inner .metrics-left-body .chart-frame{
    padding: 20rgb(11, 218, 11)
}
.general-table-container .metrics-container .metrics-inner .metrics-right-body .metrics-right-body-top .item{
    padding: 20px 10px;
    position: relative;
    border-radius: 5px;
    background-color: #fff;
}
.general-table-container .metrics-container .metrics-inner .metrics-left-body .slider-frame button{
    z-index: 10;
    display: relative;
}
.general-table-container .metrics-container .metrics-inner .metrics-right-body .metrics-right-body-top .item h3.title-header{
    margin: 5px 0px;
    font-size: 14px;
    font-weight: 400;
    color: #a2a2a2;
}
.general-table-container .metrics-container .metrics-inner .metrics-right-body .metrics-right-body-top .item .total{
    margin: 0px;
    font-size: 20px;
    font-weight: bold;
}
.general-table-container .metrics-container .metrics-inner .metrics-right-body .metrics-right-body-top .item .icon-right{
    top: 20px;
    right: 10px;
    position: absolute;
}
.general-table-container .metrics-container .metrics-inner .metrics-right-body .metrics-right-body-top .item .icon-right .icon{
    color: #fff;
    padding: 5px 6px;
    font-size: 14px;
    cursor: pointer;
    border-radius: 50%;
    transition: all 0.3s ease;
    background-color: rgb(38, 192, 38);
}
.general-table-container .metrics-container .metrics-inner .metrics-right-body .metrics-right-body-top .item .icon-right .icon:hover{
    background-color: rgb(12, 163, 12);
}
.general-table-container .metrics-container .metrics-inner .metrics-right-body .metrics-right-body-m{
    margin-top: 20px;
    padding: 20px 10px;
    border-radius: 5px;
    background-color: #fff;
}
.general-table-container .metrics-container .metrics-inner .metrics-right-body .metrics-right-body-m .show-grid{
    display: flex;
    align-items: center;
}
.general-table-container .metrics-container .metrics-inner .metrics-right-body .metrics-right-body-m .right{
    left: 20px;
    margin: 30px 0px;
    position: relative;
}
.general-table-container .metrics-container .metrics-inner .metrics-right-body .metrics-right-body-m .title-header{
    margin-bottom: 30px;
}
.general-table-container .metrics-container .metrics-inner .metrics-right-body .metrics-right-body-m .title-header h3{
    font-size: 20px;
    font-weight: bold;
}
.general-table-container .metrics-container .metrics-inner .metrics-right-body .metrics-right-body-m .right h3.title-header{
    margin: 5px 0px;
    font-size: 14px;
    font-weight: 400;
    color: #a2a2a2;
}
.general-table-container .metrics-container .metrics-inner .metrics-right-body .metrics-right-body-m .right h3.total{
    margin: 0px;
    font-size: 18px;
    font-weight: bold;
}
.general-table-container .metrics-container .metrics-inner .metrics-right-body .metrics-right-body-m .right .circle-item{
    top: -25px;
    left: -25px;
    position: absolute;
}
.general-table-container .metrics-container .metrics-inner .metrics-right-body .metrics-right-body-m .right .circle-item .icon{
    color: green;
    font-size: 12px;
    margin: 0px 5px;
}
.general-table-container .metrics-container .metrics-inner .metrics-right-body .metrics-right-body-m .left .doughnut-chart{
    position: relative;
}
.general-table-container .metrics-container .metrics-inner .metrics-right-body .metrics-right-body-m .left .doughnut-chart canvas{
    z-index: 10;
    position: relative;
}
.general-table-container .metrics-container .metrics-inner .metrics-right-body .metrics-right-body-m .left .doughnut-chart .title-header{
    z-index: 5;
    width: 100%;
    height: 100%;
    display: flex;
    position: absolute;
    align-items: center;
    justify-content: center;
}
.general-table-container .metrics-container .metrics-inner .metrics-right-body .metrics-right-body-m .left .doughnut-chart .title-header .inner{
    top: 10px;
    position: relative;
}
.general-table-container .metrics-container .metrics-inner .metrics-right-body .metrics-right-body-m .left .doughnut-chart .title-header h3.total{
    top: -10px;
    margin: 0px;
    font-size: 30px;
    font-weight: bold;
    text-align: center;
    color: #ababab;
    position: relative;
    transform: scale(1.80);
    transition: all 0.3s ease;
}
.general-table-container .metrics-container .metrics-inner .metrics-right-body .metrics-right-body-m .left .doughnut-chart .title-header h3.total.normal{
    top: 0px;
    color: #333;
    transform: scale(1);
}
.general-table-container .metrics-container .metrics-inner .metrics-right-body .metrics-right-body-m .left .doughnut-chart .title-header h3.title{
    margin: 0px;
    font-size: 14px;
    font-weight: 400;
    text-align: center;
}
/* ********* transaction dark ********** */
.admin-container.dark .general-table-container .general-table-body{
    background-color: #171717;
    /* border-top: 1px solid #323232;
    border-bottom: 1px solid #323232; */
}
.admin-container.dark .topbar-container .top-left h3{
    color: #ccc;
 }
.admin-container.dark .general-total .total-card{
    background-color: #242424;
    border: 1px solid #323232;
}
.admin-container.dark .general-total .total-card .total-right h3{
    color: #ccc;
}
.admin-container.dark .general-table-container .general-table-body .search-bar .bar-right input{
    color: #8d8d8d;
    background-color: #191919;
    border: 1px solid #323232;
}
.admin-container.dark .general-table-container .general-table-body .search-bar .bar-right input:focus{
    border: 1px solid #805900;
}
.admin-container.dark .general-table-container .general-table-body .search-bar .bar-right button,
.admin-container.dark .general-table-container .general-table-body .search-bar .bar-right .add-member-btn{
    background-color: #FDB813;
}
.admin-container.dark .general-table-container .general-table-body .search-bar .bar-right button:hover,
.admin-container.dark .general-table-container .general-table-body .search-bar .bar-right .add-member-btn:hover{
    background-color: #bb8403;
}
.admin-container.dark .general-table-container .general-table-body .search-bar .bar-left .title-header{
    color: #ccc;
}
.admin-container.dark .general-table-container .general-table-body .general-table{
    background-color: #242424;
    border: 1px solid #323232;
}
.admin-container.dark .general-table-container .general-table-body .general-table .table thead tr th{
    color: #ccc;
    background-color: #242424;
}
.admin-container.dark .general-table-container .general-table-body .general-table .table thead{
    border-bottom: 1px solid #323232;
}
.admin-container.dark .general-table-container .general-table-body .general-table .table tbody tr td{
    color: #ccc;
    background-color: #242424;
}
.admin-container.dark .general-table-container .general-table-body .general-table .table tbody{
    border-bottom: 1px solid #323232;
}
.admin-container.dark .general-table-container .general-table-body .general-table .device-type .icon{
    color: #555;
}
.admin-container.dark .general-table-container .general-table-body .general-table thead th span{
    font-size: 12px;
    color: #696969;
}
.admin-container.dark .general-table-container .general-table-body .general-table .user-detail .right h3{
    color: #ccc;
}
.admin-container.dark .general-table-container .general-table-body .general-table .user-detail .right p{
    color: #717171;
}
.admin-container.dark .general-table-container .general-table-body .general-table .user-detail .left .img,
.admin-container.dark .general-table-container .general-table-body .general-table .user-detail .left .img img{
    background-color: #ccc;
}
.admin-container.dark .general-table-container .metrics-container{
    background-color: #171717;
    border-top: 1px solid #323232;
}
.admin-container.dark .general-table-container .metrics-container .metrics-inner .metrics-left-body{
    background-color: #242424;
    border: 1px solid #323232;
}
.admin-container.dark .general-table-container .metrics-container .metrics-inner .metrics-left-body .title-header h3{
    color: #ccc;
}
.admin-container.dark .general-table-container .metrics-container .metrics-inner .metrics-left-body .chart-frame{
    color: #ccc;
}
.admin-container.dark .general-table-container .metrics-container .metrics-inner .metrics-left-body .content-left-item{
    border: 1px solid #323232;
}
.admin-container.dark .general-table-container .metrics-container .metrics-inner .metrics-left-body .content-left-item .total h3{
    color: #ccc;
}
.admin-container.dark .general-table-container .metrics-container .metrics-inner .metrics-left-body .content-left-item .title-header h3{
    color: #7a7a7a;
}
.admin-container.dark .general-table-container .metrics-container .metrics-inner .metrics-left-body .content-left-item.orange{
    background-color: #2c2c2c;
}
.admin-container.dark .general-table-container .metrics-container .metrics-inner .metrics-right-body .metrics-right-body-m{
    background-color: #242424;
    border: 1px solid #323232;
}
.admin-container.dark .general-table-container .metrics-container .metrics-inner .metrics-right-body .metrics-right-body-m .title-header h3{
    color: #ccc;
}
.admin-container.dark .general-table-container .metrics-container .metrics-inner .metrics-right-body .metrics-right-body-top .item h3.title-header{
    color: #7a7a7a;
}
.admin-container.dark .general-table-container .metrics-container .metrics-inner .metrics-right-body .metrics-right-body-m .left{
    color: #ccc;
}
.admin-container.dark .general-table-container .metrics-container .metrics-inner .metrics-right-body .metrics-right-body-m .right h3.total{
    color: #ccc;
}
.admin-container.dark .general-table-container .metrics-container .metrics-inner .metrics-right-body .metrics-right-body-m .right h3.title-header{
    color: #7a7a7a;
}
.admin-container.dark .general-table-container .metrics-container .metrics-inner .metrics-right-body .metrics-right-body-top .item{
    background-color: #242424;
    border: 1px solid #323232;
}
.admin-container.dark .general-table-container .metrics-container .metrics-inner .metrics-right-body .metrics-right-body-top .item .total{
    color: #ccc;
}
.admin-container.dark .general-table-container .metrics-container .metrics-inner .metrics-right-body .metrics-right-body-m .left .doughnut-chart .title-header h3.title{
    color: #7a7a7a;
}
.admin-container.dark .general-table-container .metrics-container .metrics-inner .metrics-right-body .metrics-right-body-m .left .doughnut-chart .title-header h3.total{
    color: #535353;
}
.admin-container.dark .general-table-container .metrics-container .metrics-inner .metrics-right-body .metrics-right-body-m .left .doughnut-chart .title-header h3.total.normal{
    top: 0px;
    color: #ccc;
    transform: scale(1);
}
@media only screen and (max-width: 1200px){
    .general-table-container .metrics-container .metrics-inner .metrics-left-body{
        margin: 50px 0px;
    }
   
}
@media only screen and (max-width: 992px){
    .general-table-container .general-table-body .search-bar{
        display: block;
        margin-top: 50px;
    }
    .general-table-container .general-table-body .search-bar .bar-right{
        width: 100%;
        margin: 20px 0px;
    }
}
@media only screen and (max-width: 767px){
    .general-table-container .general-table-body .search-bar .bar-right .add-member-btn{
        display: inline-block;
    }
    .general-table-container .general-table-body .search-bar .bar-right button.admin-admin-btn{
        display: none;
    }
    .general-table-container .metrics-container .metrics-inner .metrics-right-body .metrics-right-body-top .item{
        padding: 40px 10px;
        margin: 10px 0px;
    }
    .general-table-container .metrics-container .metrics-inner .metrics-right-body .metrics-right-body-top .item .icon-right .icon{
        font-size: 18px;
        border-radius: 5px;
    }
}
@media only screen and (max-width: 567px){
    .general-table-container .general-table-body .search-bar .bar-right button{
        width: 110px;
        margin-left: 5px;
    }
    .general-table-container .general-table-body .search-bar .bar-right .add-member-btn{
        width: inherit;
    }
    .general-table-container .general-table-body .search-bar .bar-right input{
        width: 100%;
    }
    .general-table-container .general-table-body .search-bar .bar-right .add-member-btn span{
        display: none;
    }
}
/* ************ SEARCH RANGE SECTION *************** */
.general-table-container .search-range{
    background-color: #EFEFEF; 
}
.general-table-container .inner-search-range{
    width: 95%;
    margin: 0 auto;
    padding: 30px 0px;
}
.general-table-container .inner-search-range .select-container{
    width: 100%;
    border: none;
    cursor: pointer;
    font-size: 16px;
    border-radius: 5px;
    background-color: #fff;
}
.general-table-container .select-container{
    position: relative;
}
.general-table-container .search-range .title{
    font-size: 16px;
    font-weight: bold;
}
.general-table-container .select-container .form-top{
    width: 100%;
    display: flex;
    padding: 10px;
    align-items: center;
    justify-content: space-between;
}
.general-table-container .select-container .form-top .icon{
    color: #b7b7b7;
}
.general-table-container .select-container .form-top .icon:hover{
    color: #FDB813;
}
.general-table-container .select-container .select-item-form{
    top: 300px;
    left: 0px;
    width: 100%;
    z-index: 30;
    opacity: 0;
    transform: scale(0);
    visibility: hidden;
    position: absolute;
    transition: all 0.4s ease;
}
.general-table-container .select-container .select-item-form ul{
    width: 100%;
    height: 300px;
    overflow-Y: auto;
    border-radius: 5px;
    background-color: #fff;
    box-shadow: 2px 2px 5px 2px rgba(191, 191, 191, 0.772);
}
.general-table-container .select-container .select-item-form ul::-webkit-scrollbar{
    width: 0px;
    background: transparent;
}
.general-table-container .select-container .select-item-form ul::-webkit-scrollbar-thumb {
    background: transparent;
}
.general-table-container .select-container .select-item-form.active{
    top: 50px;
    opacity: 1;
    transform: scale(1);
    visibility: visible;
}
.general-table-container .select-container .select-item-form ul li{
    padding: 0px;
    margin: 0px;
    padding: 2px 10px;
    text-transform: capitalize;
}
.general-table-container .select-container .select-item-form ul li:hover{
    background-color: #ebebeb;
}
.general-table-container .table-show-more{
    text-align: right;
}
.general-table-container .table-show-more a{
    font-size: 14px;
    font-weight: 500;
    color: #FDB813;
    transition: color 0.3s ease;
}
.general-table-container .table-show-more a:hover{
    color: #c18700;
}
/* ************** dark theme ************************* */
.admin-container.dark .general-table-container .search-range{
    background-color: #1e1e1e;
}
.admin-container.dark .general-table-container .inner-search-range .select-container{
    background-color: #1e1e1e;
    border: 1px solid #323232;
}
.admin-container.dark .general-table-container .search-range .title{
    color: #ccc;
}
.admin-container.dark .general-table-container .select-container .form-top span,
.admin-container.dark .general-table-container .select-container .form-top .icon{
    color: #b7b7b7;
}
.admin-container.dark .general-table-container .select-container .form-top .icon:hover{
    color: #FDB813;
}
.admin-container.dark .general-table-container .select-container .select-item-form ul{
    background-color: #252525;
    border: 1px solid #323232;
    box-shadow: 2px 2px 5px 2px rgba(17, 17, 17, 0.772);
}
.admin-container.dark .general-table-container .select-container .select-item-form ul li{
    color: #ccc;
}
.admin-container.dark .general-table-container .select-container .select-item-form ul li:hover{
    background-color: #1e1e1e;
}
@media only screen and (max-width: 767px){
    .general-table-container .search-range{
        margin-bottom: 25px;
    }
    .general-table-container .select-container .select-item-form ul{
        padding: 20px 0px;
    }
    .general-table-container .select-container .select-item-form ul li{
        padding: 2px 20px;
    } 
}
/* ************ MOBILE GENERAL TABLE **************** */
.general-table-container .mobile-general-table{
    width: 95%;
    margin: 0 auto;
    display: none;
    border-radius: 5px;
    background-color: #fff;
}
.general-table-container .mobile-general-table .title-header{
    padding: 20px;
    border-bottom: 1px solid #e2e2e2;
}
.general-table-container .mobile-general-table .title-header h3{
    font-size: 16px;
    font-weight: bold;
}
.general-table-container .mobile-general-table .title-header h3 span{
    font-size: 12px;
    color: #a6a6a6;
}
.general-table-container .mobile-general-table .general-table-mobile-body .table-content{
    padding: 20px;
    border-bottom: 1px solid #e2e2e2;
}
.general-table-container .mobile-general-table .general-table-mobile-body .table-content-left .left{
    display: flex;
}
.general-table-container .mobile-general-table .general-table-mobile-body .table-content-left .left.small-screen{
    display: none;
}
.general-table-container .mobile-general-table .general-table-mobile-body .table-content-left .left .img{
    width: 50px;
    height: 50px;
    overflow: hidden;
    border-radius: 40%;
    margin-right: 20px;
    border: 1px solid #ccc;
    background-color: #fff;
}
.general-table-container .mobile-general-table .general-table-mobile-body .table-content-left .left .img img{
    width: 100%;
    height: 100%;
    cursor: pointer;
    background-color: #fff;
}
.general-table-container .mobile-general-table .general-table-mobile-body .table-content-left .left-detail ul li.name h3{
    margin: 0px;
    cursor: pointer;
    font-size: 16px;
    line-height: 20px;
    font-weight: bold;
    color: #494949;
}
.general-table-container .mobile-general-table .general-table-mobile-body .table-content-right ul li,
.general-table-container .mobile-general-table .general-table-mobile-body .table-content-left .left-detail ul li{
    margin-bottom: 5px;
}
.general-table-container .mobile-general-table .general-table-mobile-body .table-content-left .left-detail ul li.name p{
    font-size: 12px;
    line-height: 20px;
    color: #878787;
}
.general-table-container .mobile-general-table .general-table-mobile-body .table-content-right ul li.list,
.general-table-container .mobile-general-table .general-table-mobile-body .table-content-left .left-detail ul li.list{
    color: #5d5d5d;
    font-size: 14px;
    line-height: 20px;
}
.general-table-container .mobile-general-table .general-table-mobile-body .table-content-right ul li.list span,
.general-table-container .mobile-general-table .general-table-mobile-body .table-content-left .left-detail ul li.list span{
    color: #2a2a2a;
    font-size: 14px;
    line-height: 20px;
    font-weight: 500;
}
.general-table-container .mobile-general-table .general-table-mobile-body .toggle-item .icon.success,
.general-table-container .mobile-general-table .general-table-mobile-body .table-content-right ul li.list span.success,
.general-table-container .mobile-general-table .general-table-mobile-body .table-content-right ul li.list .icon.success{
    color: rgb(2, 216, 2);
}
.general-table-container .mobile-general-table .general-table-mobile-body .toggle-item .icon.invalid,
.general-table-container .mobile-general-table .general-table-mobile-body .table-content-right ul li.list span.invalid,
.general-table-container .mobile-general-table .general-table-mobile-body .table-content-right ul li.list .icon.invalid{
    color: #EB5757;
}
.general-table-container .mobile-general-table .general-table-mobile-body .toggle-item .icon.review,
.general-table-container .mobile-general-table .general-table-mobile-body .table-content-right ul li.list span.review,
.general-table-container .mobile-general-table .general-table-mobile-body .table-content-right ul li.list .icon.review{
    color: #FDB813;
}
.general-table-container .mobile-general-table .general-table-mobile-body .toggle-item{
    display: none;
}
.general-table-container .mobile-general-table .general-table-mobile-body .table-content-left ul li.list .icon,
.general-table-container .mobile-general-table .general-table-mobile-body .table-content-right ul li.list .icon{
    margin: 0px 5px;
}
/* ************** dark mode **************** */
.admin-container.dark .general-table-container .mobile-general-table{
    background-color: #242424;
    border: 1px solid #323232;
}
.admin-container.dark .general-table-container .mobile-general-table .title-header h3{
    color: #ccc;
}
.admin-container.dark .general-table-container .mobile-general-table .title-header h3 span{
    color: #737373;
}
.admin-container.dark .general-table-container .mobile-general-table .title-header{
    border-bottom: 1px solid #323232;
}
.admin-container.dark .general-table-container .mobile-general-table .general-table-mobile-body .table-content{
    border-bottom: 1px solid #323232;
}
.admin-container.dark .general-table-container .mobile-general-table .general-table-mobile-body .table-content-left .left .img img,
.admin-container.dark .general-table-container .mobile-general-table .general-table-mobile-body .table-content-left .left .img{
    background-color: #adadad;
}
.admin-container.dark .general-table-container .mobile-general-table .general-table-mobile-body .table-content-left .left-detail ul li.name h3{
    color: #ccc;
}
.admin-container.dark .general-table-container .mobile-general-table .general-table-mobile-body .table-content-right ul li.list span,
.admin-container.dark .general-table-container .mobile-general-table .general-table-mobile-body .table-content-left .left-detail ul li.list span{
    color: #989898;
}
.admin-container.dark .general-table-container .mobile-general-table .general-table-mobile-body .table-content-right ul li.list,
.admin-container.dark .general-table-container .mobile-general-table .general-table-mobile-body .table-content-left .left-detail ul li.list{
    color: #6b6b6b;
}
.admin-container.dark .general-table-container .mobile-general-table .general-table-mobile-body .table-content-right ul li.list span.success,
.admin-container.dark .general-table-container .mobile-general-table .general-table-mobile-body .table-content-left .left-detail ul li.list span.success,
.admin-container.dark .general-table-container .mobile-general-table .general-table-mobile-body .table-content-right ul li.list span.success{
    color: rgb(2, 216, 2);
}
.admin-container.dark .general-table-container .mobile-general-table .general-table-mobile-body .table-content-right ul li.list span.invalid,
.admin-container.dark .general-table-container .mobile-general-table .general-table-mobile-body .table-content-left .left-detail ul li.list span.invalid,
.admin-container.dark .general-table-container .mobile-general-table .general-table-mobile-body .table-content-right ul li.list span.invalid{
    color: #EB5757;
}
.admin-container.dark .general-table-container .mobile-general-table .general-table-mobile-body .table-content-right ul li.list span.review,
.admin-container.dark .general-table-container .mobile-general-table .general-table-mobile-body .table-content-left .left-detail ul li.list span.review,
.admin-container.dark .general-table-container .mobile-general-table .general-table-mobile-body .table-content-right ul li.list span.review{
    color: #FDB813;
}
@media only screen and (max-width: 992px){
    .general-table-container .mobile-general-table{
        display: block;
    }
}
@media only screen and (max-width: 567px){
    .general-table-container .mobile-general-table .general-table-mobile-body .table-content-right{
        display: none;
    }
    .general-table-container .mobile-general-table .general-table-mobile-body .toggle-item{
        display: block;
    }
}

/* ************ ADD ADMIN USER SECTION ************** */
.add-admin-container{
    top: 0px;
    z-index: 20;
    width: 100%;
    height: 100vh;
    display: flex;
    position: fixed;
    align-items: center;
    background-color: rgba(21, 21, 21, 0.772);
}
.add-admin-container.mobile-view{
    position: static;
    background-color: #f8f8f8;
}
.add-admin-container .form .title-header h3{
    text-align: center;
    font-size: 20px;
}
.add-admin-container .form{
    width: 50%;
    margin: 0 auto;
    padding: 20px;
    border-radius: 3px;
    background-color: #f0f0f0;
}
.add-admin-container.large .form{
    width: 80%;
}
.add-admin-container .form .title-header .form-cancel{
    top: -45px;
    height: 0px;
    text-align: right;
    position: relative;
}
.add-admin-container .form .title-header .form-cancel .icon{
    color: rgb(176, 1, 1);
    font-size: 18px;
    cursor: pointer;
    border-radius: 50%;
}
.add-admin-container .form .form-body label{
    color: #333;
    font-size: 16px;
    font-weight: 500;
}
.add-admin-container .form .form-body input{
    width: 100%;
    font-size: 16px;
    padding: 5px 10px;
    border-radius: 2px;
    border: 1px solid #ccc;
}
.add-admin-container .form .form-group select:focus,
.add-admin-container .form .form-body input:focus{
    outline: none;
    border: 1px solid #FDB813;
}
.add-admin-container .form .form-body .form-input-password{
    display: flex;
    position: relative;
    align-items: center;
}
.add-admin-container .form .form-body .form-input-password input{
    padding-right: 35px;
}
.add-admin-container .form .form-body .form-input-password .icon{
    top: 15px;
    right: 5px;
    cursor: pointer;
    font-size: 18px;
    color: #999999;
    position: absolute;
}
.add-admin-container .form .form-body .form-button{
    margin-top: 20px;
}
.add-admin-container .form .form-body button{
    width: 100%;
    border: none;
    font-size: 18px;
    padding: 5px 5px;
    font-weight: bold;
    border-radius: 2px;
    background-color: #FDB813;
    transition: background-color 0.3s ease;
}
.add-admin-container .form .form-body button:hover{
    background-color: #c88c01;
}
.add-admin-container .form .form-body .form-check .form-check-input{
    width: 20px;
    height: 20px;
}
.add-admin-container .form .form-body .form-check{
    display: flex;
    align-items: center;
}
.add-admin-container .form .form-body .form-check .form-check-label{
    font-size: 12px;
    margin-left: 10px;
}
.add-admin-container .form .form-group select{
    width: 100%;
    font-size: 16px;
    padding: 15px 10px;
    border-radius: 2px;
    border: 1px solid #ccc;
}
/* ************ dark theme*********** */

.admin-container.dark .add-admin-container .form{
    background-color: #1e1e1e;
    border: 1px solid #323232;
}
.admin-container.dark .add-admin-container.mobile-view{
    margin: 0px;
    background-color: #1e1e1e;
}
.admin-container.dark .add-admin-container .form .form-body label{
    color: #ccc;
}
.admin-container.dark .add-admin-container .form .form-body input,
.admin-container.dark .add-admin-container .form .form-body input:focus{
    color: #8f8f8f;
    background-color: #1b1b1b;
    border: 1px solid #323232;
}
.admin-container.dark .add-admin-container .form .title-header h3{
    color: #ccc;
}
.admin-container.dark .add-admin-container .form .title-header .form-cancel .icon{
    color: #FDB813;
}
@media only screen and (max-width: 1250px){
    .add-admin-container .form{
        width: 70%;
    }
}
@media only screen and (max-width: 992px){
    .add-admin-container .form{
        width: 80%;
    }
}
@media only screen and (max-width: 767px){
    .add-admin-container .form{
        width: 98%;
    }
    .add-admin-container{
        display: none;
    }
    .add-admin-container.mobile-view{
        display: flex;
    }
}
/* ************* PROFILE SECTION *********** */
.profile-top{
   /* padding-left: 50px; */
   margin-top: 70px;
   background-color: #fff;
}
.profile-top .show-grid{
    margin: 0px;
}
.profile-top .show-grid .top-col{
    padding: 0px;
}
.profile-top .profile-top-left{
    width: 95%;
    margin-left: 6%;
    border-radius: 5px;
    padding: 40px 0px 20px 0px;
    border-right: 1px solid #e9e9e9;
    border-bottom: 1px solid #e9e9e9;
}
.general-table-container.float-profile .user-profile-content{
    height: 100vh;
    overflow-Y: auto;
}
.general-table-container.float-profile .user-profile-content::-webkit-scrollbar{
    width: 5px;
    border-radius: 5px;
    background: #d2d2d2;
}
.general-table-container.float-profile .user-profile-content::-webkit-scrollbar-thumb {
    border-radius: 5px;
    background: #999999;
}
.profile-top .profile-top-left .top{
    display: flex;
    margin-bottom: 20px;
}
.profile-top .show-grid{
    overflow: hidden;
}
.profile-top .profile-top-left .top .top-name{
    width: 80%;
}
.profile-top .profile-top-left .top .top-img{
    width: 50px;
    height: 50px;
    overflow: hidden;
    border-radius: 50%;
    margin-right: 20px;
    text-align: center;
    /* border: 1px solid #d8d8d8; */
    background-color: #fff;
}
.profile-top .profile-top-left .top .top-img span{
    color: #fff;
    font-size: 22px;
    font-weight: bold;
}
.profile-top .profile-top-left .top .top-img img{
    width: 100%;
    height: 100%;
    background-color: #fff;
}
.profile-top .profile-top-left .top .top-name ul{
    position: relative;
}
.profile-top .profile-top-left .top .top-name ul li{
    font-size: 18px;
    line-height: 20px;
}
.profile-top .profile-top-left .top .top-name ul li.name{
    font-weight: bold;
}
.profile-top .profile-top-left .top .top-name ul li.name .icon{
    margin-left: 5px;
    border-radius: 5px;
}
.profile-top .profile-top-left .top .top-name ul li.email{
    font-size: 14px;
    color: #2F80ED
}
.profile-top .profile-top-left .top .top-name ul li.email .icon{
    font-size: 16px;
    color: #909090;
    margin-right: 5px;
}
.profile-top .profile-top-left .top .top-name ul li.deactivate{
    top: 10px;
    right: 50px;
    position: absolute;
}
.profile-top .profile-top-left .top .top-name ul li.deactivate{
    color: red;
    cursor: pointer;
    font-size: 14px;
    font-weight: 500;
    padding: 5px 20px;
    border-radius: 3px;
    background-color: #fff;
    transition: all 0.3s ease;
    border: 1px solid rgb(249, 44, 44);
}
.profile-top .profile-top-left .top .top-name ul li.deactivate:hover{
    color: #fff;
    background-color: rgb(253, 177, 177);
}
.profile-top .profile-top-left .bottom-section{
    font-size: 14px;
    color: black;
}
.profile-top .profile-top-left .bottom-section .phone{
    font-size: 14px;
}
.profile-top .profile-top-left .bottom-section .phone .icon{
    font-size: 18px;
    color: #5a5a5a;
    margin-right: 10px;
}
.profile-top .profile-top-left .bottom-section .account span{
    color: #333;
    font-size: 14px;
    margin-right: 10px;
    font-weight: bold;
}
.profile-top .profile-top-left .bottom-section .contact .icon{
    font-size: 18px;
    color: #5a5a5a;
    margin-right: 10px;
}
.profile-top .profile-top-left .bottom-section .verified .icon{
    font-size: 18px;
    color: #27AE60;
    margin-right: 10px;
}
.profile-top .profile-top-left .bottom-section .email-button{
    margin-top: 20px;
}
.profile-top .profile-top-left .bottom-section .email-button  a{
    color: #fff;
    font-size: 16px;
    font-weight: 500;
    padding: 5px 20px;
    border-radius: 3px;
    background-color: #27AE60;
    border: 2px solid #27AE60;
    transition: all 0.3s ease;
}
.profile-top .profile-top-left .bottom-section .email-button  a:hover{
    color: #fff;
    background-color: #4dcc82;
}
.profile-top .profile-top-right{
    background-color: #EFEFEF
}
.profile-top .profile-top-right .title-header{
    width: 95%;
}
.profile-top .profile-top-right .title-header ul{
    height: 80px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.profile-top .profile-top-right .title-header ul li{
    font-size: 18px;
    font-weight: bold;
    padding-left: 20px;
}
.profile-top .profile-top-right .card-body{
    padding: 40px 20px;
    background-color: #fff;
}
.profile-top .profile-top-right .card-body .show-grid{
    width: 95%;
}
.profile-top .profile-top-right .card-body .card-item{
    padding: 20px;
    overflow: hidden;
    border-radius: 8px;
    position: relative;
    background-color: black;
}
.profile-top .profile-top-right .card-body .card-item.gold{
    background-color: #FDB813;
}
.profile-top .profile-top-right .card-body .card-item .card-top .title{
    color: #ccc;
    font-size: 14px;
    font-weight: 500;
}

.profile-top .profile-top-right .card-body .card-item .card-top .amount{
    color: #fff;
    font-size: 30px;
    font-weight: bold;
}

.profile-top .profile-top-right .card-body .card-item .card-bottom{
    margin-top: 20px;
}
.profile-top .profile-top-right .card-body .card-item .card-bottom .title{
    color: #ccc;
    font-size: 16px;
    font-weight: 500;
}
.profile-top .profile-top-right .card-body .card-item .card-bottom .date{
    color: #ccc;
    font-size: 12px;
    font-weight: 400;
}
.profile-top .profile-top-right .card-body .card-item.gold .card-bottom .title,
.profile-top .profile-top-right .card-body .card-item.gold .card-bottom .date,
.profile-top .profile-top-right .card-body .card-item.gold .card-top .title,
.profile-top .profile-top-right .card-body .card-item.gold .card-top .amount{
    color: black;
}
.profile-top .profile-top-right .card-body .card-item .card-image{
    top: 0px;
    right: 0px;
    width: 120px;
    position: absolute;
}
.profile-top .profile-top-right .card-body .card-item .card-image img{
    width: 100%;
}
/* *********** dark mode ************* */
.admin-container.dark .profile-top .profile-top-left{
    padding: 50px 10px;
    border-radius: 0px;
    background-color: #242424;
    border-right: 1px solid #323232;
    border-bottom: 1px solid #323232;
}
.admin-container.dark .profile-top{
    background-color: #171717;
 }
 .admin-container.dark .general-table-container.float-profile .user-profile-content::-webkit-scrollbar-thumb{
    background: #777777;
}
.admin-container.dark .profile-top .profile-top-left .top .top-name ul li{
    color: #ccc;
}
.admin-container.dark .profile-top .profile-top-left .top .top-name ul li.email{
    color: #2F80ED
}
.admin-container.dark .profile-top .profile-top-left .bottom-section .phone,
.admin-container.dark .profile-top .profile-top-left .bottom-section .account,
.admin-container.dark .profile-top .profile-top-left .bottom-section .contact,
.admin-container.dark .profile-top .profile-top-left .bottom-section .verified{
    color: #7a7a7a;
}
.admin-container.dark .profile-top .profile-top-left .bottom-section .account span{
    color: #7a7a7a;
}
.admin-container.dark .profile-top .profile-top-left .bottom-section .email-button  a:hover{
    background-color: #4dcc823d;
}
.admin-container.dark .profile-top .profile-top-left .top .top-name ul li.deactivate{
    color: rgb(249, 44, 44);
    background-color: transparent;
    border: 1px solid rgb(249, 44, 44);
}
.admin-container.dark .profile-top .profile-top-left .top .top-name ul li.deactivate:hover{
    background-color: rgba(249, 44, 44, 0.201);
}
.admin-container.dark .profile-top .profile-top-right{
    background-color: #242424;
}
.admin-container.dark .profile-top .profile-top-right .title-header ul li{
    color: #ccc;
}
.admin-container.dark .profile-top .profile-top-right .card-body{
    background-color: #171717;
}
@media only screen and (max-width: 1200px){
    .profile-top .profile-top-left{
        width: 95%;
        margin: 0 auto;
        margin-bottom: 30px;
    }
}
@media only screen and (max-width:767px){
    .profile-top .profile-top-left{
        width: 95%;
        border: 0px;
    }
    .profile-top .profile-top-right .card-body .card-item{
        margin-bottom: 30px;
    }
    .profile-top .profile-top-right .card-body .show-grid{
        width: 100%;
        margin: 0px;
    }
    .profile-top .profile-top-left .top .top-name ul li.deactivate{
        top: 10px;
        right: 10px;
    }
}
@media only screen and (max-width:567px){
    .profile-top .profile-top-right .card-body{
        padding: 40px 0px;
        background-color: #fff;
    }
    .profile-top .profile-top-left .top .top-name ul li.deactivate{
        position: static;
        margin-top: 20px;
    }
}
/* ************ SESSIONS SECTION ************* */
.sessions-pageview-container{
    width: 95%;
    margin: 0 auto;
    margin-top: 50px;
    margin-bottom: 50px;
    background-color: #EFEFEF;
}
.sessions-by-channel {
    width: 100%;
    height: 550px;
    border-radius: 5px;
    background-color: #fff;
    padding: 20px 20px 50px 20px;
}
.sessions-by-channel .show-grid{
    display: flex;
    align-items: center;
}
.horizontal-chart{
    width: 80%;
}
.sessions-by-channel .sessions-doughnut{
    width: 80%;
}
.horizontal-chart .chart-bar-item{
    margin: 30px 0px;
}
.sessions-by-channel .title-header{
    margin-bottom: 50px; 
}
.sessions-by-channel .title-header h3{
    font-size: 18px;
    font-weight: bold;
}
.horizontal-chart .chart-bar-item .title{
    display: flex;
    justify-content: space-between;
}
.horizontal-chart .chart-bar-item .title h3{
    font-size: 12px;
}
.horizontal-chart .chart-bar-item .title h3 span{
    font-size: 12px;
    color: #979797;
    margin-left: 5px;
}
.horizontal-chart .chart-bar-item .horizontal-bar{
   width: 100%;
   height: 5px;
   border: none;
   border-radius: 20px;
   background-color: #e8e8e8;
}
.horizontal-chart .chart-bar-item .horizontal-bar .bar{
    width: 0%;
    height: 5px;
    border: none;
    border-radius: 20px;
    transition: all 1s ease;
    animation: progree 3s linear;
}
.page-views-container {
    width: 100%;
    height: 550px;
    border-radius: 5px;
    background-color: #fff;
}
.page-views-container{
    padding: 20px;
}
.page-views-container .title-header h3{
    font-size: 18px;
    font-weight: bold;
}
.page-views-container .page-review-body{
    width: 100%;
    height: 470px;
    padding: 30px 0px;
    overflow-Y: auto;
}
.page-views-container .page-review-body::-webkit-scrollbar{
    width: 5px;
    border-radius: 5px;
    background: #ededed;
}
.page-views-container .page-review-body::-webkit-scrollbar-thumb {
    border-radius: 5px;
    background: rgb(251, 218, 157);
}
.page-views-container .page-review-body .page-review-item{
    display: flex;
    padding: 15px 5px 15px 0px;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid #f1f1f1;
}
.page-views-container .page-review-body .page-review-item .left h3.title{
    font-size: 16px;
    margin: 0px;
}
.page-views-container .page-review-body .page-review-item .left .link{
    font-size: 14px;
    color: #b6b6b6;
    line-height: 20px;
}
.page-views-container .page-review-body .page-review-item .right  h3.amount {
    font-size: 14px;
}
.page-views-container .page-review-body .page-review-item .right  h3.amount span {
    font-size: 12px;
    color: #979797;
    margin-left: 5px;
}
/* ************* dark mode ********** */
.admin-container.dark .sessions-pageview-container{
    background-color: #171717;
}
.admin-container.dark .sessions-by-channel,
.admin-container.dark .page-views-container{
    background-color: #242424;
    border: 1px solid #323232;
}
.admin-container.dark .page-views-container .title-header h3,
.admin-container.dark .sessions-by-channel .title-header h3{
    color: #ccc;
}
.admin-container.dark .horizontal-chart .chart-bar-item .title h3{
    color: #ccc;
}
.admin-container.dark .horizontal-chart .chart-bar-item .title h3 span{
    color: #7a7a7a;
}
.admin-container.dark .page-views-container .page-review-body .page-review-item{
    border-bottom: 1px solid #323232;
}
.admin-container.dark .page-views-container .page-review-body .page-review-item .right  h3.amount,
.admin-container.dark .page-views-container .page-review-body .page-review-item .left h3.title{
    color: #acacac;
}
.admin-container.dark .page-views-container .page-review-body .page-review-item .right  h3.amount span,
.admin-container.dark .page-views-container .page-review-body .page-review-item .left .link{
    color: #646464;
}
.admin-container.dark .page-views-container .page-review-body::-webkit-scrollbar{
    background: #323232;
}
.admin-container.dark .page-views-container .page-review-body::-webkit-scrollbar-thumb {
    background: #4c4c4c;
}
.admin-container.dark .horizontal-chart .chart-bar-item .horizontal-bar{
    background-color: #4c4c4c;
 }
@media only screen and (max-width: 1200px){
    .page-views-container {
        margin: 50px 0px;
    }
    .sessions-by-channel {
        width: 100%;
        height: 100%;
    }
    .horizontal-chart,
    .sessions-by-channel .sessions-doughnut{
        width: 100%;
        margin-bottom: 50px;
    }
}
/* ************* VISITS SECTION ************* */
.visits-container{
    width: 95%;
    margin: 0 auto;
}
.visitor-map-container{
    margin-top: 20px;
    padding: 20px;
    border-radius: 5px;
    background-color: #fff;
}
.visitor-map-container .title-header h3{
    font-size: 18px;
    font-weight: bold;
    margin-bottom: 20px;
}
.visitor-map-container .world-map{
    height: 500px;
}
/* ************ dark mode *********** */
.admin-container.dark .visitor-map-container{
    background-color: #242424;
    border: 1px solid #323232;
}
.admin-container.dark .visitor-map-container .world-map,
.admin-container.dark .visitor-map-container .title-header h3{
    color: #ccc;
}
/* ************* PROFILE SECTION ************* */
.general-table-container.float-profile{
    top: 0px;
    right: 0px;
    width: 95%;
    z-index: 31;
    position: fixed;
    transition: all 0.2s ease;
    transform: translate(110vw);
}
.general-table-container.float-profile.active{
    transform: translate(0);
}
.general-table-container.float-profile .profile-top{
    margin-top: 0px;
    padding: 0px;
}
.general-table-container.float-profile .float-profile-button{
    height: 0px;
}
.general-table-container.float-profile .float-profile-button button{
    top: -3px;
    left: -43px;
    color: #fff;
    font-size: 18px;
    border: none;
    padding: 5px 15px;
    cursor: pointer;
    position: relative;
    transition: all 0.3s ease;
    background-color: #EB5757;
}
.general-table-container.float-profile .float-profile-button button:hover .icon{
    animation: btnSpinner 0.3s linear;
}
@keyframes btnSpinner {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(180deg); }
}
/* ********** DEACTIVATE USER MODAL ********* */
.action-modal{
    height: 0px;
}
.action-modal .dark-skin{
    top: 0px;
    left: 0px;
    z-index: 31;
    width: 100%;
    display: none;
    height: 100vh;
    position: fixed;
    background-color: rgba(0, 0, 0, 0.661);
}
.action-modal .modal-content{
    top: 0px;
    left: 0px;
    z-index: 31;
    width: 100%;
    height: 100vh;
    position: fixed;
    display: flex;
    transform: scale(0);
    align-items: center;
    justify-content: center;
    transition: all 0.3s ease;
}
.action-modal .modal-content .modal-content-body{
    width: 50%;
    transform: scale(0);
    border-radius: 3px;
    transition: all 0.3s ease;
    background-color: #fff;
}
.action-modal .modal-content .modal-content-body .cancel-button{
    height: 0px;
    text-align: right;
    padding-right: 10px;
}
.action-modal .modal-content .modal-content-body .cancel-button .icon{
    color: #fff;
    cursor: pointer;
    font-size: 18px;
    padding: 5px 7px;
    border-radius: 50%;
    background-color: #EB5757;
    transition: all 0.3s ease;
}
.action-modal .modal-content .modal-content-body .cancel-button .icon:hover{
    background-color: #d01616;
    animation: btnSpinner 0.3s linear;
}
.action-modal .modal-content .modal-content-body .title-header{
    margin: 40px 0px;
    text-align: center;
}
.action-modal .modal-content .modal-content-body .title-header h3{
    font-size: 26px;
    line-height: 20px;
    margin-bottom: 10px;
}
.action-modal .modal-content .modal-content-body .title-header p{
    margin: 0px;
    font-size: 16px;
    line-height: 20px;
}
.action-modal .modal-content .modal-content-body .button{
    width: 100%;
    text-align: center;
    margin-bottom: 20px;
}
.action-modal .modal-content .modal-content-body .button button{
    width: 90%;
    color: #fff;
    border: none;
    padding-bottom: 5px;
    font-size: 20px;
    text-align: center;
    border-radius: 3px;
    transition: all 0.3s ease;
    border: 2px solid #EB5757;
    background-color: #EB5757;
}
.action-modal .modal-content .modal-content-body .button button:hover{
    background-color: #fd7b7b;
}
.action-modal.active .dark-skin{
    display: block;
}
.action-modal.active .modal-content,
.action-modal.active .modal-content .modal-content-body{
    transform: scale(1);
}
@media only screen and (max-width: 1200px){
    .action-modal .modal-content .modal-content-body{
        width: 80%;
    }
}
@media only screen and (max-width: 767px){
    .action-modal .modal-content .modal-content-body{
        width: 95%;
    }
}
@media only screen and (max-width: 567px){
    .action-modal .modal-content .modal-content-body .title-header h3{
        font-size: 22px;
    }
    .action-modal .modal-content .modal-content-body .button button{
        font-size: 16px;
        font-weight: 500;
    }
}
/* *********** dark mode ************ */
.admin-container.dark .action-modal .modal-content .modal-content-body{
    background-color: #242424;
    border: 1px solid #323232;
}
.admin-container.dark .action-modal .modal-content .modal-content-body .title-header h3{
    color: #ccc;
}
.admin-container.dark .action-modal .modal-content .modal-content-body .title-header p{
    color: #8d8d8d;
}
.admin-container.dark .action-modal .modal-content .modal-content-body .button button:hover{
    background-color: #ff545483;
}
/* ************** TABLE BOTTOM SECTION ************* */
.table-bottom-container{
    width: 100%;
}
.table-bottom-container .table-bottom{
    top: -1px;
    width: 100%;
    margin: 0 auto;
    display: flex;
    position: relative;
    padding: 10px 20px;
    align-items: center;
    background-color: #fff;
    justify-content: space-between;
    border-top: 1px solid #ccc;
    border-radius: 0px 0px 5px 5px;
}
.table-bottom-container .table-bottom .page-rows-left{
    display: flex;
    align-items: center;
}
.table-bottom-container .table-bottom .page-rows-left .title-header{
    margin-right: 10px;
}
.table-bottom-container .table-bottom .page-rows-left .title-header h3{
    font-size: 14px;
    font-weight: 400;
}
.table-bottom-container .table-bottom .page-rows-right ul{
    display: flex;
    align-items: center;
}
.table-bottom-container .table-bottom .page-rows-right ul li{
    font-size: 14px;
    margin-left: 10px;
}
.table-bottom-container .table-bottom .page-rows-right ul li .icon{
    cursor: pointer;
    margin-left: 30px;
    color: #a8a8a8;
}
.table-bottom-container .table-bottom .page-rows-right ul li .icon.active,
.table-bottom-container .table-bottom .page-rows-right ul li .icon:hover{
    color: #333;
}
.table-bottom-container .table-bottom .page-rows-right ul li span{
    color: #a8a8a8;
}
.table-bottom-container .table-bottom .page-rows-left .select-drop-down .drop-down-frame ul{
    width: 100%;
    top: inherit;
    bottom: 100px;
}
.table-bottom-container .table-bottom .page-rows-left .select-drop-down .drop-down-frame.active ul{
    bottom: 0px;
}
.mobile-table-content .table-bottom-container{
    display: none;
}
/* *********** dark mode ************* */
.admin-container.dark .table-bottom-container .table-bottom{
    margin-top: 20px;
    border-radius: 5px;
    background-color: #242424;
    border: 1px solid #323232;
}
.admin-container.dark .table-bottom-container .table-bottom .page-rows-right ul li,
.admin-container.dark .table-bottom-container .table-bottom .page-rows-left .title-header h3{
    color: #ccc;
}
.admin-container.dark .table-bottom-container .table-bottom .page-rows-right ul li .icon.active,
.admin-container.dark .table-bottom-container .table-bottom .page-rows-right ul li .icon:hover{
    color: #FDB813;
}
.admin-container.dark .table-bottom-container .table-bottom .page-rows-right ul li span{
    color: #707070;
}
@media only screen and (max-width: 992px){
    .general-table-body .table-bottom-container{
        display: none;
    }
    .mobile-table-content .table-bottom-container{
        display: block;
    }
    .table-bottom-container .table-bottom{
        width: 95%;
    }
    .table-bottom-container .table-bottom .page-rows-left{
        margin-bottom: 20px;
    }
}
@media only screen and (max-width: 767px){
    .table-bottom-container .table-bottom{
        display: block;
    }
    .table-bottom-container .table-bottom .page-rows-right ul li{
        text-align: center;
    }
}
/* ************ NOTIFICATIONS SECTION *********** */
.notification-container{
    top: 0px;
    right: 0px;
    width: 100%;
    height: 100vh;
    z-index: 20;
    display: none;
    position: fixed;
}
.notification-container.active{
    display: block;
}
.notification-container .notification-skin{
    width: 100%;
    height: 100vh;
}
.notification-container .notification-frame{
    top: 10px;
    right: 10px;
    padding: 10px;
    position: absolute;
    border-radius: 5px;
    background-color: #fff;
    box-shadow: 2px 2px 5px 2px rgba(191, 191, 191, 0.772);
}
.notification-container .notification-frame .notification-inner-frame{
    width: 400px;
    height: 90vh;
    overflow-Y: auto;
}
.notification-container .notification-frame .notification-inner-frame::-webkit-scrollbar{
    width: 5px;
    background: #ccc;
}
.notification-container .notification-frame .notification-inner-frame::-webkit-scrollbar-thumb {
    background: #b2b2b2;
    border-radius: 10px;
  }
.notification-container .notification-frame .title-header{
    padding: 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid #e9e9e9;
}
.notification-container .notification-frame .title-header h3{
    font-size: 22px;
}
.notification-container .notification-frame .title-header .icon{
    color: #333;
    font-size: 16px;
    cursor: pointer;
    padding: 4px 10px;
}
.notification-container .notification-frame .title-header .icon:hover{
    color: #fdb713;
}
.notification-container .notification-frame .title-header h3{
    font-size: 22px;
}
.notification-container .notification-content .notification-item-parent {
    position: relative;
}
.notification-container .notification-frame .notification-content .notification-items{
    display: flex;
    position: relative;
    padding: 10px 20px;
    border-radius: 5px;
    margin-bottom: 20px;
    border: 1px solid transparent;
}
.notification-container .notification-frame .notification-content .notification-items.unread{
    background-color: #f9f9f9;
}
.notification-container .notification-frame .notification-content .notification-items.unread:hover,
.notification-container .notification-frame .notification-content .notification-items:hover{
    background-color: #f9f9f9;
}
.notification-container .notification-frame .notification-content .notification-items .img{
    width: 50px;
    height: 50px;
    overflow: hidden;
    border-radius: 40%;
    margin-right: 20px;
    border: 1px solid #e9e9e9;
}
.notification-container .notification-frame .notification-content .notification-items .img img{
    width: 100%;
    height: 100%;
}
.notification-container .notification-frame .notification-content .notification-items .not-content{
    width: 80%;
    line-height: 20px;
}
.notification-container .notification-frame .notification-content .notification-items .not-content{
    font-size: 16px;
}
.notification-container .notification-frame .notification-content .notification-items .notification{
    width: 100%;
    color: #555;
    font-size: 14px;
    word-wrap: break-word;
}
.notification-container .notification-frame .notification-content .notification-items .time{
    font-size: 12px;
    margin-top: 5px;
    color: #7B61FF;
    font-weight: 600;
}
.notification-container .notification-frame .notification-control{
    padding: 20px;
}
.notification-container .notification-frame .notification-control .control{
    font-size: 14px;
    cursor: pointer;
    font-weight: 500;
    padding: 5px 10px;
    margin-right: 20px;
    border-radius: 10px;
    transition: all 0.3s ease;
    border: 2px solid transparent;
}
.notification-container .notification-frame .notification-control .control:hover{
    background-color:  #efefef;
}
.notification-container .notification-frame .notification-control .control.active{
    background-color:  #fdb7137a;
}
.notification-container .notification-frame .notification-control .control.active:hover{
    border: 2px solid #fdb713;
}
.notification-container .notification-content .notification-item-parent .notification-options{
    top: 20px;
    right: 20px;
    width: 100%;
    position: absolute;
}
.notification-container .notification-content .notification-item-parent .notification-options .icon.button{
    right: 0px;
    z-index: 10;
    color: #333;
    display: none;
    cursor: pointer;
    font-size: 20px;
    position: absolute;
    padding: 11px 15px;
    border-radius: 5px;
    background-color: #fff;
    box-shadow: 2px 2px 5px 2px rgba(191, 191, 191, 0.772);
}
.notification-container .notification-content .notification-item-parent .notification-options .icon.button:hover{
    background-color: #f6f6f6;
}
.notification-container .notification-content .notification-item-parent:hover .notification-options .icon.button{
    display: block;
}
.notification-container .notification-content .notification-item-parent .notification-options .notification-options-items{
    right: 0px;
    width: 300px;
    z-index: 10;
    display: none;
    position: absolute;
    border-radius: 5px;
    background-color: #fff;
    box-shadow: 2px 2px 5px 2px rgba(191, 191, 191, 0.772);
}
.notification-container .notification-content .notification-item-parent .notification-options .notification-options-items.active{
    display: block;
}
.notification-container .notification-content .notification-item-parent .notification-options .notification-options-items ul li .icon{
    font-size: 16px;
    margin-right: 10px;
}
.notification-container .notification-content .notification-item-parent .notification-options .notification-options-items ul li{
    color: #555;
    font-size: 16px;
    cursor: pointer;
    padding: 0px 10px;
}
.notification-container .notification-content .notification-item-parent .notification-options .notification-options-items ul{
    padding: 10px 0px;
}
.notification-container .notification-content .notification-item-parent .notification-options .notification-options-items ul li:hover{
    background-color: #ececec;
}
/* ************* dark mode ************ */
.admin-container.dark .notification-container .notification-frame{
    background-color: #242424;
    border: 1px solid #323232;
    box-shadow: 2px 2px 5px 2px rgba(17, 17, 17, 0.772);
}
.admin-container.dark .notification-container .notification-frame .title-header h3{
    color: #ccc;
}
.admin-container.dark .notification-container .notification-frame .notification-content .notification-items.unread{
    background-color: #33333355;
}
.admin-container.dark .notification-container .notification-frame .title-header{
    border-bottom: 1px solid #323232;
}
.admin-container.dark .notification-container .notification-frame .notification-control .control{
    color: #ccc;
}
.admin-container.dark .notification-container .notification-frame .notification-control .control:hover{
    background-color: #333;
}
.admin-container.dark .notification-container .notification-frame .notification-control .control.active{
    color: #ccc;
    background-color:  #fdb7137a;
}
.admin-container.dark .notification-container .notification-frame .notification-content .notification-items .img,
.admin-container.dark .notification-container .notification-frame .notification-content .notification-items .img img{
    background-color:  #a1a1a1;
}
.admin-container.dark .notification-container .notification-frame .notification-content .notification-items .img{
    border: none;
}
.admin-container.dark .notification-container .notification-frame .notification-content .notification-items .notification{
    color: #8e8e8e;
}
.admin-container.dark .notification-container .notification-frame .notification-content .notification-items .time{
    color: #fdb713;
}
.admin-container.dark .notification-container .notification-frame .notification-inner-frame::-webkit-scrollbar{
    background-color: #252525;
}
.admin-container.dark .notification-container .notification-frame .notification-inner-frame::-webkit-scrollbar-thumb {
    background-color: rgba(115, 115, 115, 0.379);
}
.admin-container.dark .notification-container .notification-frame .title-header .icon{
    color: #ccc;
}
.admin-container.dark .notification-container .notification-frame .title-header .icon:hover{
    color: #fdb713;
}
.admin-container.dark .notification-container .notification-frame .notification-content .notification-items.unread:hover,
.admin-container.dark .notification-container .notification-frame .notification-content .notification-items:hover{
    background-color: #33333355;
    border: 1px solid #323232;
}
.admin-container.dark .notification-container .notification-content .notification-item-parent .notification-options .icon.button{
    color: #ccc;
    background-color: #333;
    box-shadow: 2px 2px 5px 2px rgba(17, 17, 17, 0.772);
}
.admin-container.dark .notification-container .notification-content .notification-item-parent .notification-options .icon.button:hover{
    background-color: #404040;
}
.admin-container.dark .notification-container .notification-content .notification-item-parent .notification-options .notification-options-items{
    background-color: #2f2f2f;
    box-shadow: 2px 2px 5px 2px rgba(17, 17, 17, 0.772);
}
.admin-container.dark .notification-container .notification-content .notification-item-parent .notification-options .notification-options-items ul li{
    color: #8e8e8e;
}
.admin-container.dark .notification-container .notification-content .notification-item-parent .notification-options .notification-options-items ul li:hover{
    background-color: #404040;
}
@media only screen and (max-width: 1400px){
    .notification-container .notification-frame .notification-inner-frame{
        height: 85vh;
    }
}
@media only screen and (max-width: 992px){
    .notification-container .notification-frame .notification-inner-frame{
        height: 90vh;
    }
}
@media only screen and (max-width: 767px){
    .notification-container .notification-frame{
        top: 5px;
        right: 5px;
        width: 95%;
    }
    .notification-container .notification-frame .notification-inner-frame{
        width: 100%;
    }
}
@media only screen and (max-width: 567px){
    .admin-container.dark .notification-container .notification-frame .notification-inner-frame::-webkit-scrollbar{
        display: none;
    }
    .notification-container .notification-frame .notification-inner-frame{
        height: 90vh;
    }
    .notification-container .notification-content .notification-item-parent .notification-options .notification-options-items{
        width: 80%;
    }
    .notification-container .notification-content .notification-item-parent .notification-options{
        right: 0px;
    }
}
@media only screen and (max-width: 398px){
    .notification-container .notification-content .notification-item-parent .notification-options .notification-options-items{
        width: 90%;
    }
}

/* *********** EMPTY CONTENT SECTION ************ */
.empty-content-container{
    width: 100%;
    height: 300px;
    display: flex;
    margin: 50px 0px;
    align-items: center;
    border-radius: 10px;
    background-color: #fff;
    justify-content: center;
}
.empty-content-container .title-header h3{
    font-size: 20px;
}
@media only screen and (max-width: 565px){
    .empty-content-container .title-header h3{
        font-size: 16px;
    }
}
/* ************ dark mode ************ */
.admin-container.dark .empty-content-container{
    background-color: #242424;
    border: 1px solid #323232;
}
.admin-container.dark .empty-content-container .title-header h3{
    color: #ccc;
}
/* *************** TABLE DROPDOWN SECTION *********** */
.table-drop-down{
    position: relative;
}
.table-drop-down .drop-down{
    top: 15px;
    right: 0px;
    z-index: 10;
    width: 200px;
    display: none;
    padding: 5px 0px;
    position: absolute;
    border-radius: 5px;
    background-color: #fff;
    box-shadow: 2px 2px 5px 2px rgba(191, 191, 191, 0.772);
}
.table-drop-down .drop-down.active{
    display: block;
}
.table-drop-down .icon{
    cursor: pointer;
}
.table-drop-down .drop-down ul li{
    cursor: pointer;
    padding: 0px 10px;
}
.table-drop-down .drop-down ul li .icon{
    color: #555;
    cursor: pointer;
    margin-right: 10px;
}
.table-drop-down .drop-down ul li:hover{
    background-color: #eeeeee;
}

/* *********** dark mode ******* */
.admin-container.dark .table-drop-down .drop-down{
    background-color: #252525;
    border: 1px solid #323232;
    box-shadow: 2px 2px 5px 2px rgba(17, 17, 17, 0.772);
}
.admin-container.dark .table-drop-down .drop-down ul li .icon{
    color: #737373;
}
.admin-container.dark .table-drop-down .drop-down ul li span{
    color: #ccc;
}
.admin-container.dark .table-drop-down .drop-down ul li:hover{
    background-color: #1e1e1e;
}


















